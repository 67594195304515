import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Api, getSession, clearSession, cancelRequest } from '../../api/Assinafy';
import { Dashboard, Section } from '../../layout';
import { SessionContext } from '../../routes/DashboardRoute';
import { Table, Alert, Button, Input, Select, Icon, Toast, Modal } from '../../components';
import Form, { createInputChange, createInputBlur, createInputError } from '../../components/Form';
import ModalPasswordSet from './ModalPasswordSet';
import { dateFormat, phoneNumber, cpfFormat } from '../../utils/Formats';

const Profile = () => {
  return (<Dashboard.Content>
    <Dashboard.Header
      title="Editando minha conta"
    />

    <ProfileSection />

  </Dashboard.Content>)
}

const ProfileSection = (props) => {
  const [user, setUser] = useState({})
  const [accounts, setAccounts] = useState([])
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formValidation, setFormValidation] = useState([])
  const [formData, setFormData] = useState({})
  const [readOnly, setReadOnly] = useState(false)
  const [toast, setToast] = useState('')
  const [modalRemove, setModalRemove] = useState(false)
  const [modalPassword, setModalPassword] = useState(false)
  const [session, setSession] = useContext(SessionContext);
  const cancelToken = cancelRequest()
  const { isRead } = props
  const navigate = useNavigate()
  const { state } = useLocation()

  const handleSubmit = (event) => {
    event.preventDefault()

    if (event.target.checkValidity()) {
      setLoading(true)

      Api('user/updateSelf')(formData, {}, () => {
        setSession(getSession())
      })
        .then(({ status }) => {
          if (status === 200) {
            navigate('/dashboard/account', {
              state: {
                toast: 'Conta atualizada com sucesso'
              }
            })
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    } else {
      Array.from(event.target.elements).map((element) => {
        if (element.name) {
          setFormValidation((prevState) => [
            ...prevState,
            { name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const handleChange = createInputChange(formData, setFormData)

  const handleBlur = createInputBlur(formValidation, setFormValidation)

  const showInputError = createInputError(formValidation)

  const handleCancelDelete = () => {
    Api('user/cancelDeleteSelf')()
      .then(({ status }) => {
        if (status === 200) {
          setUser({
            ...user,
            to_be_deleted_at: null
          })
          setToast('Cancelamento da exclusão programada da conta realizado com sucesso')
          clearSession()
        }
      })
  }

  const handleAccountRemove = () => {
    if (user.is_password_set) {
      setModalRemove(true)
    } else {
      setModalPassword(true)
    }
  }

  const handlePasswordChange = () => {
    if (user.is_password_set) {
      navigate('/dashboard/account/change-password')
    } else {
      setModalPassword(true)
    }
  }

  useEffect(() => {
    setReadOnly(isRead)
  }, [isRead])

  useEffect(() => {
    Api('user/getSelf')({
      ...cancelToken.config
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setUser(data.user)
          setAccounts(data.accounts)
        }
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  useEffect(() => {
    if (state?.toast) {
      setToast(state.toast)
      window.history.replaceState({}, document.title)
    }
  }, [state])

  return (<Section>
    <Form id="form-profile"
      onSubmit={handleSubmit}
    >
      <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
        {formError.map((error) => error)}
      </Alert>
      <Table
        className="table-bordered table-form"
        rows={[
          ['Nome', isRead
            ? user.name
            : <Form.Group>
              <Input name="name"
                defaultValue={user.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {showInputError('name')}
            </Form.Group>],
          ['CPF', isRead
            ? cpfFormat(user.government_id)
            : <Form.Group>
              <Input.Cpf name="government_id"
                defaultValue={cpfFormat(user.government_id)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {showInputError('government_id')}
            </Form.Group>],
          ['E-mail', user.email],
          ['Telefone', isRead
            ? phoneNumber(user.telephone)
            : <Form.Group>
              <Input.Phone name="telephone"
                defaultValue={phoneNumber(user.telephone)}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {showInputError('telephone')}
            </Form.Group>],
          ['Senha', <div className="d-flex">
            <div>É recomendável usar uma senha forte</div>
            {!isRead &&
              <Button variant="link"
                onClick={handlePasswordChange}
              >
                <Icon id="icon-edit" className="me-2" size="16" />
                TROCAR
              </Button>
            }
          </div>],
          {/* TODO login social 
            ['Conta Google', <div className="d-flex">
              <div>Facilite seu login conectando sua conta Google</div>
              <Button variant="google">
                <Icon id="icon-google" className="me-2" size="24" />
                CONECTAR CONTA GOOGLE
              </Button>
            </div>],
          */},
          ['Status da conta', <div className="d-flex">
            <div>Ativada dia {user.created_at && dateFormat(user.created_at)}</div>
            {(!isRead && !user.to_be_deleted_at) &&
              <Button
                variant="danger"
                onClick={handleAccountRemove}
              >
                <Icon id="icon-trash" className="me-2" size="16" />
                CANCELAR CONTA
              </Button>
            }
            {(!isRead && !!user.to_be_deleted_at) &&
              <Button
                variant="info"
                onClick={handleCancelDelete}
              >
                REVERTER CANCELAMENTO
              </Button>
            }
          </div>]
        ]}
      />

      <Form.Footer horizontal>
        {readOnly
          ? <Button variant="info" as={Link}
            to={`/dashboard/profile`}
          >
            <Icon id="icon-edit" className="me-1" size="16" />
            EDITAR
          </Button>
          : <>
            <Button variant="primary" submit loading={loading}>
              <Icon id="icon-check" className="me-1" size="16" />
              SALVAR ALTERAÇÕES
            </Button>
            <Button variant="info" onClick={() => navigate(-1)}>
              CANCELAR
            </Button>
          </>
        }
      </Form.Footer>
    </Form>
    <Toast show={toast} onClose={() => setToast('')}>
      {toast}
    </Toast>

    <ModalRemove
      show={modalRemove}
      setModalRemove={setModalRemove}
      accounts={accounts}
    />
    <ModalPasswordSet
      show={modalPassword}
      setModalPassword={setModalPassword}
      user={user}
      setFormError={setFormError}
    />
  </Section>)
}

const ModalRemove = ({ show, setModalRemove, accounts }) => {
  const navigate = useNavigate()
  const [reasons, setReasons] = useState([])

  const handleSubmit = (formData) => new Promise(() => {
    navigate('/dashboard/profile/delete', {
      state: formData
    })
  })

  useEffect(() => {
    if (show && !reasons.length) {
      Api('user/deleteReasons')()
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            data.map(({ code, name }) => {
              setReasons((prevState) => {
                return [
                  ...prevState,
                  { value: code, label: name }
                ]
              })
            })
          }
        })
    }
  }, [show])

  return (
    <Modal show={show}
      centered
      onHide={() => setModalRemove(false)}
    >
      <Modal.Header>
        <Button variant="icon" className="btn-close"
          onClick={() => setModalRemove(false)}
        >
          <Icon id="icon-close" size="24" />
        </Button>
        <Modal.Title as={'div'} className="h2">
          Deseja realmente excluir sua conta?
        </Modal.Title>
        <p>
          Ao excluir sua conta Assinafy, você perde acesso a todos os seus  documentos e workspaces.
        </p>
        <p>
          Ao invés disso, você pode manter sua conta e continuar com acesso aos seus documentos.
        </p>
        <h3 className="h4">Workspaces que serão excluídos</h3>
        <ul>
          {accounts.map((account, i) =>
            <li key={i}>{account.name}</li>
          )}
        </ul>
      </Modal.Header>
      <Form id="form-profile-delete"
        onSubmit={handleSubmit}
        controls={[
          {
            Component: Select,
            name: 'delete_reason_type',
            label: 'Qual o motivo da exclusão?',
            size: 'lg',
            required: true,
            options: reasons
          },
          {
            Component: Input.Password,
            name: 'password',
            label: 'Senha',
            size: 'lg',
            required: true,
            autoComplete: 'current-password'
          }
        ]}
        buttons={[
          {
            variant: 'light', onClick: () => setModalRemove(false), children: <>
              <Icon id="icon-close" className="me-1" size="16" />
              CANCELAR
            </>
          },
          {
            variant: 'danger', submit: true, children: <>
              <Icon id="icon-trash" className="me-1" size="16" />
              EXCLUIR CONTA
            </>
          }
        ]}
        ComponentBody={Modal.Body}
        ComponentFooter={Modal.Footer}
      >
      </Form>
    </Modal>
  )
}

Profile.Section = ProfileSection

export default Profile;