import React from 'react';
import classNames from 'classnames';
import Spinner from '../Spinner';

import './styles.scss';

const Table = ({
  responsive = true,
  variant,
  renderRow,
  headerClassName,
  header,
  rows,
  ...props
}) => {
  const { className } = props;
  const prefix = 'table';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, className);

  if (!renderRow) {
    renderRow = (row, i) => <tr key={i}>
      {Object.values(row).map((value, i) =>
        <td key={i}>{value}</td>
      )}
    </tr>
  }

  return (
    <div className={responsive ? 'table-responsive' : ''}>
      <table className={newClassName}>
        {header && <thead>
          <tr>
            {header.map(({ name, className }, i) =>
              <th key={i}
                className={className}
              >
                {name}
              </th>
            )}
          </tr>
        </thead>}
        <tbody>
          {rows ?
            !!rows.length ?
              rows.map(renderRow)
              :
              <tr>
                <td colSpan={header && header.length} className="table-loading">
                  Nenhum dado encontrado
                </td>
              </tr>
            :
            <tr>
              <td colSpan={header && header.length} className="table-loading">
                <Spinner className="me-1" />
                Carregando...
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

export default Table;
