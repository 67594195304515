import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Api, downloadFile, urlWithAccessToken, cancelRequest } from '../../api/Assinafy'
import { Dashboard, Section } from '../../layout';
import { Status, Alert, Toast, Image, Dropdown, Card, Button, Icon } from '../../components';
import SectionSigners from './View/SectionSigners';
import SectionExpiration from './View/SectionExpiration';
import SectionActivities from './View/SectionActivities';
import ModalViewer from './View/ModalViewer';
import { dateFormat, timeFormat } from '../../utils/Formats';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';

import thumbDocument from '../../assets/images/thumb-document.svg';
import './styles.scss';

const DocumentView = () => {
  const { id: documentId } = useParams()
  const [document, setDocument] = useState({})
  const [sectionTitle, setSectionTitle] = useState('')
  const [activities, setActivities] = useState([])
  const [modalViewer, setModalViewer] = useState(false)
  const [alert, setAlert] = useState({})
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState('')
  const cancelTokenDocument = cancelRequest()
  const cancelTokenActivities = cancelRequest()
  const navigate = useNavigate()

  const showFirstCard = () => {
    if (_.isEmpty(document)) {
      return <Card />
    }

    switch (document.status) {
      case 'uploading':
      case 'metadata_ready':
        return <CardCustom type="fields" document={document} />
      case 'certificated': case 'pending_signature': case 'expired':
        return <CardCustom type="certificated_download" document={document} />
      default:
        return <CardCustom type="pending_signature" document={document} disabled={true} />
    }
  }

  const showSecondCard = () => {
    if (_.isEmpty(document)) {
      return <Card />
    }

    switch (document.status) {
      case 'uploading':
      case 'metadata_ready':
        return <CardCustom type="no_fields" document={document} />
      case 'certificated': case 'pending_signature': case 'expired':
        return <CardCustom type="download" document={document} />
      default:
        return <CardCustom type="pending_signature" document={document} />
    }
  }

  const handleResendAssignmentAll = () => {
    setLoading(true)

    Promise.all(document.assignment.summary.signers.map(async (signer) => {
      await Api('assignment/resend')(documentId, document.assignment.id, signer.id)
        .catch(() => {
          setLoading(false)
        })
    }))
      .then(() => {
        setLoading(false)
        setToast('Documento reenviado com sucesso')
      })
  }

  useEffect(() => {
    Api('document/view')(documentId, {
      ...cancelTokenDocument.config
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setDocument(data)
        }
      })
      .catch(({ response: { data } }) => {
        navigate('/dashboard', {
          state: {
            alert: {
              type: 'danger',
              message: data.message
            }
          }
        })
      })

    Api('activity/index')(documentId, {
      ...cancelTokenActivities.config
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setActivities(data)
        }
      })

    return () => {
      cancelTokenDocument.cancel()
      cancelTokenActivities.cancel()
    }
  }, [documentId])

  useEffect(() => {
    if (_.isEmpty(document)) return

    if ([
      'uploading',
      'uploaded',
      'metadata_processing',
      'failed'
    ].indexOf(document.status) !== -1) {
      navigate('/dashboard/documents')
    }

    if (document.status === 'uploading' || document.status === 'metadata_ready') {
      setSectionTitle('Escolha como você quer que esse documento seja enviado')
    } else {
      setSectionTitle('Escolha como você quer fazer o download desse documento')
    }

    if (document.status === 'rejected_by_signer') {
      setAlert({
        variant: 'danger',
        children: <>
          <b>Documento bloqueado</b>
          Um signatário se negou a assinar o documento.<br />
          Motivo: {document.decline_reason}
        </>
      })
    }
  }, [document])

  useEffect(() => {
    if (_.isEmpty(document)) return

    const { assignment } = document

    if (document.status === 'expired') {
      const { signer_count, completed_count } = assignment.summary
      const expires_at = new Date(assignment.expires_at)
      const signersPending = (signer_count - completed_count)

      setAlert({
        variant: 'danger',
        children: <>
          <b>Este documento expirou!</b>
          O documento {document.name} expirou no dia {dateFormat(expires_at)}{signersPending && !document.is_closed ? ` faltando ${signersPending} assinatura${signersPending > 1 ? 's' : ''}, deseja reenviar o documento?` : '.'}
        </>
      })
    }

    if (document.status === 'pending_signature' && !document.is_closed && document.assignment?.expires_at) {
      const { signer_count, completed_count } = assignment.summary
      const expires_at = new Date(assignment.expires_at)
      const diff = expires_at.getTime() - new Date().getTime()
      const diffHours = diff / 1000 / 60 / 60
      const signersPending = (signer_count - completed_count)

      if (diffHours < 48) {
        let diffDays = 'breve';
        if (diffHours > 24) {
          diffDays = `${("0" + (Math.ceil(diffHours / 24))).slice(-2)} dias`
        }

        setAlert({
          variant: 'warning',
          children: <div className="d-flex">
            <div className="d-flex flex-column flex-grow-1">
              <b>Este documento expira em {diffDays}.</b>
              O documento expira dia {dateFormat(expires_at)} às {timeFormat(expires_at)} e
              faltam {signersPending} signatários, você pode alterar a data ou reenviar alerta aos signatários faltantes.
            </div>
            <Button
              onClick={handleResendAssignmentAll}
              loading={loading}
              loadingText="ENVIANDO"
              className="ms-3"
            >
              REENVIAR
            </Button>
          </div>
        })
      }
    }
  }, [document, loading])

  return (<Dashboard.Content>
    <Dashboard.Header
      title={<>
        {document.name}
        <Status id={document.status} />
      </>}
    />

    {!_.isEmpty(alert) &&
      <Alert {...alert} />
    }

    <Section className="section-document">
      <Section.Header
        title={sectionTitle}
      />
      <Row>
        <Col md>
          {showFirstCard()}
        </Col>
        <Col md>
          {showSecondCard()}
        </Col>
        <Col lg={{ span: 'auto', order: 'first' }}>
          <Image key={uuid()}
            width="168"
            height="238"
            src={document.artifacts?.thumbnail && urlWithAccessToken(document.artifacts.thumbnail)}
            placeholder={thumbDocument}
          >
            <Button variant="info"
              onClick={() => setModalViewer(true)}
            >
              <Icon id="icon-visibility" className="me-1" size="18" />
              VISUALIZAR <span className="d-lg-none">DOCUMENTO</span>
            </Button>
          </Image>
        </Col>
      </Row>
    </Section>

    {document.assignment &&
      <SectionSigners
        document={document}
        setToast={setToast}
      />
    }

    {document.assignment && !document.is_closed &&
      <SectionExpiration
        documentId={document.id}
        assignment={document.assignment}
      />
    }

    <SectionActivities activities={activities} />

    <ModalViewer show={!!modalViewer}
      document={document}
      onClose={() => setModalViewer(false)}
    />

    <Toast show={toast} onClose={() => setToast('')}>
      {toast}
    </Toast>
  </Dashboard.Content>);
}

const CardCustom = ({ document, type, disabled, ...props }) => {
  const handleDownload = async (event, url) => {
    event.preventDefault();
    return downloadFile(urlWithAccessToken(url))
  }

  const signersScore = () => {
    const summary = document.assignment?.summary

    if (summary) {
      return `${summary.completed_count}/${summary.signer_count}`
    }
  }

  const details = {
    fields: {
      title: 'Preparar com campos',
      text: 'Você pode pedir assinatura e campos extras para o signatário preencher.',
      button: <Button
        variant="primary"
        as={Link}
        to={`/dashboard/documents/${document.id}/signers`}
        disabled={disabled}
      >
        COM CAMPOS
      </Button>
    },
    no_fields: {
      title: 'Preparar sem campos',
      text: 'O signatário apenas confirma que está de acordo com o documento clicando em um botão.',
      button: <Button
        variant="primary"
        as={Link}
        to={`/dashboard/documents/${document.id}/virtual/signers`}
        disabled={disabled}
      >
        SEM CAMPOS
      </Button>
    },
    download: {
      title: 'Download original',
      text: 'Você baixa o arquivo original sem as assinaturas digitais dos associados.',
      button: <Button
        variant="light"
        onClick={(e) => handleDownload(e, document.artifacts?.original)}
        disabled={disabled}
      >
        <Icon id="icon-arrow_downward" className="me-1" size="18" />
        DOWNLOAD ORIGINAL
      </Button>
    },
    certificated_download: {
      title: 'Download assinado',
      text: 'Você baixa o arquivo com as assinaturas digitais dos associados e o certificado.',
      button: <>
        {(document.status == 'certificated')
          ? <Dropdown
            variant="primary"
            button={<>
              <Icon id="icon-arrow_downward" className="me-1" size="18" />
              DOWNLOAD ASSINADO
            </>}
          >
            <Dropdown.Item as={Button}
              onClick={(e) => handleDownload(e, document.artifacts?.bundle, `${document.name}.zip`)}
            >
              <Icon id="icon-task_line" className="me-1" size="20" />
              Arquivos zipados
            </Dropdown.Item>
            <Dropdown.Item as={Button}
              onClick={(e) => handleDownload(e, document.artifacts?.certificated)}
            >
              <Icon id="icon-task_line" className="me-1" size="20" />
              Documento Assinado
            </Dropdown.Item>
            <Dropdown.Item as={Button}
              onClick={(e) => handleDownload(e, document.artifacts['certificate-page'])}
            >
              <Icon id="icon-description" className="me-1" size="20" />
              Pág. de Certificação
            </Dropdown.Item>
          </Dropdown>
          : <Button variant="link" disabled>
            <Icon id="icon-schedule" className="me-1" size="20" />
            {document.status == 'expired'
              ? 'DOCUMENTO EXPIRADO'
              : `FALTANDO ASSINATURA ${signersScore()}`
            }
          </Button>
        }
      </>
    }
  }
  const detail = details[type]

  if (!detail) {
    return (<Card></Card>)
  }

  return (<Card {...props}>
    <h3 className="h3">
      {detail.title}
    </h3>
    <Card.Text>
      {detail.text}
    </Card.Text>
    {detail.button}
  </Card>);
};

export default DocumentView;