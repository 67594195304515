import { Tabs, Tab } from 'react-bootstrap';

import './styles.scss';

const MyTabs = (props) => {
  return (
    <Tabs {...props} />
  )
};

const MyTab = (props) => {
  return (
    <Tab {...props} />
  )
};

MyTabs.Tab = MyTab

export default MyTabs;
