import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Api, cancelRequest, getSession } from '../../../api/Assinafy';
import { Row, Col } from 'react-bootstrap';
import { Section } from '../../../layout';
import { Alert, Modal, Button, Form, Icon, Input, Toast } from '../../../components';
import ModalPasswordSet from '../../Profile/ModalPasswordSet';

const ApiTab = () => {
  const [toast, setToast] = useState('')
  const [formError, setFormError] = useState([])
  const [modalNew, setModalNew] = useState(false)
  const [modalView, setModalView] = useState(false)
  const [modalPassword, setModalPassword] = useState(false)
  const [apiKey, setApiKey] = useState()
  const cancelToken = cancelRequest()
  const { state } = useLocation()
  const { user } = getSession()

  const handleNewApiKey = () => {
    if (user.is_password_set) {
      setModalNew(true)
    } else {
      setModalPassword(true)
    }
  }

  useEffect(() => {
    if (state?.toast) {
      setToast(state.toast)
      window.history.replaceState({}, document.title)
    }
  }, [state])

  useEffect(() => {
    Api('user/viewApiKey')({
      ...cancelToken.config,
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setApiKey(data.api_key)
        }
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  return (<Section className="section-api-key">
    <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
      {formError.map((error, i) => <div key={i}>{error}</div>)}
    </Alert>

    <Section.Header
      title="Integração com a nossa API"
      titleAs="h3"
      opening="Gere sua chave de acesso à API. Ela é exclusiva e única por usuário"
    >
    </Section.Header>

    {apiKey !== undefined && <>
      {!apiKey ?
        <Button variant="primary"
          onClick={handleNewApiKey}
        >
          CRIAR CHAVE DE API
        </Button>
        :
        <div className="form">
          <Input
            key={apiKey}
            name="access_token"
            defaultValue={apiKey}
            readOnly={true}
          />
          <div className="form-footer flex-row">
            <Button variant="primary"
              onClick={handleNewApiKey}
            >
              GERAR NOVA CHAVE DE API
            </Button>
          </div>
        </div>
      }

      <hr />
    </>}

    <Button variant="link"
      onClick={() => window.open('https://api.assinafy.com.br/v1/docs', '_blank')}
    >
      ACESSAR DOCUMENTAÇÃO DA API
      <Icon id="icon-external" className="ms-1" size="16" />
    </Button>

    <Toast show={toast} variant="success" onClose={() => setToast('')}>
      {toast}
    </Toast>

    <ModalNew
      show={modalNew}
      setShow={setModalNew}
      setToast={setToast}
      apiKey={apiKey}
      setApiKey={setApiKey}
      setModalView={setModalView}
    />
    <ModalView
      show={modalView}
      setShow={setModalView}
      setToast={setToast}
      apiKey={apiKey}
    />
    <ModalPasswordSet
      show={modalPassword}
      setModalPassword={setModalPassword}
      user={user}
      setFormError={setFormError}
    />
  </Section>);
}

const ModalNew = ({
  show,
  setShow,
  setToast,
  apiKey,
  setApiKey,
  setModalView
}) => {
  const handleSubmit = (formData) => Api('user/generateApiKey')(formData)
    .then(({ status, data: { data } }) => {
      if (status === 200) {
        setApiKey(data.api_key)
        setShow(false)
        setToast('Chave de API criada com sucesso')
        setModalView(true)
      }
    })

  const handleClose = () => setShow(false)

  return (
    <Modal.Alert show={show}
      title={apiKey ? 'Gerar nova chave de acesso a API' : 'Criar chave de acesso à API'}
      description={<>
        {apiKey ? <p>
          Ao criar uma nova chave de acesso à API, a chave de acesso antiga com o <b>final do {apiKey.replace(/\*+/, '')}</b> vai ser invalidada automaticamente.
        </p> : <p>
          Ao criar uma chave de acesso à API, você está criando uma chave de acesso para suas informações.
        </p>}
        <p>
          <b>Insira sua senha de acesso para poder criar a chave.</b>
        </p>
      </>}
      onClose={handleClose}
    >
      <Form
        id="form-api-key"
        onSubmit={handleSubmit}
        size="lg"
        ComponentBody={ModalNewBody}
        ComponentFooter={Modal.Footer}
        controls={[
          {
            Component: Input.Password,
            name: 'password',
            placeholder: 'Senha de acesso',
            required: true,
            autoFocus: true
          }
        ]}
        buttons={[
          {
            variant: 'info', children: <>
              CANCELAR
            </>,
            onClick: handleClose
          },
          {
            variant: 'primary', submit: true, children: <>{!apiKey ? 'CRIAR CHAVE' : 'GERAR NOVA CHAVE'}</>
          }
        ]}
      />
    </Modal.Alert>
  )
}

const ModalView = ({
  show,
  setShow,
  setToast,
  apiKey
}) => {
  const handleClose = () => setShow(false)

  const handleCopy = () => {
    const text = document.querySelector('#api-key-control').value
    navigator.clipboard.writeText(text)
      .then(() => {
        setToast('A chave de acesso a API foi copiada para a área de transferência.')
      })
      .catch((error) => {
        setToast('Não foi possível copiar a chave de acesso a API para a área de transferência.')
      });
  }

  return (
    <Modal.Alert show={show}
      className="modal-api-key-view"
      title="Chave da API Gerada"
      description={<>
        <p>
          Abaixo está a chave chave de acesso a API, ela é exclusiva e única, guarde ela com segurança.
        </p>
        <p>
          <b>Copie ela agora pois você não terá mais como visualizar essa chave.</b>
        </p>
      </>}
      onClose={handleClose}
    >
      <Modal.Body>
        <Input
          id="api-key-control"
          defaultValue={apiKey}
          readOnly={true}
        />
        <Button variant="link" onClick={handleCopy}>
          <Icon id="icon-copy" className="me-1" size="16" />
          COPIAR
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          FECHAR
        </Button>
      </Modal.Footer>
    </Modal.Alert>
  )
}

const ModalNewBody = (props) => {
  return <Modal.Body>
    <Row>
      <Col lg="7">
        {props.children}
      </Col>
    </Row>
  </Modal.Body>
}

export default ApiTab;