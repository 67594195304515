import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { login, Api, clearSession } from '../../api/Assinafy';
import { Page } from '../../layout';
import { Alert, PageLoader, Icon, Input, Form, Button } from '../../components';

import './styles.scss';

const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [pageLoader, setPageLoader] = useState(false)
  const [stateAlert, setStateAlert] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { state, search } = location

  const handleSubmit = (formData) => login(formData)
    .then(({ status }) => {
      if (status === 200) {
        navigate('/dashboard')
      }
    })

  const googleLoginSuccess = (token) => {
    setPageLoader(true)

    Api('socialLogin/login')({
      token,
      provider: 'google',
      has_accepted_terms: true
    })
      .then(({ status }) => {
        if (status === 200) {
          setPageLoader(false)

          navigate('/dashboard')
        }
      })
      .catch(() => {
        setPageLoader(false)
      })
  }

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => googleLoginSuccess(access_token)
  })

  useGoogleOneTapLogin({
    onSuccess: ({ credential }) => googleLoginSuccess(credential)
  })

  useEffect(() => {
    const verificationCode = searchParams.get('verify')
    if (verificationCode) {
      navigate(`/verify/${verificationCode}`)
    } else {
      clearSession()
    }
  }, [navigate, search])

  useEffect(() => {
    if (state?.alert) {
      setStateAlert({ ...state.alert })

      window.history.replaceState(Object.assign({ ...state }, { alert: {} }), '')
    }
  }, [state])

  return (<>
    {pageLoader && <PageLoader>
      Entrando...
    </PageLoader>}

    <Page className="page-sign"
      title="Bem-vindo(a) à Assinafy"
      opening="Insira seu usuário e senha para acessar sua conta."
      btnAccount={{
        title: 'Não possui conta?',
        btn: { url: '/signup', text: 'Criar conta' }
      }}
    >

      {stateAlert &&
        <Alert variant={stateAlert.type} onClose={() => setStateAlert(false)}>
          {stateAlert.message}
        </Alert>
      }
      
      <Form.Group className="form-group-social">
        <Button
          size="lg" className="btn-block" variant="google"
          onClick={() => googleLogin()}
        >
          <Icon id="icon-google" className="me-2" size="24" />
          Entrar com o Google
        </Button>
      </Form.Group>

      <Form id="form-login" className="form-sign"
        onSubmit={handleSubmit}
        size="lg"
        controls={[
          { Component: Input.Email, name: 'email', label: 'E-mail', required: true, autoComplete: 'username', autoFocus: true },
          { Component: Input.Password, name: 'password', label: 'Senha', required: true, autoComplete: 'current-password' }
        ]}
        buttons={[
          {
            variant: 'primary', submit: true, children: <>
              Acessar conta
              <Icon id="icon-arrow_forward" className="ms-2" size="24" />
            </>
          },
          { variant: 'link', as: Link, to: '/forgot-password', children: 'Esqueceu a senha?' }
        ]}
      />
    </Page>
  </>);
};

export default Login;
