import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { SketchPicker } from 'react-color';
import { Button } from '../';

import './styles.scss';

const ColorPicker = ({ title, defaultValue, onChange, disabled, ...props }) => {
  const newClassName = classNames('colorpicker-toggle', disabled && 'disabled');
  const [show, setShow] = useState(false)
  const [color, setColor] = useState('#fff')
  const hexToRGB = (hex) => {
    hex = '0x' + hex
    let r = hex >> 16 & 0xFF
    let g = hex >> 8 & 0xFF
    let b = hex & 0xFF
    return `rgb(${r}, ${g}, ${b})`
  }

  const handleClick = () => setShow(true)

  const handleClose = (event) => {
    event.stopPropagation()
    setShow(false)
  }

  const handleChangeComplete = (color) => {
    setColor(color.hex)
    
    if (onChange) {
      onChange(color.hex.replace('#',''))
    }
  }

  useEffect(() => {
    if (defaultValue) {
      setColor(hexToRGB(defaultValue))
    }
  }, [defaultValue])

  return (<>
    <Button className={newClassName}
      onClick={handleClick}
    >
      <div className="colorpicker-color"
        style={{ backgroundColor: color }}
      />
      {title}
      {(!disabled && show) && <>
        <div className="colorpicker-overlay"
          onClick={handleClose}
        />
        <SketchPicker className="colorpicker"
          color={color}
          onChangeComplete={handleChangeComplete}
        />
      </>}
    </Button>
  </>);
}

export default ColorPicker;