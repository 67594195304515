import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Viewport = (props) => {
  const { className } = props
  const prefix = 'viewport';
  const newClassName = classNames(prefix, className);

  return (
    <div {...props} className={newClassName} />
  )
}

export default Viewport;