import React, { useEffect, useState } from 'react';
import { getSession } from '../../api/Assinafy';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Container } from '../';
import DropdownUser from '../../components/Dropdown/DropdownUser';

import logo from '../../assets/images/assinafy-logo.svg';
import './styles.scss';

const Page = ({ headerFull, btnAccount, title, opening, ...props }) => {
  const { className, children } = props
  const prefix = 'page';
  const newClassName = classNames(prefix, className);

  return (
    <div {...props} className={newClassName}>
      <Header headerFull={headerFull} btnAccount={btnAccount} />
      <Container>
        {(title || opening) &&
          <div className="page-subheader">
            <h1 className="display-3">
              {title}
            </h1>
            {opening &&
              <div className="page-opening">
                {opening}
              </div>
            }
          </div>
        }

        {children}

        {btnAccount &&
          <div className="page-footer">
            <p>
              {btnAccount.title}
            </p>
            <Link to={btnAccount.btn.url} className="btn btn-info">
              {btnAccount.btn.text}
            </Link>
          </div>
        }
      </Container>
    </div>
  );
};

const Header = ({ headerFull, btnAccount, ...props }) => {
  const session = getSession()
  const user = session?.user

  return (
    <header className="page-header">
      <Container fluid={headerFull}>
        <Link to={user ? '/dashboard' : '/'} className="logo">
          <img src={logo} height="24" alt="Assinafy logo" />
        </Link>
        <ul className="nav">
          {user ?
            <li className="nav-item">
              <DropdownUser session={session} />
            </li>
            :
            btnAccount && <li className="nav-item">
              {btnAccount.title}
              <Link to={btnAccount.btn.url} className="btn btn-info">
                {btnAccount.btn.text}
              </Link>
            </li>
          }
        </ul>
      </Container>
    </header>
  );
};

export default Page;
