import React from 'react';
import classNames from 'classnames';

import './styles.scss'

const Progress = ({
  size = 24,
  strokeWidth = 5,
  value = 0,
  max = 100,
  ...props
}) => {
  const { className } = props
  const newClassName = classNames('progress', className)
  const radius = 20
  const circumference = 2 * Math.PI * radius

  return (
    <svg className={newClassName} viewBox="25 25 50 50" style={{ width: size }}>
      <circle className="line" cx="50" cy="50" r={radius} strokeLinecap="round" style={{ strokeWidth }} />
      <circle className="dash" cx="50" cy="50" r={radius} strokeLinecap="round"
        transform="rotate(-90 50 50)"
        style={{ strokeWidth }}
        strokeDasharray={[circumference * value / max, circumference]}
      />
    </svg>
  )
}

export default Progress;