import React, { useState } from 'react';
import { Api } from '../../../api/Assinafy'
import { Section } from '../../../layout';
import { Status, Button, Icon, Table, Avatar, Spinner } from '../../../components';
import { statusIcon } from '../../../utils/Documents'

const SectionSigners = ({ document, setToast }) => {

  const signerStatusId = (signer) => {
    let status = signer.completed ? 'certificated' : 'pending_signature'

    if (document.is_closed) {
      if (signer.id === document.declined_by?.id) {
        status = 'rejected_by_signer'
      }
    }

    return status
  }

  return (
    <Section className="section-signers">
      <Section.Header
        title={`Signatários ${document.assignment.summary.completed_count}/${document.assignment.summary.signer_count}`}
      />
      <Table responsive={false}
        rows={document.assignment.summary.signers}
        renderRow={(signer, i) => <tr key={i}>
          <td>
            <div className="signer-header">
              <Avatar placeholder={signer.full_name[0] || signer.email[0]} />
              <div className="signer-title">
                {signer.full_name}
              </div>
              <div className="signer-email d-lg-none">
                {signer.email}
              </div>
            </div>
          </td>
          <td className="d-none d-lg-table-cell">
            <Status id={signerStatusId(signer)} />
          </td>
          <td className="d-none d-sm-table-cell">
            {signer.email}
          </td>
          {!document.is_closed &&
            <td>
              <div className="btn-group">
                <SectionSignersButtonResend
                  documentId={document.id}
                  documentAssignmentId={document.assignment.id}
                  signer={signer}
                  setToast={setToast}
                />
                <span className="d-lg-none">
                  <Icon id={statusIcon(signer.completed ? 'certificated' : 'pending_signature')} size="18" />
                </span>
              </div>
            </td>
          }
        </tr>}
      />
    </Section>
  )
}

const SectionSignersButtonResend = ({ documentId, documentAssignmentId, signer, setToast }) => {
  const [loading, setLoading] = useState(false)

  const handleResendAssignment = () => {
    setLoading(true)

    Api('assignment/resend')(documentId, documentAssignmentId, signer.id)
      .then(({ status, data: { data } }) => {
        setLoading(false)

        if (status === 200) {
          setToast(`Documento reenviado com sucesso para ${signer.email}`)
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Button variant="link"
      onClick={handleResendAssignment}
      loading={loading}
      loadingText={<>
        <Spinner className="me-1" />
        ENVIANDO
      </>}
    >
      <Icon id="icon-share" size="18" />
      <span className="d-none d-lg-block">
        REENVIAR
      </span>
    </Button>
  )
}

export default SectionSigners;