import React, { useState, useEffect, useRef } from 'react';
import { Api, getSession, cancelRequest } from '../../../api/Assinafy';
import { Section } from '../../../layout';
import { Button, Input, Alert } from '../../../components';
import Form, { createInputChange, createInputBlur, createInputError } from '../../../components/Form';
import { Row, Col } from 'react-bootstrap';
import { dateFormat } from '../../../utils/Formats'

const WebhooksTab = () => {
  const [active, setActive] = useState()
  const [webhooks, setWebhooks] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [formError, setFormError] = useState([])
  const [formValidation, setFormValidation] = useState([])
  const [formData, setFormData] = useState({})
  const [events, setEvents] = useState([])
  const [errors, setErrors] = useState([])
  const cancelToken = cancelRequest()
  const currentAccount = getSession().accounts[0]

  const handleSubmit = (event) => {
    event.preventDefault()

    setFormData(Object.assign(formData, { events, is_active: active }))

    const submitData = { ...formData }
    delete submitData.updated_at

    if (event.target.checkValidity()) {
      setLoading(true)

      Api('webhook/update')(currentAccount.id, submitData)
        .then(resp => {
          if (resp.status === 200) {
            setLoading(false)
            setSubmitDisabled(true)
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
          setSubmitDisabled(true)
        })
    } else {
      Array.from(event.target.elements).map((element) => {
        if (element.name) {
          setFormValidation((prevState) => [
            ...prevState,
            { name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const fetchWebhooks = () => {
    Api('webhook/eventTypes')({
      ...cancelToken.config
    })
      .then(({ status, data: { data: eventTypes } }) => {
        if (status === 200) {
          Api('webhook/index')(currentAccount.id)
            .then(({ status, data: { data } }) => {
              const { url, email, events, updated_at, is_active } = data

              setActive(is_active)

              setFormData({
                ...formData, url, email, updated_at
              })

              if (status === 200) {
                eventTypes.map((eventType) => {
                  if (events.indexOf(eventType.id) !== -1) {
                    eventType.is_active = true

                    setEvents((prevState) => [...prevState, eventType.id])
                  } else {
                    eventType.is_active = false
                  }

                  setWebhooks((prevState) => [...prevState, eventType])
                })
              }
            })
        }
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
        }
      })
  }

  const handleWebhookItemChange = (event, id) => {
    const checked = event.target.checked

    if (submitDisabled) {
      setSubmitDisabled(false)
    }

    setEvents((prevState) => {
      if (!checked) {
        return prevState.filter(eventId => eventId !== id)
      }

      return [...events, id]
    })
  }

  const handleWebhookInactivate = () => {
    Api('webhook/inactivate')(currentAccount.id)
      .then(({ status, data: { data: { is_active } } }) => {
        if (status === 200) {
          setActive(is_active)
        }
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
        }
      })
  }

  const handleChange = (event) => {
    if (submitDisabled) {
      setSubmitDisabled(false)
    }
    
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleBlur = createInputBlur(formValidation, setFormValidation)

  const showInputError = createInputError(formValidation)

  useEffect(() => {
    if (!currentAccount) {
      setErrors(['Você precisa estar em um workspace'])
      return
    }

    fetchWebhooks()

    return () => {
      cancelToken.cancel()
    }
  }, [currentAccount.id])

  return (<Section>
    <Alert show={errors.length} variant="danger">
      {errors.map((error) => error)}
    </Alert>

    <Row>
      <Col lg="6">
        {!errors.length && <>
          <Section.Header
            title="Webhooks"
            titleAs="h3"
            opening="Configure eventos que vão ser enviados para a integração."
          >
            {active !== undefined &&
              <Input.Switch labelOn="Ativado" labelOff="Desativado"
                onChange={() => active ? handleWebhookInactivate() : setActive(!active)}
                defaultChecked={active}
              />
            }
          </Section.Header>
          <Form id="form-webhook"
            onSubmit={handleSubmit}
          >
            {!!active && <>
              <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
                {formError.map((error) => error)}
              </Alert>

              <Form.Group>
                <Input name="url" size="lg"
                  placeholder="URL do sistema que vai receber os eventos"
                  defaultValue={formData.url}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {showInputError('url')}
              </Form.Group>
              <Form.Group>
                <Input.Email name="email" size="lg"
                  placeholder="E-mail que vai receber as notificações importantes"
                  defaultValue={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {showInputError('email')}
              </Form.Group>

              <div className="webhook-list">
                <h3>
                  Eventos disponíveis
                </h3>

                {webhooks && webhooks.map((webhook, i) => <WebhookItem
                  {...webhook}
                  key={i}
                  onChange={handleWebhookItemChange}
                />)}
              </div>

              <div className="webhook-updated_at">
                <h4 className="h3">
                  Last webhooks dispatched events
                </h4>
                Última atualização {dateFormat(formData.updated_at)}
              </div>

              <Form.Footer horizontal>
                <Button variant="primary" submit
                  size="lg"
                  loading={loading}
                  disabled={submitDisabled}
                >
                  Salvar Webhooks
                </Button>
              </Form.Footer>
            </>}
          </Form>
        </>}
      </Col>
    </Row>
  </Section>);
}

const WebhookItem = ({
  id,
  description,
  is_active,
  onChange
}) => {
  return (<div className="webhook-item">
    <div>
      <div className="webhook-item-title">
        {id}
      </div>
      {description}
    </div>
    <Input.Switch
      onChange={(event) => onChange(event, id)}
      defaultChecked={is_active}
    />
  </div>)
}

export default WebhooksTab;