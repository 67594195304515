import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api, getSession, createField, cancelRequest } from '../../api/Assinafy';
import { Row, Col } from 'react-bootstrap';
import { Dashboard, Section } from '../../layout';
import { Alert, Input, Select, Button, Icon } from '../../components';
import Form, { createInputChange, createInputBlur, createInputError } from '../../components/Form';
import ModalRemove from './ModalRemove';
import ModalError from './ModalError';
import _ from 'lodash';

const FieldView = (props) => {
  const updateField = Api('field/update')
  const createField = Api('field/create')
  const { isRead } = props
  const navigate = useNavigate()
  const { id: fieldId } = useParams()
  const cancelToken = cancelRequest()
  const accountId = getSession().accounts[0].id
  const [fieldTypes, setFieldTypes] = useState([])
  const [readOnly, setReadOnly] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formValidation, setFormValidation] = useState([])
  const [formError, setFormError] = useState([])
  const [formData, setFormData] = useState({
    id: fieldId
  })
  const [modalRemove, setModalRemove] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [fieldValidation, setFieldValidation] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (event.target.checkValidity()) {
      setLoading(true)

      let request
      let toast

      if (fieldId) {
        request = updateField(accountId, fieldId, formData)
        toast = 'Campo atualizado com sucesso'
      } else {
        request = createField(accountId, formData)
        toast = 'Campo criado com sucesso'
      }

      request
        .then(({ status }) => {
          if (status === 200) {
            navigate('/dashboard/workspace/fields', { state: { toast } })
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    } else {
      Array.from(event.target.elements).map((element) => {
        if (element.name) {
          setFormValidation((prevState) => [
            ...prevState,
            { name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const handleFieldChange = (event) => setFormData({
    ...formData,
    [event.target.name]: event.target.value
  })

  const handleFieldCheck = (event) => setFormData({
    ...formData,
    [event.target.name]: !formData.is_required
  })

  const handleFieldValidationChange = (event) => {
    setFieldValidation(event.target.value)
  }

  const handleBlur = createInputBlur(formValidation, setFormValidation)

  const showInputError = createInputError(formValidation)

  const handleRemove = () => {
    Api('field/delete')(accountId, fieldId)
      .then(({ status }) => {
        if (status === 200) {
          setModalRemove(false)
          navigate('/dashboard/workspace/fields', {
            state: { toast: 'Campo removido com sucesso' }
          })
        }
      })
      .catch(({ response: { data } }) => {
        setModalRemove(false)
        setModalError(data.message)
      })
  }

  useEffect(() => {
    if (
      (!fieldId && !createField) ||
      (fieldId && !updateField)
    ) {
      navigate('/dashboard/workspace/fields')
      return;
    }

    if (fieldId) {
      Api('field/view')(accountId, fieldId, {
        ...cancelToken.config,
      })
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            setFormData(data)
            setFieldValidation(data.regex ? 'regular-expression' : '')
          }
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            navigate('/dashboard/workspace/fields')
          }
        })
    } else {
      setReadOnly(false)
    }

    return () => {
      cancelToken.cancel()
    }
  }, [fieldId]);

  useEffect(() => {
    Api('field/fieldTypesIndex')({
      ...cancelToken.config,
    })
      .then(({ data: { data } }) => {
        data.map(({ type, name }) => {
          setFieldTypes((prevState) => [
            ...prevState,
            { label: name, value: type }
          ])
        })
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  useEffect(() => {
    setReadOnly(isRead)
  }, [isRead])

  return (<Dashboard.Content>
    <Dashboard.Header
      title={readOnly ? `${formData.name}` : formData.name ? `Editando ${formData.name}` : 'Novo campo'}
    />
    <Section>
      <Section.Header
        title={
          readOnly
            ? 'Segue abaixo as caracteristicas desse campo.'
            : 'Informe abaixo as caracteristicas desse novo campo.'
        }
        titleAs="h4"
      />
      <Form id="form-field"
        onSubmit={handleSubmit}
      >
        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>

        <Row>
          <Col lg={5}>
            <Form.Group>
              <Input name="name" label="Nome do campo"
                size="lg"
                onChange={handleFieldChange}
                onBlur={handleBlur}
                defaultValue={formData.name}
                required
                readOnly={readOnly}
              />
              {showInputError('name')}
            </Form.Group>
            <Form.Group>
              {_.isEmpty(fieldTypes)
                ? <div className="form-control form-control-lg disabled">
                  Carregando...
                </div>
                : <Select name="type" label="Tipo de campo"
                  size="lg"
                  options={fieldTypes}
                  onChange={handleFieldChange}
                  onBlur={handleBlur}
                  value={formData.type}
                  required
                  readOnly={readOnly}
                />
              }
              {showInputError('type')}
            </Form.Group>
            {/* <Form.Group>
              <Input.Tel name="caracteres" label="Número máximo de caracteres"
                onChange={handleFieldChange}
                required
                readOnly={readOnly}
              />
            </Form.Group> */}
            <Form.Group>
              <Select name="validation-type" label="Validação"
                size="lg"
                options={[
                  { 'label': 'Sem validação', 'value': 'no' },
                  { 'label': 'Expressão regular', 'value': 'regular-expression' }
                ]}
                onChange={handleFieldValidationChange}
                onBlur={handleBlur}
                value={fieldValidation}
                required
                readOnly={readOnly}
              />
              {showInputError('validation-type')}
            </Form.Group>
            {fieldValidation === 'regular-expression' &&
              <Form.Group>
                <Input name="regex" label="Informe a expressão"
                  size="lg"
                  onChange={handleFieldChange}
                  readOnly={readOnly}
                  value={formData.regex}
                />
              </Form.Group>
            }
            {/* <Form.Group>
              <Form.Check>
                <Input.Checkbox name="is_required" value="1"
                  checked={formData.is_required}
                  onChange={handleFieldCheck}
                />
                Obrigatório
              </Form.Check>
            </Form.Group> */}
            <Form.Footer horizontal>
              {readOnly
                ? <Button variant="primary" as={Link}
                  to={`/dashboard/fields/${fieldId}/edit`}
                >
                  <Icon id="icon-edit" className="me-1" size="16" />
                  EDITAR CAMPO
                </Button>
                : <Button variant="primary" submit loading={loading}>
                  <Icon id="icon-check" className="me-1" size="16" />
                  {fieldId ? 'SALVAR ALTERAÇÕES' : 'SALVAR CAMPO'}
                </Button>
              }
              <Button variant="info" onClick={() => navigate(-1)}>
                {readOnly ? 'VOLTAR' : 'CANCELAR'}
              </Button>
            </Form.Footer>
          </Col>
        </Row>
      </Form>
    </Section>
    {(!isRead && fieldId) &&
      <Section>
        <Section.Header
          title="Remover campo"
          titleAs="h4"
          opening="Ao remover o campo, ele não ficará mais visível na preparação dos documentos."
        />
        <Button variant="danger"
          onClick={() => setModalRemove(formData)}
        >
          REMOVER CAMPO
        </Button>
      </Section>
    }
    <ModalRemove
      modalRemove={modalRemove}
      setModalRemove={setModalRemove}
      handleRemove={handleRemove}
    />
    <ModalError
      modalError={modalError}
      setModalError={setModalError}
    />
  </Dashboard.Content>)
}

export default FieldView;