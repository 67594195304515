const dateFormat = (date) => {
  return new Date(date).toLocaleString('pt-BR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}

const timeFormat = (date) => {
  return new Date(date).toLocaleString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit'
  })
}

const currencyFormat = (value, digits = 2) => {
  if (!value) {
    return
  }

  return parseFloat(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: digits
  })
}

const phoneNumber = (value) => {
  if (!value) return

  return value.replace(/\D/g, '')
    .replace(/^(\d{1})/, '($1')
    .replace(/^(\(\d{2})(\d)/, '$1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2');
}

const cpfFormat = (value) => {
  if (!value) return

  return value.replace(/\D/g, '')
    .replace(/^(\d{3})(\d)/, '$1.$2')
    .replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2')
    .replace(/^(\d{3}\.\d{3}\.\d{3})(\d)/, '$1-$2');
}

export {
  dateFormat,
  timeFormat,
  currencyFormat,
  phoneNumber,
  cpfFormat
}