import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Tooltip = ({show, placement = 'top', ...props}) => {
  const { className, children } = props
  const prefix = 'tooltip';
  const newClassName = classNames(prefix, placement, className)

  if (!show) {
    return (<></>)
  }

  return (
    <div {...props} className={newClassName}>
      <div className={`${prefix}-body`}>
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
