import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Page } from '../../layout';
import { Alert, Icon } from '../../components';
import { currencyFormat } from '../../utils/Formats';

import './styles.scss';

const Plans = () => {
  const location = useLocation()
  const { state } = location

  const alertClose = () => {
    window.history.replaceState(Object.assign({ ...state }, { alert: {} }), '')
  }

  const plansList = [{
    title: 'Free',
    price: 0,
    docsTotal: 5,
    docsExtra: 0,
    link: '/plan/free'
  }, {
    title: 'Standard',
    price: 39.00,
    docsTotal: 50,
    docsExtra: 1.00,
    link: '/plan/standard'
  }, {
    title: 'Pro',
    price: 99.00,
    docsTotal: 200,
    docsExtra: 0.5,
    link: '/plan/pro'
  }, {
    title: 'Enterprise',
    price: 199.00,
    docsTotal: 500,
    docsExtra: 0.39,
    link: '/plan/enterprise'
  }];

  return (
    <Page className="page-plans"
      title="Planos Assinafy"
      opening="Escolha o plano que melhor atende às necessidades de sua empresa."
    >
      {state?.alert &&
        <Alert variant={state.alert.type} onClose={alertClose}>
          {state.alert.message}
        </Alert>
      }

      <List>
        {plansList.map((item, i) =>
          <Item key={i} {...item} />
        )}
      </List>

      <Infos />
    </Page>
  );
};

const List = (props) => {
  return (
    <div {...props} className="plans-list" />
  );
};

const Item = (props) => {
  const { title, price, docsTotal, docsExtra, link } = props;

  return (
    <div className="plan-item">
      <div className="plan-header">
        <h2 className="plan-title">
          {title}
        </h2>
        <div className="plan-price">
          {price > 0 ?
            <>
              {currencyFormat(price, 0)}
              <span>/mês</span>
            </>
            : 'Grátis'}
        </div>
      </div>
      <div className="plan-body">
        <div className="plan-docs-total">
          {docsTotal} documentos
          <span>/mês</span>
        </div>
        {docsExtra > 0 &&
          <div className="plan-docs-extra">
            {currencyFormat(docsExtra)} por documento adicional
          </div>
        }
      </div>
      <div className="plan-footer">
        <Link to={link} className="btn">
          Contratar
          <Icon id="icon-arrow_forward" className="ms-2" size="24" />
        </Link>
      </div>
    </div>
  );
};

const Infos = () => {
  return (
    <div className="plans-infos">
      <h3 className="h4">
        Todos os planos acima possuem
      </h3>
      <ul>
        <li>Signatários ilimitados</li>
        <li>Assinatura com rubrica</li>
        <li>Envio de contrato via e-mail</li>
        <li>API para integração</li>
        <li>Backup de contratos</li>
        <li>Envio de contrato via SMS e WhatsApp</li>
        <li>Assinatura eletrônica</li>
        <li>Notificação de entrega e abertura do documento </li>
      </ul>
    </div>
  );
}

export default Plans;
