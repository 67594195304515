import React from 'react';

import './styles.scss';

const Scroll = (props) => {
    return (
        <div {...props} className="scroll" />
    )
}

export default Scroll;