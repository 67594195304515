import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../api/Assinafy';
import { Alert, Textarea, Button, Icon, Modal } from '../../../components';
import Form, { createInputChange, createInputBlur, createInputError } from '../../../components/Form';

const ModalDeny = ({
  params,
  onComplete,
  ...props
}) => {
  const { signerAccessCode, documentId, assignmentId } = params
  const { onClose } = props
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formValidation, setFormValidation] = useState([])
  const [formData, setFormData] = useState({})
  const navigate = useNavigate()

  if (!onComplete) {
    onComplete = () => navigate(`/sign/${signerAccessCode}/closed`)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    let endpoint = 'signer/rejectAssignment'
    if (Array.isArray(documentId)) {
      endpoint = 'signer/declineMultiple'
    }

    if (event.target.checkValidity()) {
      setLoading(true)

      Api(endpoint)(documentId, assignmentId, formData, signerAccessCode)
        .then(({ status }) => {
          if (status === 200) {
            onComplete()
            setLoading(false)
          }
        })
        .catch(({ response }) => {
          setFormError([response.data.message])
          setLoading(false)
        })
    } else {
      Array.from(event.target.elements).map((element) => {
        if (element.name) {
          setFormValidation((prevState) => [
            ...prevState,
            { name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const handleClose = () => {
    onClose()
    setLoading(false)
    setFormError([])
  }

  const handleChange = createInputChange(formData, setFormData)

  const handleBlur = createInputBlur(formValidation, setFormValidation)

  const showInputError = createInputError(formValidation)

  return (
    <Modal.Alert {...props}
      variant="danger"
      icon="icon-close"
      title={`Deseja realmente recusar a assinatura ${Array.isArray(documentId) ? 'desses documentos' : 'desse documento'}?`}
      buttonConfirm="RECUSAR DOCUMENTO"
      onClose={handleClose}
    >
      <Form id="form-sign-decline-confirm"
        onSubmit={handleSubmit}
      >
        <Modal.Body>
          <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
            {formError.map((error) => error)}
          </Alert>

          <Form.Group>
            <Textarea name="decline_reason" label="Descreva o motivo da recusa"
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            <Form.Text>
              Essas informações serão compartilhadas somente com o remetente.
            </Form.Text>
            {showInputError('decline_reason')}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" submit loading={loading}>
            <Icon id="icon-close" className="me-1" size="16" />
            RECUSAR DOCUMENTO
          </Button>
        </Modal.Footer>
      </Form>
    </Modal.Alert>
  )
};

export default ModalDeny;