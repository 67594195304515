import React, { useEffect, useState, forwardRef } from 'react';
import classNames from 'classnames';
import { Alert, Button } from '../';

import './styles.scss';

const Form = forwardRef(({
  size,
  data = {},
  controls,
  buttons,
  onSubmit,
  wasValidated,
  ComponentBody = React.Fragment,
  ComponentFooter = Form.Footer,
  isFooterHorizontal,
  ...props
}, ref) => {
  const { className, children } = props
  const newClassName = classNames('form', className)
  const [validated, setValidated] = useState(!!wasValidated)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const [formValidation, setFormValidation] = useState([])
  const [formError, setFormError] = useState([])

  const handleSubmit = (event) => {
    setValidated(true)

    if (children) {
      onSubmit(event)
    } else {
      event.preventDefault()

      setFormValidation([])

      if (event.target.checkValidity()) {
        setLoading(true)

        if (onSubmit) {
          onSubmit(formData)
            .catch(({ response }) => {
              if (response) {
                setFormError([response.data.message])
              }

              setLoading(false)
            })
        }
      } else {
        Array.from(event.target.elements).map((element) => {
          if (element.name) {
            setFormValidation((prevState) => [
              ...prevState,
              { name: element.name, message: element.validationMessage }
            ])
          }
        })
      }
    }
  }

  const handleChange = createInputChange(formData, setFormData)

  const handleBlur = createInputBlur(formValidation, setFormValidation)

  const showInputError = createInputError(formValidation)

  useEffect(() => {
    setFormData(Object.assign(formData, data));
  }, [data])

  return (
    <form ref={ref} {...props}
      noValidate={true}
      className={`${newClassName} ${validated ? 'was-validated' : ''}`}
      onSubmit={handleSubmit}
    >
      <ComponentBody>
        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>

        {controls?.map(({ Component, help, visible, onBlur, ...field }, i) => {
          if (visible === false) {
            return
          }

          return (<Form.Group key={i}>
            <Component
              {...field}
              size={field.size || size}
              onChange={handleChange}
              onBlur={(event) => {
                if (onBlur) {
                  onBlur(formData)
                }

                handleBlur(event)
              }}
            />
            {help && <Form.Text>
              {help}
            </Form.Text>}
            {showInputError(field.name)}
          </Form.Group>)
        })}

        {children}
      </ComponentBody>

      {buttons && <ComponentFooter horizontal={isFooterHorizontal}>
        {buttons.map((button, i) => {
          return (<Button key={i} {...button} size={size} loading={button.submit && loading} />)
        })}
      </ComponentFooter>}
    </form>
  );
});

const FormGroup = (props) => {
  const { className } = props
  const newClassName = classNames('form-group', className);

  return (
    <div {...props} className={newClassName} />
  );
};

const FormLabel = (props) => {
  return (
    <label {...props} className="form-label" />
  );
};

const FormCheck = (props) => {
  const { className } = props
  const newClassName = classNames('form-check', className);

  return (
    <label {...props} className={newClassName} />
  );
};

const FormText = (props) => {
  const { className } = props
  const newClassName = classNames('form-text', className);

  return (
    <small {...props} className={newClassName} />
  );
};

const FormFooter = ({ horizontal, ...props }) => {
  const { className } = props
  const newClassName = classNames('form-footer', horizontal && 'flex-lg-row', className);

  return (
    <div {...props} className={newClassName} />
  );
};

const createInputError = (formValidation) => {
  return (inputName) => {
    const error = formValidation.find((error) => error.name === inputName)
    if (error) {
      return (<div className="help-block">
        {error.message}
      </div>)
    }
  }
}

const createInputChange = (formData, setFormData) => {
  return (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }
}

const createInputBlur = (formValidation, setFormValidation) => {
  return (event) => {
    const element = event.target

    if (element.checkValidity()) {
      setFormValidation(formValidation.filter((error) => error.name !== element.name))
    } else {
      setFormValidation([
        ...formValidation.filter((error) => error.name !== element.name),
        { name: element.name, message: element.validationMessage }
      ])
    }
  }
}

Form.Group = FormGroup;
Form.Label = FormLabel;
Form.Check = FormCheck;
Form.Text = FormText;
Form.Footer = FormFooter;

export default Form;
export { createInputChange, createInputBlur, createInputError };