import React, { useState, useEffect } from 'react'
import { statusIcon, statusTitle } from '../../utils/Documents'
import { Badge, Icon } from '../'

const Status = ({ variant, as, id, icon, title, ...props }) => {
  return (
    <Badge {...props} variant={variant} as={as}>
      <Icon id={statusIcon(id, icon)} size="14" />
      {statusTitle(id, title)}
    </Badge>
  );
};

const List = ({ variant, defaultValue, ...props }) => {
  const { items, onItemClick } = props
  const [actives, setActives] = useState(defaultValue?.split(',') || [])
  const itemAll = {
    id: 'all',
    icon: 'description',
    title: 'Todos'
  }

  const handleClick = (event) => {
    const value = event.target.value

    if (!actives.includes(value)) {
      setActives([
        ...actives,
        event.target.value
      ])
    } else {
      setActives(actives.filter(id => id !== value))
    }
  }

  const handleClickAll = () => setActives([])

  const updateClassname = (item) => {
    return actives.includes(item.id) && 'active'
  }

  useEffect(() => {
    const activesToString = actives.join(',')
    if (activesToString !== defaultValue) {
      onItemClick(activesToString)
    }
  }, [actives])

  return (
    <div className="badge-list-wrapper">
      <div className="badge-list">
        <Status key={-1} {...itemAll}
          variant={variant}
          as="button"
          type="submit"
          name="status"
          onClick={handleClickAll}
          className={!actives.length && 'active'}
        >
          Todos
        </Status>
        {items.map((item) =>
          <Status key={item.id} {...item}
            variant={variant}
            as="button"
            type="submit"
            name="status"
            value={item.id}
            onClick={handleClick}
            className={updateClassname(item)}
          />
        )}
      </div>
    </div>
  )
}

Status.List = List;

export default Status;
