import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Api, getSession, cancelRequest } from '../../../api/Assinafy'
import { roles } from '../../../utils/User';
import { Row, Col } from 'react-bootstrap';
import { Dashboard, Section } from '../../../layout'
import { Form, Input, Select, Button, Icon, Tooltip, Dropdown, Avatar, Table, Alert, Badge, Modal, Toast } from '../../../components'
import { v4 as uuid } from 'uuid';

const TeamTab = () => {
  const [theme, setTheme] = useState({})
  const [members, setMembers] = useState()
  const [errors, setErrors] = useState([])
  const [toast, setToast] = useState('')
  const [modalRemove, setModalRemove] = useState(false)
  const [modalError, setModalError] = useState(false)
  const [modalInvite, setModalInvite] = useState(false)
  const currentAccount = getSession().accounts[0]
  const cancelToken = cancelRequest()

  const handleFetchAccountMembers = () => {
    Api('accountUser/memberIndex')(currentAccount.id, {
      ...cancelToken.config,
      params: {
        // 'per-page': 8
      }
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setMembers(data)
        }
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
        }
      })
  }

  const handleRemove = (id) => {
    const member = members.find(m => m.id === id)

    if (member?.type === 'User') {
      Api('accountUser/delete')(currentAccount.id, id)
        .then(({ status }) => {
          if (status === 200) {
            handleFetchAccountMembers()
            setToast('Usuário removido com sucesso')
            setModalRemove(false)
          }
        })
    } else {
      Api('userInvitation/delete')(id)
        .then(({ status }) => {
          if (status === 200) {
            handleFetchAccountMembers()
            setToast('Convite removido com sucesso')
            setModalRemove(false)
          }
        })
    }
  }

  useEffect(() => {
    if (!currentAccount) {
      setErrors(['Você precisa estar em um workspace'])
      return
    }

    handleFetchAccountMembers()

    Api('account/theme')(currentAccount.id, {
      ...cancelToken.config
    })
      .then(({ data: { data } }) => {
        setTheme(data)
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  return (<Section>

    <Alert show={errors.length} variant="danger">
      {errors.map((error) => error)}
    </Alert>

    {!errors.length && <>
      <Section.Header
        title={`Equipe ${theme.account_name}`}
        titleAs="h3"
        opening="Lista com todos as pessoas dessa equipe e níveis de acesso"
      >
        <Dashboard.FixedBottom>
          {Api('userInvitation/create') && <>
            <Button variant="primary"
              onClick={() => setModalInvite(true)}
            >
              CONVIDAR
            </Button>
            <Button variant="primary"
              className="btn-scroll-top"
              onClick={() => setModalInvite(true)}
            >
              <Icon id="icon-add" />
            </Button>
          </>}
        </Dashboard.FixedBottom>
      </Section.Header>

      <Table className="table-bordered table-hover"
        header={[
          { name: 'Nome' },
          { name: '' },
          { name: 'E-mail', className: 'd-none d-sm-table-cell' },
          { name: 'Nível de acesso', className: 'd-none d-sm-table-cell' },
          { name: '' }
        ]}
        rows={members}
        renderRow={(member) => {
          return (<tr key={member.id}>
            <td>
              {member.type === 'User'
                ? <Link to={`/dashboard/users/${member.id}/view`} className="user-header">
                  <Avatar placeholder={member.name[0] || member.email[0]} />
                  <div className="user-title">
                    {member.name}
                  </div>
                </Link>
                : <div className="user-header">
                  <Avatar placeholder={member.name[0] || member.email[0]} />
                  <div className="user-title">
                    {member.name}
                  </div>
                </div>
              }
            </td>
            <td>{member.type === 'Invitation' && <Badge>
              <Icon id="icon-info" className="text-warning" size="14" />
              Convite enviado
            </Badge>}</td>
            <td className="d-none d-sm-table-cell">
              {member.email}
            </td>
            <td className="d-none d-sm-table-cell">
              {member.roles.join(', ')}
            </td>
            <td className="col-action">
              <TableActions
                member={member}
                setModalRemove={setModalRemove}
              />
            </td>
          </tr>);
        }}
      />

      <Modal.Alert show={modalRemove}
        variant="danger"
        icon="icon-trash"
        title={`Deseja realmente remover ${modalRemove.name}?`}
        description={
          modalRemove.roles?.includes('owner') && <>
            <p>
              Ao remover {modalRemove.name}, o usuário perderá acesso ao workspace {theme.account_name}
              e não terá acesso a todos os documentos dele.
            </p>
            <p>
              Selecione abaixo para quem você deseja transferir os documentos que {modalRemove.name}
              era responsável. O usuário selecionado receberá as notificações desses documentos.
            </p>
          </>}
        onClose={() => setModalRemove(false)}
      >
        {modalRemove.roles?.includes('owner') &&
          <Modal.Body>
            <Row>
              <Col lg="8">
                <Form.Group>
                  <Select name="member" size="lg"
                    label="Selecione o novo responsável"
                    autoFocus={true}
                    options={members?.map((m) => { return { label: m.name, value: m.id } })}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
        }
        <Modal.Footer>
          <Button variant="info"
            onClick={() => setModalRemove(false)}
          >
            CANCELAR
          </Button>
          <Button variant="danger" submit
            onClick={() => handleRemove(modalRemove.id)}
          >
            <Icon id="icon-trash" className="me-1" size="16" />
            REMOVER
          </Button>
        </Modal.Footer>
      </Modal.Alert>

      <Modal.Alert show={modalError}
        variant="warning"
        icon="icon-warning"
        title="Não foi possível remover o campo"
        description="O campo checkbox está sendo utilizado em documentos aguardando assinatura."
        onClose={() => setModalError(false)}
      >
        <Modal.Footer>
          <Button variant="info" submit
            onClick={() => setModalError(false)}
          >
            OK, FECHAR
          </Button>
        </Modal.Footer>
      </Modal.Alert>

      <ModalInvite show={modalInvite}
        setModalInvite={setModalInvite}
        theme={theme}
      />
    </>}

    <Toast show={toast} variant="success" onClose={() => setToast('')}>
      {toast}
    </Toast>
  </Section>);
}

const TableActions = ({ member, setModalRemove }) => {
  const navigate = useNavigate()
  const dropdownRef = useRef()

  const getActions = () => {
    if (member.type === 'User') {
      return [
        {
          icon: 'icon-visibility',
          text: 'VISUALIZAR',
          onClick: () => navigate(`/dashboard/users/${member.id}/view`)
        },
        Api('authorization/assignRole') && {
          icon: 'icon-edit',
          text: 'EDITAR',
          onClick: () => navigate(`/dashboard/users/${member.id}/view`)
        },
        Api('accountUser/delete') && {
          icon: 'icon-trash',
          text: 'EXCLUIR',
          onClick: () => setModalRemove(member)
        }
      ]
    }

    return [
      Api('userInvitation/delete') && {
        icon: 'icon-trash',
        text: 'EXCLUIR',
        onClick: () => setModalRemove(member)
      }
    ]
  }

  return (<div className="table-actions">
    <Dropdown
      ref={dropdownRef}
      align="right"
      variant="action"
      button={<Icon id="icon-more_vert" />}
    >
      {getActions().map(({ icon, text, onClick }, i) => {
        if (!text) {
          return;
        }

        return (<Dropdown.Item key={i}
          as={Button}
          onClick={onClick}
        >
          <Icon id={icon} className="me-1" size="20" />
          {text}
        </Dropdown.Item>)
      })}
    </Dropdown>
  </div>)
}

const ModalInvite = ({ setModalInvite, theme, ...props }) => {
  const { show } = props
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [newInvites, setNewInvites] = useState([])
  const navigate = useNavigate()
  const currentAccount = getSession().accounts[0]

  const handleClose = () => {
    setModalInvite(false)
    setFormError([])
    setValidated(false)
    setLoading(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    setValidated(true)

    if (event.target.checkValidity()) {
      setLoading(true)

      Promise.all(newInvites.map(async ({ email, role }) => {
        await Api('userInvitation/create')({
          account_id: currentAccount.id,
          email,
          role
        })
      }))
        .then(() => {
          handleClose()
          navigate(0)
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    }
  }

  const emptyInvite = () => {
    return {
      uuid: uuid(),
      email: '',
      role: ''
    }
  }

  const addNewInvite = () => {
    setNewInvites([
      ...newInvites,
      emptyInvite()
    ])
  }

  const updateInvite = (newInvite, index) => {
    const newNewInvites = [...newInvites];
    newNewInvites[index] = newInvite;
    setNewInvites(newNewInvites);
  }

  const removeInvite = (index) => {
    const newNewInvites = [...newInvites]
    if (newNewInvites.length > 1) {
      newNewInvites.splice(index, 1)
    } else {
      newNewInvites[index] = emptyInvite()
    }
    setNewInvites(newNewInvites)
  }

  useEffect(() => {
    if (!newInvites.length) {
      addNewInvite()
    }
  }, [newInvites])

  return (<Modal show={show}
    onHide={handleClose}
    centered
  >
    <Form id="form-team-invite"
      onSubmit={handleSubmit}
    >
      <Modal.Header>
        <Button variant="icon" className="btn-close"
          onClick={handleClose}
        >
          <Icon id="icon-close" size="24" />
        </Button>
        <Modal.Title as={'div'} className="h2">
          Convite para equipe {theme.account_name}
        </Modal.Title>
        Informe abaixo o e-mail de quem você quer convidar.
      </Modal.Header>
      <Modal.Body>

        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>

        {newInvites.map((invite, i) =>
          <NewInvite key={invite.uuid}
            index={i}
            {...invite}
            onUpdate={updateInvite}
            onRemove={removeInvite}
          />
        )}
        <Form.Footer horizontal>
          <Button variant="link"
            onClick={addNewInvite}
          >
            <Icon id="icon-add" className="me-1" size="18" />
            ADICIONAR MAIS
          </Button>
        </Form.Footer>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" submit
          loading={loading}
        >
          ENVIAR CONVITE
        </Button>
      </Modal.Footer>
    </Form>
  </Modal >)
}

const NewInvite = ({ index, onUpdate, onRemove, ...props }) => {
  const { uuid, email, role } = props
  const [tooltipShow, setTooltipShow] = useState(false)
  const [newInvite, setNewInvite] = useState({ uuid, email, role })

  const handleInputChange = (event) => {
    setNewInvite({
      ...newInvite,
      [event.target.name]: event.target.value
    });
  }

  const tooltipMouseEnter = () => {
    setTooltipShow(true)
  }

  const tooltipMouseLeave = () => {
    setTooltipShow(false)
  }

  useEffect(() => {
    onUpdate && onUpdate(newInvite, index)
  }, [newInvite])

  return (
    <Row>
      <Col>
        <Form.Group>
          <Select name="role" size="lg"
            label="Nível de acesso"
            autoFocus={true}
            options={roles}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
      </Col>
      <Col lg="6">
        <Form.Group>
          <Input.Email name="email"
            onChange={handleInputChange}
            label="E-mail"
            size="lg"
            required
          />
        </Form.Group>
      </Col>
      <Col xs="auto">
        <Button variant="icon" className="btn-remove"
          onMouseEnter={tooltipMouseEnter}
          onMouseLeave={tooltipMouseLeave}
          onClick={() => onRemove(index)}
        >
          <Icon id="icon-trash" size="28" />
          <Tooltip show={tooltipShow}>
            Excluir
          </Tooltip>
        </Button>
      </Col>
    </Row>
  )
}

export default TeamTab;