import React, { useEffect } from 'react';
import { clearSession } from '../../api/Assinafy';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    clearSession()
    window.pageCanvas = []

    setTimeout(() => {
      navigate('/')
    }, 150);
  }, [])

  return (<></>);
};

export default Logout;
