import React from 'react';
import { Button, Modal } from '../../components';

const ModalError = ({ modalError, setModalError }) => {
  return (
    <Modal.Alert show={!!modalError}
      variant="warning"
      icon="icon-warning"
      title="Não foi possível remover o campo"
      description={modalError}
      onClose={() => setModalError(false)}
    >
      <Modal.Footer>
        <Button variant="info" submit
          onClick={() => setModalError(false)}
        >
          OK, FECHAR
        </Button>
      </Modal.Footer>
    </Modal.Alert>
  )
}

export default ModalError;