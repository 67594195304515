import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Dropdown, Button, Icon } from '../../components';

import logo from '../../assets/images/assinafy-logo.svg';
import logoSmall from '../../assets/images/a-logo.svg';
import './styles.scss';

const Sign = (props) => {
  const { className, children } = props
  const newClassName = classNames('sign', className);

  return (<div className={newClassName}>
    {children}
  </div>)
}

export function SignNavbar({
  toggler = true,
  ...props
}) {
  const { children, items, buttonNext } = props
  const [navbarShow, setNavbarShow] = useState(false)

  return (
    <nav className={`navbar${navbarShow ? ' navbar-open' : ''}`}>
      <Link to={'/'} className="logo">
        <img src={navbarShow ? logoSmall : logo} height="24" alt="Assinafy logo" />
      </Link>
      {children}
      {toggler && <Button className="btn-navbar"
        onClick={() => setNavbarShow(!navbarShow)}
      >
        <span></span><span></span><span></span>
      </Button>}
      {items && <div id="navbar">
        <ul className="nav d-lg-none">
          {items.map((item, i) =>
            <li key={i} className="nav-item">
              <Button {...item} />
            </li>
          )}
        </ul>
        <ul className="nav d-none d-lg-flex">
          <li className="nav-item">
            <Dropdown
              button={<>
                OUTRAS AÇÕES
                <Icon id="icon-arrow_down" className="ms-1" size="16" />
              </>}
              variant="light"
              align="right"
            >
              {items.map((item, i) =>
                <Dropdown.Item key={i} {...item} as="button" type="button" />
              )}
            </Dropdown>
          </li>
          {!!buttonNext &&
            <li className="nav-item">
              <Button {...buttonNext} variant="primary" />
            </li>
          }
        </ul>
        <div className="navbar-footer d-lg-none">
          <Icon id="icon-lock" className="me-1" size="14" />
          Ambiente seguro Assinafy
        </div>
      </div>}
    </nav>
  )
}

export default Sign;