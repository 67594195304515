import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api, getSession, cancelRequest } from '../../api/Assinafy';
import { roles, getRoleName } from '../../utils/User';
import { Dashboard, Section } from '../../layout';
import { Form, Alert, Table, Button, Icon, Select } from '../../components';
import _ from 'lodash';

const UserView = (props) => {
  const accountAssignRole = Api('authorization/assignRole')
  const accountRevokeRole = Api('authorization/revokeRole')
  const { isRead } = props
  const [readOnly, setReadOnly] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formData, setFormData] = useState({})
  const [formDataView, setFormDataView] = useState({})
  const cancelToken = cancelRequest()
  const navigate = useNavigate()
  const accountId = getSession().accounts[0].id
  const { id: userId } = useParams()

  const handleFieldValueChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (event.target.checkValidity()) {
      setLoading(true)

      accountAssignRole(accountId, {
        user_id: formData.id,
        role: _.isArray(formData.roles) ? formData.roles[0] : formData.roles
      })
        .then(({ status }) => {
          if (status === 200) {
            const rolesRevoke = roles.filter((role) => role.value !== formData.roles).map((role) => role.value).join(',')
            accountRevokeRole(accountId, {
              user_id: formData.id,
              role: rolesRevoke
            })
              .then(({ status }) => {
                if (status === 200) {
                  navigate('/dashboard/workspace/team', {
                    state: {
                      toast: 'Usuário atualizado com sucesso'
                    }
                  })
                }
              })
              .catch(({ response }) => {
                if (response) {
                  setFormError([response.data.message])
                }

                /* TODO: temporary code */
                if (rolesRevoke.value === 'owner') {
                  accountRevokeRole(accountId, {
                    user_id: formData.id,
                    role: 'organizer'
                  })
                }

                setLoading(false)
              })
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    }
  }

  useEffect(() => {
    if (userId) {
      Api('accountUser/userView')(accountId, userId, {
        ...cancelToken.config,
      })
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            setFormDataView(data)
            setFormData(data)
          }
        })
    } else {
      setReadOnly(false)
    }

    return () => {
      cancelToken.cancel()
    }
  }, [userId]);

  useEffect(() => {
    if (!isRead && !accountAssignRole) {
      navigate('/dashboard/workspace/team')
    }

    if (!isRead) {
      setFormData(formDataView)
    }

    setReadOnly(isRead)
  }, [isRead])

  return (<Dashboard.Content>
    <Dashboard.Header
      title={readOnly ? `${formData.name}` : `Editando ${formData.name}`}
    />
    <Section>
      <Form id="form-user"
        onSubmit={handleSubmit}
      >
        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>

        <Table
          className="table-form"
          rows={[
            ['Nome', formDataView.name, ''],
            ['E-mail', formDataView.email, ''],
            // ['Telefone', formDataView.telephone, ''],
            ['Nível de acesso', isRead
              ? getRoleName(formDataView.roles)
              : <Form.Group>
                <Select name="roles" size="lg"
                  label="Selecione o nível de acesso"
                  options={roles}
                  onChange={handleFieldValueChange}
                  value={!!formData.roles && (_.isArray(formData.roles) ? formData.roles[0] : formData.roles)}
                  required
                />
              </Form.Group>,
              <>{formData.roles?.includes('owner') && <>
                <Icon id="icon-info" className="me-1" size="14" />
                <small>Pode fazer tudo, menos deletar o proprietário.</small>
              </>}</>
            ],
            // ['Convidado por', formData.is_active, ''],
            ['Última atividade', new Date(formDataView.updated_at).toLocaleString('pt-BR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            }), ''],
          ]}
        />
        <Form.Footer horizontal>
          {readOnly
            ? accountAssignRole && <Button variant="primary" as={Link}
              to={`/dashboard/users/${userId}/edit`}
            >
              <Icon id="icon-edit" className="me-1" size="16" />
              EDITAR
            </Button>
            : <Button variant="primary" submit loading={loading}>
              <Icon id="icon-check" className="me-1" size="16" />
              {userId ? 'SALVAR ALTERAÇÕES' : 'SALVAR'}
            </Button>
          }
          <Button variant="info" onClick={() => navigate(-1)}>
            {readOnly ? 'VOLTAR' : 'CANCELAR'}
          </Button>
        </Form.Footer>
      </Form>
    </Section>
  </Dashboard.Content>)
}

export default UserView;