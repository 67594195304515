import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { Api } from '../../../api/Assinafy';
import { Button, Icon } from '../../../components';

import imgCreateFreeAccount from '../../../assets/images/img-create-free-account.png';
import './styles.scss';

const CreateFreeAccount = () => {
  const [pageLoader, setPageLoader] = useState(false)
  const navigate = useNavigate()

  const googleLoginSuccess = (token) => {
    setPageLoader(true)

    Api('socialLogin/login')({
      token,
      provider: 'google',
      has_accepted_terms: true
    })
      .then(({ status }) => {
        if (status === 200) {
          setPageLoader(false)

          navigate('/dashboard')
        }
      })
      .catch(() => {
        setPageLoader(false)
      })
  }

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => googleLoginSuccess(access_token)
  })

  return (<>
    <div className="create-free-account">
      <div className="create-free-account-body">
        <h3 className="h1">
          Crie sua conta grátis!
        </h3>
        Com a Assinafy, você envia documentos para outras pessoas assinarem. É rápido, seguro e grátis!
        <div className="btn-group">
          <Button variant="google"
            onClick={() => googleLogin()}
          >
            <Icon id="icon-google" className="me-2" size="24" />
            Criar com o Google
          </Button>
          <Button as={Link} variant="primary"
            to={'/signup'}
          >
            CRIAR COM E-MAIL
          </Button>
        </div>
      </div>
      <img src={imgCreateFreeAccount} className="create-free-account-img" height="186" alt="" />
    </div>
    <p className="small">
      Já possui uma conta? <Link to={'/'}>
        Acessar sua conta
      </Link>.
    </p>
  </>)
};

export default CreateFreeAccount;