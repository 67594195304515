import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import './styles.scss';

const Select = ({ variant, size, label, options, ...props }) => {
  const { className, defaultValue, value, children, onChange } = props
  const [filled, setFilled] = useState()
  const prefix = 'form-control';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, size && `${prefix}-${size}`, className);

  const handleChange = (e) => {
    setFilled(!!e.target.value)

    if (onChange) {
      onChange(e)
    }
  }

  useEffect(() => {
    setFilled(!!(defaultValue || value))
  }, [defaultValue, value])

  return (<>
    <select {...props}
      className={`${newClassName}${filled ? ' filled' : ''}`}
      onChange={handleChange}
    >
      {children}
      {!children && <option></option>}
      {options && options.map((option, i) =>
        <option key={i} {...option} label={option.label || option.value} />
      )}
    </select>
    {label && <label className="form-label">
      {label}
    </label>}
  </>);
};

export default Select;
