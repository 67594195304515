import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card/Card';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import styled from 'styled-components';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  Table,
} from '../../../components';
import { Section } from '../../../layout';

const StyledCard = styled(Card)`
  height: 100%;

  .card-body {
    padding: 16px;
  }

  .h5 {
    margin-bottom: 8px;
    color: #142573;
  }

  .progress {
    margin-top: 30px;
  }

  small {
    margin-top: 26px;
    color: #707d88;
  }
`;

const StyledTable = styled(Table)`
  margin-bottom: 0;
`;

const tableInfos = [
  {
    label: 'E-mail Nota Fiscal',
    value: 'guivas@febacapital.com',
    type: 'email'
  },
  {
    label: 'Faturado com',
    value: 'Mastercard ••••4400',
    type: 'credit'
  },
];

const billingRows = [
  {
    col1: '28/09/22 ',
    col2: 'Assinafy Plano Enterprise',
    col3: '500',
    col4: '(1) R$ 0,39',
    col5: 'R$ 199,39',
  },
  {
    col1: '28/09/22 ',
    col2: 'Assinafy Plano Enterprise',
    col3: '500',
    col4: '(2) R$ 0,78',
    col5: 'R$ 199,78',
  },
];

const PlanTab = () => {
  const [showModalNf, setShowModalNf] = useState(false);
  const [showModalCredit, setShowModalCredit] = useState(false);
  const [email, setEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const [creditCardName, setCreditCardName] = useState('');
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [creditCardExpirationMonth, setCreditCardExpirationMonth] =
    useState('');
  const [creditCardExpirationYear, setCreditCardExpirationYear] = useState('');
  const [creditCardCVV, setCreditCardCVV] = useState('');

  const handleOpenModal = (type) => {
    if (type === 'credit') {
      setShowModalCredit(true);
    } else {
      setShowModalNf(true);
    }  
  }

  const handleCloseModalNf = () => setShowModalNf(false);
  const handleCloseModalCredit = () => setShowModalCredit(false);

  const handleSubmitNf = (e) => {
    e.preventDefault();
    setEmail(newEmail);
    handleCloseModalNf();
  };

  const handleSubmitCredit = (e) => {
    e.preventDefault();
    handleCloseModalCredit();
  };

  return (
    <>
      <Section>
        <Row className="g-3">
          <Col xs={12} sm={6} md={3}>
            <StyledCard variant="outline">
              <h2 className={'h5'}>Próximo vencimento</h2>
              <div className={'h2'}>10 de Out, 2022</div>
            </StyledCard>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <StyledCard variant="outline">
              <h2 className={'h5'}>Documentos do plano</h2>
              <div className={'h2'}>500 documentos</div>
              <ProgressBar percent={50} />
            </StyledCard>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <StyledCard variant="outline">
              <h2 className={'h5'}>Documentos extras</h2>
              <div className={'h2'}>1 documento</div>
              <small>R$ 0,39 por documento extra</small>
            </StyledCard>
          </Col>
          <Col>
            <StyledCard variant="outline">
              <h2 className={'h5'}>Valor da cobrança</h2>
              <div className={'h2'}>R$ 199,39</div>
              <small>Plano Enterprise + 1 doc extra</small>
            </StyledCard>
          </Col>
        </Row>
      </Section>

      <Section>
        <Row>
          <Col md={8}>
            <StyledTable
              className="table-bordered table-hover table-nfe"
              rows={tableInfos}
              renderRow={({label, value, type}, index) => {
                return (
                  <tr key={index}>
                    <td width={'25%'}>{label}</td>
                    <td>{value}</td>
                    <td className="col-action">
                      <Button
                        variant="light"
                        className="has-hover"
                        as={Button}
                        onClick={() => handleOpenModal(type)}
                      >
                        <Icon id={'icon-edit'} className="me-1" size="18" />
                        Alterar
                      </Button>
                    </td>
                  </tr>
                );
              }}
            />
          </Col>
        </Row>
      </Section>

      <Section>
        <Section.Header title="Histórico de cobrança" titleAs="h2" />

        <Table
          className="table-bordered table-hover"
          header={[
            { name: 'Data' },
            { name: 'Plano' },
            { name: 'Doc. Plano' },
            { name: 'Doc. Extras' },
            { name: 'Valor Da Nota' },
          ]}
          rows={billingRows}
          renderRow={(row, index) => {
            return (
              <tr key={index}>
                <td>{row.col1}</td>
                <td>{row.col2}</td>
                <td>{row.col3}</td>
                <td>{row.col4}</td>
                <td>{row.col5}</td>
              </tr>
            );
          }}
        />
      </Section>

      <Modal show={showModalNf} onHide={handleCloseModalNf}>
        <Modal.Header closeButton>
          <Button
            variant="icon"
            className="btn-close"
            onClick={handleCloseModalNf}
          >
            <Icon id="icon-close" size="24" />
          </Button>
          <Modal.Title as={'div'} className="h2">
            E-mail Nota Fiscal
          </Modal.Title>
          Informe abaixo o e-mail que vai receber as Notas Fiscais do plano.
        </Modal.Header>
        <Form size="lg" id="form-signer-new">
          <Modal.Body>
            <Form.Group>
              <Input
                type="email"
                size="lg"
                label="E-mail"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={handleCloseModalNf}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSubmitNf}>
              <Icon id="icon-check" className="me-2" size="18" />
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal show={showModalCredit} onHide={handleCloseModalCredit}>
        <Modal.Header closeButton>
          <Button
            variant="icon"
            className="btn-close"
            onClick={handleCloseModalCredit}
          >
            <Icon id="icon-close" size="24" />
          </Button>
          <Modal.Title as={'div'} className="h2">
            Pagamento via cartão de crédito
          </Modal.Title>
          Insira os dados do cartão de crédito.
        </Modal.Header>
        <Form size="lg">
          <Modal.Body>
            <Form.Group>
              <Input
                type="text"
                size="lg"
                label="Nome do titular (igual o escrito no cartão)"
                value={creditCardName}
                onChange={(e) => setCreditCardName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group>
              <Input.CreditCard
                label="Número do cartão"
                size="lg"
                value={creditCardNumber}
                onChange={(e) => setCreditCardNumber(e.target.value)}
              />
            </Form.Group>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Select
                    label="Mês de validade"
                    size="lg"
                    options={[
                      { label: '01 - Janeiro', value: '01' },
                      { label: '02 - Fevereiro', value: '02' },
                      { label: '03 - Março', value: '03' },
                      { label: '04 - Abril', value: '04' },
                      { label: '05 - Maio', value: '05' },
                      { label: '06 - Junho', value: '06' },
                      { label: '07 - Julho', value: '07' },
                      { label: '08 - Agosto', value: '08' },
                      { label: '09 - Setembro', value: '09' },
                      { label: '10 - Outubro', value: '10' },
                      { label: '11 - Novembro', value: '11' },
                      { label: '12 - Dezembro', value: '12' },
                    ]}
                    onChange={(e) =>
                      setCreditCardExpirationMonth(e.target.value)
                    }
                    value={creditCardExpirationMonth}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Select
                    label="Ano de validade"
                    size="lg"
                    options={[
                      { label: '2024', value: '2024' },
                      { label: '2025', value: '2025' },
                      { label: '2026', value: '2026' },
                      { label: '2027', value: '2027' },
                      { label: '2028', value: '2028' },
                      { label: '2029', value: '2029' },
                      { label: '2030', value: '2030' },
                      { label: '2031', value: '2031' },
                      { label: '2032', value: '2032' },
                      { label: '2033', value: '2033' },
                      { label: '2034', value: '2034' },
                      { label: '2035', value: '2035' },
                    ]}
                    onChange={(e) =>
                      setCreditCardExpirationYear(e.target.value)
                    }
                    value={creditCardExpirationYear}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Input.Tel
                    size="lg"
                    label="Código de segurança (CVV)"
                    value={creditCardCVV}
                    onChange={(e) => setCreditCardCVV(e.target.value)}
                    maxLength={3}
                    required
                  />
                  <Icon
                    id="icon-question"
                    size="24"
                    className="text-info ms-2"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={handleCloseModalCredit}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleSubmitCredit}>
              <Icon id="icon-check" className="me-2" size="18" />
              Cadastrar cartão
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default PlanTab;
