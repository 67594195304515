const roles = [
  { label: 'Owner', value: 'owner' },
  { label: 'Organizer', value: 'organizer' }
]

const getRoleName = (role) => {
  if (!role) return ''

  if (typeof role === 'object') {
    return roles.map((r) => r.value == role ? r.label : undefined).filter((r) => r !== undefined).join(', ')
  }

  return roles.find((r) => r.value == role).label
}

export {
  roles,
  getRoleName
}