import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Api, getSession, selectAccount, deleteAccount, cancelRequest } from '../../../api/Assinafy';
import { Section } from '../../../layout';
import { SessionContext } from '../../../routes/DashboardRoute';
import { Modal, Button, Icon, Dropdown, Table, Form, Input, Alert, Toast, Avatar } from '../../../components';
import { getRoleName } from '../../../utils/User';

/**
 * TODO
 * - table column: access type
 * - table column: plan 
 */

const WorkspaceTab = () => {
  const [accounts, setAccounts] = useState([])
  const [errors, setErrors] = useState([])
  const [toast, setToast] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const [modalNew, setModalNew] = useState(false)
  const [session, setSession] = useContext(SessionContext);
  const cancelToken = cancelRequest()
  const navigate = useNavigate()
  const { state } = useLocation()

  const getAccounts = () => {
    Api('accountUser/index')({
      ...cancelToken.config
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setAccounts(data)
        }
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
        }
      })
  }

  const handleRemove = (workspace) => {
    const { is_delete_allowed } = workspace

    setLoading(true)

    deleteAccount(is_delete_allowed)(workspace.id, () => {
      setSession(getSession())
    })
      .then(({ status }) => {
        if (status === 200) {
          getAccounts()
          setModalRemove(false)
          setLoading(false)
          setToast({
            success: true,
            message: 'Workspace removido com sucesso'
          })
        }
      })
      .catch(({ response }) => {
        if (response) {
          setModalRemove(false)
          setLoading(false)
          setToast({
            success: false,
            message: response.data.message
          })
        }
      })
  }

  useEffect(() => {
    if (state?.toast) {
      setToast({
        message: state.toast
      })
      window.history.replaceState({}, document.title)
    }
  }, [state])

  useEffect(() => {
    getAccounts()
  }, [])

  return (<Section>
    <Alert show={errors.length} variant="danger">
      {errors.map((error) => error)}
    </Alert>

    <Section.Header
      title="Workspaces"
      titleAs="h3"
      opening="Temos abaixo a lista com todos os workspaces que você tem acesso."
    >
      {Api('accountUser/addAccount') && <Button variant="primary" onClick={() => setModalNew(true)}>
        <Icon id="icon-add" className="me-1" size="16" />
        NOVO WORKSPACE
      </Button>}
    </Section.Header>

    <Table className="table-bordered table-hover"
      header={[
        { name: 'Nome' },
        { name: 'ID', className: 'd-none d-md-table-cell' },
        { name: 'Tipo de acesso' },
        // { name: 'Plano' },
        { name: '' }
      ]}
      rows={accounts}
      renderRow={(workspace) => {
        return (<tr key={workspace.id}>
          <td>
            <div className="workspace-header"
              onClick={() => selectAccount(workspace.id, () => {
                setSession(getSession())
                navigate('/dashboard')
              })}
            >
              <Avatar placeholder={workspace.name && workspace.name[0]} />
              <div className="workspace-title">{workspace.name}</div>
            </div>
          </td>
          <td className="d-none d-md-table-cell">
            {workspace.id}
          </td>
          <td>
            {getRoleName(workspace?.roles[0])}
          </td>
          {/* <td></td> */}
          <td className="col-action">
            <TableActions
              workspace={workspace}
              setModalRemove={setModalRemove}
            />
          </td>
        </tr>)
      }}
    />

    <ModalRemove
      show={modalRemove}
      modalRemove={modalRemove}
      setModalRemove={setModalRemove}
      handleRemove={() => handleRemove(modalRemove)}
      loading={loading}
    />

    <ModalNew show={modalNew}
      setModalNew={setModalNew}
      getAccounts={getAccounts}
    />

    {toast.success ?
      <Toast show={toast} variant="success" onClose={() => setToast(false)}>
        {toast.message}
      </Toast>
      :
      <Toast show={toast} onClose={() => setToast(false)}>
        {toast.message}
      </Toast>
    }
  </Section>);
}

const TableActions = ({ workspace, setModalRemove }) => {
  const [session, setSession] = useContext(SessionContext);
  const navigate = useNavigate()
  const dropdownRef = useRef()

  const actionsButtons = [
    {
      id: 'view',
      icon: 'icon-external',
      text: 'ACESSAR',
      onClick: () => selectAccount(workspace.id, () => {
        setSession(getSession())
        navigate('/dashboard')
      })
    },
    {
      icon: workspace.is_delete_allowed ? 'icon-trash' : 'icon-exit',
      text: workspace.is_delete_allowed ? 'EXCLUIR' : 'SAIR',
      onClick: () => setModalRemove(workspace)
    }
  ]

  const { id, icon, text, onClick } = actionsButtons[0]

  return (<div className="table-actions">
    <div className="d-none d-xl-table-cell">
      <Button variant="light"
        className="has-hover"
        as={Button}
        onClick={() => onClick(id)}
      >
        <Icon id={icon} size="18" className="me-1" />
        {text}
      </Button>
    </div>
    <Dropdown
      ref={dropdownRef}
      align="right"
      variant="action"
      button={<Icon id="icon-more_vert" />}
    >
      {actionsButtons.map(({ id, icon, text, onClick }, i) => {
        if (!text) {
          return;
        }

        return (<Dropdown.Item key={i}
          as={Button}
          onClick={() => onClick(id)}
        >
          <Icon id={icon} className="me-1" size="20" />
          {text}
        </Dropdown.Item>)
      })}
    </Dropdown>
  </div>)
}

const ModalNew = ({ show, setModalNew, getAccounts }) => {
  const [session, setSession] = useContext(SessionContext);

  const handleSubmit = (formData) => Api('accountUser/addAccount')(formData, () => {
    setSession(getSession())
    getAccounts()
  })
    .then(({ status }) => {
      if (status === 200) {
        setModalNew(false)
      }
    })

  return (
    <Modal.Alert show={show}
      title="Criando workspace"
      description="Informe abaixo o nome do seu workspace."
      onClose={() => setModalNew(false)}
    >
      <Form
        id="form-workspace-create"
        onSubmit={handleSubmit}
        size="lg"
        ComponentBody={Modal.Body}
        ComponentFooter={Modal.Footer}
        controls={[
          {
            Component: Input,
            name: 'name',
            placeholder: 'Nome do novo Workspace',
            required: true,
            autoFocus: true
          }
        ]}
        buttons={[
          {
            variant: 'primary', submit: true, children: <>
              <Icon id="icon-check" className="me-1" size="16" />
              SALVAR WORKSPACE
            </>
          }
        ]}
      />
    </Modal.Alert>
  )
}

const ModalRemove = ({ show, modalRemove, setModalRemove, handleRemove, loading }) => {
  const { name, is_delete_allowed } = modalRemove

  let modalDetails = {
    title: `Deseja realmente excluir o workspace ${name}?`,
    description: `Ao remover o workspace ${name}, você perderá todos os documentos dele. A exclusão do workspace não apagará dívidas pendentes.`
  }

  if (!is_delete_allowed) {
    modalDetails = {
      title: `Deseja realmente sair do workspace ${name}?`,
      description: `Você está saindo do workspace ${name}, você irá perder acesso a todos os documentos. Tem certeza que deseja continuar?`
    }
  }

  return (
    <Modal.Alert show={show}
      variant="danger"
      icon="icon-trash"
      title={modalDetails.title}
      description={modalDetails.description}
      onClose={() => setModalRemove(false)}
    >
      <Modal.Footer>
        <Button variant="light"
          onClick={() => setModalRemove(false)}
        >
          CANCELAR
        </Button>
        <Button variant="danger" submit
          onClick={handleRemove}
          loading={loading}
        >
          <Icon id={is_delete_allowed ? 'icon-trash' : 'icon-exit'} className="me-1" size="16" />
          {is_delete_allowed ? 'REMOVER' : 'SAIR'}
        </Button>
      </Modal.Footer>
    </Modal.Alert>
  )
}

export default WorkspaceTab;