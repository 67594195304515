import React, { useState, useEffect, useRef } from 'react';
import { Api, clearSession } from '../../api/Assinafy'
import { useNavigate, useLocation } from 'react-router-dom';
import { Page } from '../../layout';
import { Button, Input, Alert } from '../../components';
import Form, { createInputChange, createInputBlur, createInputError } from '../../components/Form';

import './styles.scss';

const PasswordReset = ({ isNew }) => {
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formValidation, setFormValidation] = useState([])
  const location = useLocation()
  const { search } = location
  const query = new URLSearchParams(search)
  const [formData, setFormData] = useState({
    'email': query.get('email'),
    'token': query.get('token')
  })
  const navigate = useNavigate()
  const confirmPasswordRef = useRef()

  const handleSubmit = (event) => {
    event.preventDefault()

    if (event.target.checkValidity()) {
      setLoading(true)

      Api('authentication/resetPassword')(formData)
        .then(resp => {
          if (resp.status === 200) {
            navigate('/password-reset/success')
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    } else {
      Array.from(event.target.elements).map((element) => {
        if (element.name) {
          setFormValidation((prevState) => [
            ...prevState,
            { name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const handleChange = createInputChange(formData, setFormData)

  const handleBlur = (event) => {
    if (formData.new_password !== formData.confirm_password) {
      confirmPasswordRef.current.setCustomValidity('Senha e confirmação de senha são diferentes')
    } else {
      confirmPasswordRef.current.setCustomValidity('')
    }

    createInputBlur(formValidation, setFormValidation)(event)
  }

  const showInputError = createInputError(formValidation)

  useEffect(() => {
    clearSession()
  }, [])

  return (
    <Page className="page-sign"
      title={`Defina sua ${!isNew ? 'nova ' : ''}senha`}
      opening={`Escolha uma ${!isNew ? 'nova ' : ''}senha de acesso.`}
      btnAccount={{
        title: 'Não possui conta?',
        btn: { url: '/signup', text: 'Criar conta' }
      }}
    >
      <Form id="form-password-reset" className="form-sign"
        onSubmit={handleSubmit}
      >
        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>

        <Form.Group>
          <Input.Password size="lg" name="new_password" label={`${!isNew ? 'Nova s' : 'S'}enha`} minLength="8" required
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="new-password"
          />
          <Form.Text>
            A senha deve conter pelo menos 8 caracteres.
          </Form.Text>
          {showInputError('new_password')}
        </Form.Group>

        <Form.Group>
          <Input.Password ref={confirmPasswordRef}
            size="lg" name="confirm_password" label="Confirmar Senha" minLength="8" required
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="new-password"
          />
          <Form.Text>
            A senha deve ser igual a de cima.
          </Form.Text>
          {showInputError('confirm_password')}
        </Form.Group>

        <Form.Footer>
          <Button variant="primary" size="lg" submit loading={loading}>
            Salvar {!isNew ? 'nova ' : ''}senha
          </Button>
        </Form.Footer>
      </Form>
    </Page>
  );
};

export default PasswordReset;
