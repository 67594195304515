import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Icon from '../Icon';

import './styles.scss';

const Pagination = (props) => {
  const location = useLocation()
  const { pathname, search } = location
  const searchParams = new URLSearchParams(search)
  const { pageCount } = props
  const currentPage = searchParams.get('page') || 1
  const pages = []

  const createUrl = (page, rule = true) => {
    if (rule === true) searchParams.set('page', page)
    else searchParams.delete('page')

    return `${pathname}?${searchParams.toString()}`
  }

  if (pageCount < 2) {
    return <></>
  }

  for (let i = 0; i < pageCount; i++) {
    const isCurrentPage = i === (currentPage - 1)

    pages.push(
      <Link key={i} to={createUrl(i + 1, i > 0)}
        className={`page-item${isCurrentPage ? ' active' : ''}`}
      >
        {i + 1}
      </Link>
    )
  }

  return (
    <div className="pagination">
      {!!pages.length && currentPage > 1 &&
        <Link className="page-item"
          to={createUrl(Number(currentPage) - 1, currentPage > 2)}
        >
          <Icon id="icon-arrow_left" size="20" />
        </Link>
      }
      {pages}
      {!!pages.length && currentPage < pageCount &&
        <Link className="page-item"
          to={createUrl(Number(currentPage) + 1)}
        >
          <Icon id="icon-arrow_right" size="20" />
        </Link>
      }
    </div>
  )
}

export default Pagination;