import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Sign from '../layout/Sign'

const SignRoute = () => {
  const [layoutClassName, setLayoutClassName] = useState()

  return (
    <Sign className={layoutClassName}>
      <Outlet context={[layoutClassName, setLayoutClassName]} />
    </Sign>
  )
};

export default SignRoute;
