import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../api/Assinafy'
import { Toast, Form, Input, Button, Icon, Modal } from '../../../components';

const ModalExpiration = ({
  onClose,
  documentId,
  assignmentId,
  expiration,
  ...props
}) => {
  const navigate = useNavigate()
  const [newExpiration, setNewExpiration] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState('')
  const datePickerRef = useRef()

  const handleSubmit = (event) => {
    event.preventDefault()

    if (event.target.checkValidity()) {
      const expires_at = newExpiration ? newExpiration : ''

      setLoading(true)

      Api('assignment/resetExpiration')(documentId, assignmentId, {
        expires_at
      })
        .then(({ status }) => {
          if (status === 200) {
            if (newExpiration) {
              if (expiration) {
                setToast('Data de validade alterada com sucesso, todos os signatários vão receber uma notifação com a alteração.');
              } else {
                setToast('Data de validade adicionada com sucesso, todos os signatários vão receber uma notificação com a alteração.');
              }
            } else {
              setToast('Data de validade removida com sucesso, todos os signatários vão receber uma notificação com a alteração.');
            }
          }

          onClose(false)
          setLoading(false)
          navigate(0)
        })
        .catch(({ response }) => {
          setToast(response.data.message)
          setLoading(false)
        })
    }
  }

  const handleCloseClick = () => {
    setLoading(false)
    onClose(false)
  }

  useEffect(() => {
    setNewExpiration(datePickerRef.current?.props.selected)
  }, [props.show])

  return (<>
    <Modal {...props} onHide={handleCloseClick} centered>
      <Modal.Header>
        <Button variant="icon" className="btn-close"
          onClick={handleCloseClick}
        >
          <Icon id="icon-close" size="24" />
        </Button>
        <Modal.Title as={'div'} className="h2">
          Validade do documento
        </Modal.Title>
        Informe até quando os signatários poderão assinar o documento.
      </Modal.Header>
      <Form id="form-document-expiration"
        onSubmit={handleSubmit}
      >
        <Modal.Body>
          <Form.Group className="group-calendar">
            <Input.Date ref={datePickerRef}
              size="lg"
              name="expiration"
              className="control-datepicker"
              onChange={(value) => setNewExpiration(value)}
              label="Data"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="lg" submit
            loading={loading}
          >
            SALVAR ALTERAÇÕES
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
    <Toast show={toast} onClose={() => setToast('')}>
      {toast}
    </Toast>
  </>)
};

export default ModalExpiration;