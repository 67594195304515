import React from 'react';
import { Link } from 'react-router-dom';
import { Page } from '../../layout';
import { Icon } from '../../components';

import './styles.scss';

const PasswordResetSuccess = ({ isNew }) => {
  return (
    <Page className="page-sign"
      title="Pronto!"
      opening={`A sua senha foi ${isNew ? 'criada' : 'resetada'} com sucesso.`}
      btnAccount={{
        title: 'Não possui conta?',
        btn: { url: '/signup', text: 'Criar conta' }
      }}
    >
      <Link to={'/'} className="btn btn-lg btn-block btn-primary">
        Voltar para o Login
        <Icon id="icon-arrow_forward" className="ms-2" size="24" />
      </Link>
    </Page>
  );
};

export default PasswordResetSuccess;
