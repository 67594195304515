import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

import './styles.scss';

const Toast = ({ show = false, variant, ...props }) => {
  const { className, children, onClose } = props
  const prefix = 'toast';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, className);
  const [shown, setShown] = useState(show)

  useEffect(() => {
    if (! children) return;

    window.setTimeout(() => {
      setShown(false)
    }, 2000)
  }, [children])

  useEffect(() => {
    setShown(show)
  }, [show])

  if (!shown) {
    return (<></>)
  }

  return (
    <div {...props} className={newClassName}>
      <div className={`${prefix}-body`}>
        {variant &&
          <Icon id={`icon-${variant}`} className="me-2" size="24" />
        }
        {children}
      </div>
      <button type="button" className="btn" onClick={(e) => onClose(false, e)}>
        Fechar
      </button>
    </div>
  )
}

export default Toast