import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Api, cancelRequest } from '../../api/Assinafy';
import { Dashboard, Section } from '../../layout';
import { Alert, Table, Button, Icon, Modal, Input, Pagination } from '../../components';
import Form, { createInputChange, createInputBlur, createInputError } from '../../components/Form';
import DocumentsEmpty from '../../modules/Documents/DocumentsEmpty';
import DocumentsList from '../../modules/Documents/DocumentsList';

const SignerView = (props) => {
  const fetchSignerDocuments = Api('signer/documents')
  const { isRead } = props
  const [readOnly, setReadOnly] = useState(false)
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formValidation, setFormValidation] = useState([])
  const [formData, setFormData] = useState({})
  const [documents, setDocuments] = useState()
  const [pagination, setPagination] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const [modalError, setModalError] = useState(false)
  const navigate = useNavigate()
  const { id: signerId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const cancelToken = cancelRequest()

  const handleSubmit = (event) => {
    event.preventDefault()

    setValidated(true)

    if (event.target.checkValidity()) {
      setLoading(true)

      Api('signer/update')(signerId, formData)
        .then(({ status }) => {
          if (status === 200) {
            navigate('/dashboard/signers', {
              state: {
                toast: 'Contato atualizado com sucesso'
              }
            })
          }
        })
        .catch(({ response }) => {
          if (response) {
            setFormError([response.data.message])
          }

          setLoading(false)
        })
    } else {
      Array.from(event.target.elements).map((element) => {
        if (element.name) {
          setFormValidation((prevState) => [
            ...prevState,
            { name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const handleChange = createInputChange(formData, setFormData)

  const handleBlur = createInputBlur(formValidation, setFormValidation)

  const showInputError = createInputError(formValidation)

  const handleRemove = () => {
    setModalRemove(false)
    setModalError(true)
  }

  const getDocuments = () => {
    fetchSignerDocuments(signerId, {
      params: {
        'page': page || 1
      }
    })
      .then(({ status, headers, data: { data } }) => {
        if (status === 200) {
          setDocuments(data)
        }

        setPagination({
          pageCount: headers['x-pagination-page-count'],
          perPage: headers['x-pagination-per-page'],
          totalCount: headers['x-pagination-total-count']
        })
      })
  }

  useEffect(() => {
    Api('signer/view')(signerId)
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setFormData(data)

          if (fetchSignerDocuments) {
            getDocuments()
          }
        }
      })
  }, [signerId])

  useEffect(() => {
    getDocuments()

    return () => {
      cancelToken.cancel()
    }
  }, [searchParams])

  useEffect(() => {
    setReadOnly(isRead)
  }, [isRead])

  return (<Dashboard.Content>
    <Dashboard.Header
      title={readOnly ? `${formData.full_name}` : `Editando ${formData.full_name}`}
    />
    <Section>
      <Form id="form-signer"
        onSubmit={handleSubmit}
      >
        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>

        <Table
          className="table-bordered table-form"
          rows={[
            ['Nome', isRead
              ? formData.full_name
              : <Form.Group>
                <Input name="full_name" size="lg"
                  defaultValue={formData.full_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {showInputError('full_name')}
              </Form.Group>],
            ['E-mail', formData.email],
            /*['Telefone', isRead
              ? formData.phone
              : <Form.Group>
                <Input.Phone name="phone" size="lg"
                  defaultValue={formData.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {showInputError('phone')}
              </Form.Group>]*/
          ]}
        />
        <Form.Footer horizontal>
          {readOnly
            ? <Button variant="primary" as={Link}
              to={`/dashboard/signers/${signerId}/edit`}
            >
              <Icon id="icon-edit" className="me-1" size="16" />
              EDITAR
            </Button>
            : <Button variant="primary" submit loading={loading}>
              <Icon id="icon-check" className="me-1" size="16" />
              {signerId ? 'SALVAR ALTERAÇÕES' : 'SALVAR'}
            </Button>
          }
          <Button variant="info" onClick={() => navigate(-1)}>
            {readOnly ? 'VOLTAR' : 'CANCELAR'}
          </Button>
        </Form.Footer>
      </Form>
    </Section>
    {fetchSignerDocuments && <Section>
      <Section.Header
        title="Documentos associados"
      />

      {documents && !documents.length ?
        <DocumentsEmpty />
        :
        <>
          <DocumentsList rows={documents} />
          {pagination &&
            <Pagination {...pagination} />
          }
        </>
      }
    </Section>}
    <Modal.Alert show={modalRemove}
      variant="danger"
      icon="icon-trash"
      title="Deseja realmente excluir esse contato?"
      description="Você pode editar ele se preferir."
      onClose={() => setModalRemove(false)}
    >
      <Modal.Footer>
        <Button variant="info"
          onClick={() => setModalRemove(false)}
        >
          CANCELAR
        </Button>
        <Button variant="danger" submit
          onClick={handleRemove}
        >
          <Icon id="icon-trash" className="me-1" size="16" />
          REMOVER
        </Button>
      </Modal.Footer>
    </Modal.Alert>
    <Modal.Alert show={modalError}
      variant="warning"
      icon="icon-warning"
      title="Não foi possível remover o campo"
      description="O campo checkbox está sendo utilizado em documentos aguardando assinatura."
      onClose={() => setModalError(false)}
    >
      <Modal.Footer>
        <Button variant="info" submit
          onClick={() => setModalError(false)}
        >
          OK, FECHAR
        </Button>
      </Modal.Footer>
    </Modal.Alert>
  </Dashboard.Content>)
};

export default SignerView;