import React, { useState } from 'react';
import { Api, getSession, clearSession } from '../../api/Assinafy';
import { useNavigate, useParams } from 'react-router-dom';
import { Dashboard } from '../../layout'
import { Tabs, Alert, Button } from '../../components';
import Profile from '../Profile/Profile';
import WorkspacesTab from './Tabs/WorkspacesTab';
import ApiTab from './Tabs/ApiTab';

import './styles.scss';

const Account = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [user, setUser] = useState(getSession().user)
  const [linkLoading, setLinkLoading] = useState(false)
  const [requestEmailSent, setRequestEmailSent] = useState(false)

  const handleRequestVerify = (event) => {
    event.preventDefault()

    setLinkLoading(true)

    Api('user/requestEmailVerification')({ email: user.email })
      .then(resp => {
        setRequestEmailSent(true)
        setLinkLoading(false)
        clearSession()
      })
      .catch(({ response }) => {
        setLinkLoading(false)
      })
  }

  return (<Dashboard.Content>
    <Dashboard.Header
      title="Minha conta"
    />

    <Alert show={!user.is_email_verified} variant="warning">
      <div className="d-flex justify-content-between">
        <div>
          <b>Sua conta ainda não foi ativada.</b><br />
          Por favor, conclua o processo de ativação para aproveitar todos os recursos disponíveis.
        </div>
        <Button
          variant="link"
          className="btn-request"
          onClick={handleRequestVerify}
          loading={linkLoading}
          loadingText="Reenviando e-mail de ativação..."
          disabled={requestEmailSent}
        >
          {requestEmailSent ? 'E-mail de ativação reenviado' : 'Reenviar e-mail de ativação'}
        </Button>
      </div>
    </Alert>

    <Tabs
      activeKey={params.tab}
      onSelect={(k) => navigate(`/dashboard/account/${k}`)}
    >
      <Tabs.Tab eventKey="profile" title="Meu perfil">
        {(!params.tab || params.tab === 'profile') && <Profile.Section isRead />}
      </Tabs.Tab>
      <Tabs.Tab eventKey="workspaces" title="Workspaces">
        {params.tab === 'workspaces' && <WorkspacesTab />}
      </Tabs.Tab>
      <Tabs.Tab eventKey="api" title="API">
        {params.tab === 'api' && <ApiTab />}
      </Tabs.Tab>
      {/* <Tabs.Tab eventKey="plan" title="Plano">
      </Tabs.Tab> */}
      {/* <Tabs.Tab eventKey="webhooks" title="Webhooks">
      </Tabs.Tab> */}
    </Tabs>
  </Dashboard.Content>)
}

export default Account;