import React from 'react';

import './styles.scss';

const Avatar = ({ placeholder, bgColor, ...props }) => {
  return (
    <div {...props} className="avatar" style={{ backgroundColor: bgColor }}>
      {placeholder}
    </div>
  )
}

export default Avatar;