import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Card = ({ className, variant, children }) => {
    const prefix = 'card';
    const newClassName = classNames(prefix, className, variant && `${prefix}-${variant}`);

    return (
        <div className={newClassName}>
            <div className="card-body">
                {children}
            </div>
        </div>
    );
};

const CardText = (props) => <div className="card-text" {...props} />;

Card.Text = CardText;

export default Card;
