import axios from 'axios'

/* TODO:
  resync caso erro 403 no workspace
*/

const cancelRequest = () => {
  // const controller = new AbortController();
  const source = axios.CancelToken.source();

  return {
    cancel: () => {
      // controller.abort();
      source.cancel();
    },
    config: {
      // signal: controller.signal,
      cancelToken: source.token
    }
  }
}

const apiBaseEndpoint = () => {
  const env = process.env.REACT_APP_ENVIRONMENT
  const version = 'v1'

  if (env === 'production') {
    return `https://api.assinafy.com.br/${version}`
  }

  // return `https://api.funny-chatterjee.stage.assinafy.com.br/${version}`
  return `https://api.main.stage.assinafy.com.br/${version}`
}

const getSession = () => {
  const itemSession = localStorage.getItem('session')
  if (!itemSession) {
    clearSession()
    return {}
  }

  return JSON.parse(itemSession + '')
}

const updateSession = (data = {}) => {
  const itemSession = JSON.parse(localStorage.getItem('session'))

  localStorage.setItem('session', JSON.stringify(Object.assign(itemSession, data)))
}

const clearSession = () => {
  localStorage.clear()
}

const getPermissions = (name) => {
  const itemPermissions = localStorage.getItem('permissions')
  if (!itemPermissions) {
    return false
  }

  const permissions = JSON.parse(itemPermissions + '')

  if (name) {
    return permissions.find((route) => route.name === name)?.is_allowed
  }

  return permissions
}

const accessToken = () => {
  const currentSession = getSession()
  if (currentSession?.access_token) {
    return currentSession.access_token
  }
}

const authorizationHeader = () => {
  const token = accessToken()
  if (token) {
    return { Authorization: `Bearer ${token}` }
  }
}

const defaultHeaders = () => {
  return {
    ...authorizationHeader(),
    'Content-Type': 'application/json'
  }
}

const urlWithAccessToken = (url) => url + `?access-token=${accessToken()}`

const urlWithSignerAccessCode = (url, signerAccessCode) => url + `?signer-access-code=${signerAccessCode}`

const setSession = (response, resolve) => {
  const { data: { data, data: { accounts } } } = response

  localStorage.setItem('session', JSON.stringify(data))

  if (accounts && accounts.length) {
    Api('authorization/permissionIndex')(accounts[0].id)
      .then(({ data: { data } }) => {
        localStorage.setItem('permissions', JSON.stringify(data))

        resolve(response.data)
      })
      .catch(() => {
        localStorage.setItem('permissions', JSON.stringify([]))

        resolve(response.data)
      })
  } else {
    resolve(response.data)
  }
}

const login = async (credentials) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      baseURL: apiBaseEndpoint(),
      url: '/login',
      data: credentials
    })
      .then(resp => {
        setSession(resp, resolve)
      }).catch(error => {
        clearSession()
        reject(error)
      })
  })
}

const downloadFileBlob = async (url) => axios({
  type: 'GET',
  url: url,
  headers: defaultHeaders(),
  responseType: 'blob'
})

const downloadFile = async (url) => {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', '')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const updateAccounts = async (resp, resolve, callback) => {
  console.log('updateAccounts: init')
  Api('accountUser/index')()
    .then(({ status, data: { data } }) => {
      console.log('updateAccounts: return accounts')
      if (status === 200) {
        console.log('updateAccounts: updating session')
        console.log('updateAccounts:', data)

        updateSession({
          accounts: data
        })

        if (callback) {
          console.log('updateAccounts: callback')
          callback()
        }

        if (resolve) {
          console.log('updateAccounts: resolve')
          resolve(resp)
          console.log('updateAccounts: end')
        }
      }
    })
}

const selectAccount = (id, callbackThen, callbackCatch) => {
  console.log('selectAccount: init')

  return new Promise((resolve, reject) => {
    Api('account/view')(id)
      .then(resp => {
        if (resp.status === 200) {
          console.log('selectAccount: permissions init', id)

          Api('authorization/permissionIndex')(id)
            .then(({ data: { data } }) => {
              localStorage.setItem('permissions', JSON.stringify(data))

              updateAccounts(resp.data, resolve, callbackThen)

              console.log('selectAccount: permissions end')
            })
        }
      }).catch((error) => {
        callbackCatch('Não foi possível acessar o workspace selecionado.')
        reject(error)
      })
  })
}

const selfUpdates = async (callback) => {
  Api('user/getSelf')()
    .then(({ status, data: { data: { user } } }) => {
      console.log('selfFetch: return accounts')
      if (status === 200) {
        console.log('selfFetch: updating session')
        console.log('selfFetch:', user)

        updateSession({
          user
        })

        if (callback) {
          console.log('selfFetch: callback')
          callback()
        }

        console.log('selfFetch: end')
      }
    })
}

const Endpoints = {
  'document/index': {
    required_login: true,
    required_workspace: true,
    fn: async (config = {}) => {
      const currentAccount = getSession().accounts[0]
      if (!currentAccount) {
        return
      }

      return axios(Object.assign({
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        url: `/accounts/${currentAccount.id}/documents`
      }, config))
    }
  },
  'document/view': {
    required_login: true,
    required_workspace: false,
    fn: async (documentId, config = {}) => {
      return axios(Object.assign({
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        url: `/documents/${documentId}`
      }, config))
    }
  },
  'document/delete': {
    required_login: true,
    required_workspace: false,
    fn: async (documentId, config = {}) => {
      return axios(Object.assign({
        method: 'DELETE',
        url: `/documents/${documentId}`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'document/upload': {
    required_login: true,
    required_workspace: true,
    fn: async (formData, config = {}) => {
      const currentAccount = getSession().accounts[0]
      if (!currentAccount) {
        return
      }

      return axios(Object.assign({
        method: 'POST',
        headers: {
          ...defaultHeaders(),
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
        baseURL: apiBaseEndpoint(),
        url: `/accounts/${currentAccount.id}/documents`,
      }, config))
    }
  },
  'signer/viewDocument': {
    required_login: false,
    required_workspace: false,
    fn: async (signerAccessCode, config = {}) => {
      return axios(Object.assign({
        baseURL: apiBaseEndpoint(),
        params: {
          'signer-access-code': signerAccessCode
        },
        url: '/sign'
      }, config))
    }
  },
  'signer/verifyCode': {
    required_login: false,
    required_workspace: false,
    fn: async (signerAccessCode, verificationCode) => {
      return axios({
        method: 'POST',
        baseURL: apiBaseEndpoint(),
        headers: {
          'Content-Type': 'application/json'
        },
        url: '/verify',
        data: {
          'verification-code': verificationCode,
          'signer-access-code': signerAccessCode
        }
      })
    }
  },
  'signer/index': {
    required_login: true,
    required_workspace: false,
    fn: async (config = {}) => {
      const currentAccount = getSession().accounts[0]
      if (!currentAccount) {
        return
      }

      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${currentAccount.id}/signers`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'signer/view': {
    required_login: true,
    required_workspace: false,
    fn: async (signerId, config = {}) => {
      const currentAccount = getSession().accounts[0]
      if (!currentAccount) {
        return
      }

      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${currentAccount.id}/signers/${signerId}`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'signer/documents': {
    required_login: true,
    required_workspace: false,
    fn: async (signerId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/signers/${signerId}/documents`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'signer/create': {
    required_login: true,
    required_workspace: true,
    fn: async (formData) => {
      const currentAccount = getSession().accounts[0]
      if (!currentAccount) {
        return
      }

      return axios({
        method: 'POST',
        url: `/accounts/${currentAccount.id}/signers`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        data: JSON.stringify(formData)
      })
    }
  },
  'signer/update': {
    required_login: true,
    required_workspace: true,
    fn: async (signerId, formData) => {
      const currentAccount = getSession().accounts[0]
      if (!currentAccount) {
        return
      }

      return axios({
        method: 'PUT',
        url: `/accounts/${currentAccount.id}/signers/${signerId}`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        data: JSON.stringify(formData)
      })
    }
  },
  'signer/delete': {
    required_login: true,
    required_workspace: true,
    fn: async (signerId) => {
      const currentAccount = getSession().accounts[0]
      if (!currentAccount) {
        return
      }

      return axios({
        method: 'DELETE',
        url: `/accounts/${currentAccount.id}/signers/${signerId}`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      })
    }
  },
  'field/fieldTypesIndex': {
    required_login: true,
    required_workspace: false,
    fn: async (config = {}) => {
      return axios(Object.assign({
        type: 'GET',
        url: '/field-types',
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'field/index': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}/fields`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'field/view': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, fieldId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}/fields/${fieldId}`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'field/update': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, fieldId, formData) => axios({
      method: 'PUT',
      url: `/accounts/${accountId}/fields/${fieldId}`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(formData)
    })
  },
  'field/delete': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, fieldId) => axios({
      method: 'DELETE',
      url: `/accounts/${accountId}/fields/${fieldId}`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint()
    })
  },
  'field/create': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, formData) => axios({
      method: 'POST',
      url: `/accounts/${accountId}/fields`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(formData)
    })
  },
  'field/validate': {
    required_login: false,
    required_workspace: true,
    fn: async (accountId, fieldId, value, signerAccessCode) => axios({
      method: 'POST',
      url: `/accounts/${accountId}/fields/${fieldId}/validate`,
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      },
      data: JSON.stringify({ value })
    })
  },
  'field/validateMultiple': {
    required_login: false,
    required_workspace: true,
    fn: async (accountId, values, signerAccessCode) => axios({
      method: 'POST',
      url: `/accounts/${accountId}/fields/validate-multiple`,
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      },
      data: JSON.stringify(values)
    })
  },
  'assignment/create': {
    required_login: true,
    required_workspace: false,
    fn: async (documentId, entries) => axios({
      method: 'POST',
      url: `/documents/${documentId}/assignments`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(entries)
    })
  },
  'assignment/resend': {
    required_login: true,
    required_workspace: false,
    fn: async (documentId, assignmentId, signerId) => axios({
      method: 'PUT',
      url: `/documents/${documentId}/assignments/${assignmentId}/signers/${signerId}/resend`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint()
    })
  },
  'activity/index': {
    required_login: true,
    required_workspace: false,
    fn: async (documentId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/documents/${documentId}/activities`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        params: {
          'page': 1
        }
      }, config))
    }
  },
  'accountUser/addAccount': {
    required_login: true,
    required_workspace: false,
    fn: async (data, callback) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          url: '/accounts',
          headers: defaultHeaders(),
          baseURL: apiBaseEndpoint(),
          data: JSON.stringify(data)
        })
          .then((resp) => {
            if (resp.status === 200) {
              updateAccounts(resp, resolve, callback)
            }
          }).catch(error => {
            reject(error)
          })
      })
    }
  },
  'accountUser/index': {
    required_login: true,
    required_workspace: false,
    fn: async (config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: '/accounts',
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'account/view': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'account/theme': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}/theme`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'account/update': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, data, callback) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'PUT',
          url: `/accounts/${accountId}`,
          headers: defaultHeaders(),
          baseURL: apiBaseEndpoint(),
          data: JSON.stringify(data)
        })
          .then((resp) => {
            if (resp.status === 200) {
              updateAccounts(resp, resolve, callback)
            }
          }).catch(error => {
            reject(error)
          })
      })
    }
  },
  'account/delete': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, callback) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'DELETE',
          url: `/accounts/${accountId}`,
          headers: defaultHeaders(),
          baseURL: apiBaseEndpoint()
        })
          .then((resp) => {
            if (resp.status === 200) {
              updateAccounts(resp, resolve, callback)
            }
          }).catch(error => {
            reject(error)
          })
      })
    }
  },
  'accountUser/leave': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, callback) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'PUT',
          url: `/accounts/${accountId}/leave`,
          headers: defaultHeaders(),
          baseURL: apiBaseEndpoint()
        })
          .then((resp) => {
            if (resp.status === 200) {
              updateAccounts(resp, resolve, callback)
            }
          }).catch(error => {
            reject(error)
          })
      })
    }
  },
  'account/uploadLogo': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, formData) => axios({
      method: 'POST',
      url: `/accounts/${accountId}/logo`,
      headers: {
        ...defaultHeaders(),
        'Content-Type': 'multipart/form-data'
      },
      baseURL: apiBaseEndpoint(),
      data: formData
    })
  },
  'account/deleteLogo': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId) => axios({
      method: 'DELETE',
      url: `/accounts/${accountId}/logo`,
      headers: {
        ...defaultHeaders()
      },
      baseURL: apiBaseEndpoint()
    })
  },
  'accountUser/memberIndex': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}/members`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'authorization/assignRole': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, formData) => axios({
      method: 'POST',
      url: `/accounts/${accountId}/assign-role`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(formData)
    })
  },
  'authorization/revokeRole': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, formData) => axios({
      method: 'POST',
      url: `/accounts/${accountId}/revoke-role`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(formData)
    })
  },
  'signer/uploadSignature': {
    required_login: false,
    required_workspace: false,
    fn: async (blob, signerAccessCode, signatureType = 'signature') => {
      return axios({
        method: 'POST',
        baseURL: apiBaseEndpoint(),
        url: '/signature',
        headers: {
          'Content-Type': 'image/png'
        },
        params: {
          'signer-access-code': signerAccessCode,
          'type': signatureType
        },
        data: blob
      })
    }
  },
  'signer/signature': {
    required_login: false,
    required_workspace: false,
    fn: async (signerAccessCode, signatureType = 'signature') => {
      return axios({
        method: 'GET',
        url: `/signature/${signatureType}`,
        baseURL: apiBaseEndpoint(),
        responseType: 'blob',
        params: {
          'signer-access-code': signerAccessCode
        }
      })
    }
  },
  'signer/signItems': {
    required_login: false,
    required_workspace: false,
    fn: async (documentId, assignmentId, signedData, signerAccessCode) => axios({
      method: 'POST',
      url: `/documents/${documentId}/assignments/${assignmentId}`,
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      },
      data: JSON.stringify(signedData)
    })
  },
  'authentication/requestPasswordReset': {
    required_login: false,
    required_workspace: false,
    fn: async (data) => axios({
      method: 'PUT',
      url: '/authentication/request-password-reset',
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    })
  },
  'authentication/changePassword': {
    required_login: true,
    required_workspace: false,
    fn: async (data) => axios({
      method: 'PUT',
      url: '/authentication/change-password',
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(data)
    })
  },
  'authentication/resetPassword': {
    required_login: false,
    required_workspace: false,
    fn: async (data) => axios({
      method: 'PUT',
      url: '/authentication/reset-password',
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    })
  },
  'accountUser/userIndex': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}/users`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'user/getSelf': {
    required_login: true,
    required_workspace: false,
    fn: async (config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: '/users/self',
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'user/updateSelf': {
    required_login: true,
    required_workspace: false,
    fn: async (formData, config = {}, callback) => {
      return new Promise((resolve, reject) => {
        return axios(Object.assign({
          method: 'PUT',
          url: '/users/self',
          headers: defaultHeaders(),
          baseURL: apiBaseEndpoint(),
          data: JSON.stringify(formData)
        }, config))
          .then((resp) => {
            selfUpdates(callback)

            resolve(resp)
          }).catch(error => {
            reject(error)
          })
      })
    }
  },
  'user/deleteSelf': {
    required_login: true,
    required_workspace: false,
    fn: async (formData, config = {}) => {
      return axios(Object.assign({
        method: 'DELETE',
        url: '/users/self',
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        data: JSON.stringify(formData)
      }, config))
    }
  },
  'user/cancelDeleteSelf': {
    required_login: true,
    required_workspace: false,
    fn: async (formData, config = {}) => {
      return axios(Object.assign({
        method: 'PUT',
        url: '/users/self/cancel-delete',
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        data: JSON.stringify(formData)
      }, config))
    }
  },
  'accountUser/userView': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, userId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}/users/${userId}`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'user/create': {
    required_login: false,
    required_workspace: false,
    fn: async (formData) => {
      return new Promise((resolve, reject) => {
        return axios({
          method: 'POST',
          baseURL: apiBaseEndpoint(),
          url: '/users',
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(formData)
        })
          .then(resp => {
            setSession(resp, resolve)
          }).catch(error => {
            clearSession()
            reject(error)
          })
      })
    }
  },
  'accountUser/delete': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, userId) => axios({
      method: 'DELETE',
      url: `/accounts/${accountId}/users/${userId}`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint()
    })
  },
  'user/deleteReasons': {
    required_login: true,
    required_workspace: false,
    fn: async () => axios({
      method: 'GET',
      url: '/users/delete-reasons',
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint()
    })
  },
  'userInvitation/fulfill': {
    required_login: false,
    required_workspace: false,
    fn: async (invitationId, formData) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          baseURL: apiBaseEndpoint(),
          url: `/user-invitations/${invitationId}/fulfill`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: JSON.stringify(formData)
        })
          .then(resp => {
            setSession(resp, resolve)
          }).catch(error => {
            clearSession()
            reject(error)
          })
      })
    }
  },
  'user/verifyEmail': {
    required_login: false,
    required_workspace: false,
    fn: async (data) => axios({
      method: 'PUT',
      url: '/users/verify-email',
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    })
  },
  'user/requestEmailVerification': {
    required_login: true,
    required_workspace: false,
    fn: async (data) => axios({
      method: 'PUT',
      url: '/users/request-email-verification',
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(data)
    })
  },
  'userInvitation/create': {
    required_login: true,
    required_workspace: false,
    fn: async (formData) => axios({
      method: 'POST',
      url: '/user-invitations',
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(formData)
    })
  },
  'userInvitation/view': {
    required_login: false,
    required_workspace: false,
    fn: async (invitationId) => axios({
      method: 'GET',
      url: `/user-invitations/${invitationId}`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint()
    })
  },
  'userInvitation/delete': {
    required_login: true,
    required_workspace: false,
    fn: async (invitationId) => axios({
      method: 'DELETE',
      url: `/user-invitations/${invitationId}`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint()
    })
  },
  'signer/self': {
    required_login: false,
    required_workspace: false,
    fn: async (signerAccessCode) => axios({
      method: 'GET',
      url: '/signers/self',
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      }
    })
  },
  'signer/acceptTerms': {
    required_login: false,
    required_workspace: false,
    fn: async (signerAccessCode) => axios({
      method: 'PUT',
      url: '/signers/accept-terms',
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      },
    })
  },
  'signer/rejectAssignment': {
    required_login: false,
    required_workspace: false,
    fn: async (documentId, assignmentId, data, signerAccessCode) => axios({
      method: 'PUT',
      url: `/documents/${documentId}/assignments/${assignmentId}/reject`,
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      },
      data: JSON.stringify(data)
    })
  },
  'signer/declineMultiple': {
    required_login: false,
    required_workspace: false,
    fn: async (documentIds, assignmentId, data, signerAccessCode) => axios({
      method: 'PUT',
      url: '/signers/documents/decline-multiple',
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      },
      data: JSON.stringify(Object.assign({
        document_ids: documentIds,
        ...data
      }))
    })
  },
  'signer/signMultiple': {
    required_login: false,
    required_workspace: false,
    fn: async (documentIds, signerAccessCode) => axios({
      method: 'PUT',
      url: '/signers/documents/sign-multiple',
      baseURL: apiBaseEndpoint(),
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        'signer-access-code': signerAccessCode
      },
      data: JSON.stringify({
        document_ids: documentIds
      })
    })
  },
  'assignment/resetExpiration': {
    required_login: true,
    required_workspace: false,
    fn: async (documentId, assignmentId, data) => axios({
      method: 'PUT',
      url: `/documents/${documentId}/assignments/${assignmentId}/reset-expiration`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(data)
    })
  },
  'authorization/permissionIndex': {
    required_login: false,
    required_workspace: true,
    fn: async (accountId) => axios({
      method: 'GET',
      url: `/accounts/${accountId}/permissions`,
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint()
    })
  },
  'socialLogin/login': {
    required_login: false,
    required_workspace: false,
    fn: async (credentials) => {
      return new Promise((resolve, reject) => {
        axios({
          method: 'POST',
          baseURL: apiBaseEndpoint(),
          url: '/authentication/social-login',
          data: credentials
        })
          .then(resp => {
            setSession(resp, resolve)
          }).catch(error => {
            clearSession()
            reject(error)
          })
      })
    }
  },
  'user/generateApiKey': {
    required_login: true,
    required_workspace: false,
    fn: async (formData) => axios({
      method: 'POST',
      url: '/users/api-keys',
      headers: defaultHeaders(),
      baseURL: apiBaseEndpoint(),
      data: JSON.stringify(formData)
    })
  },
  'user/viewApiKey': {
    required_login: true,
    required_workspace: false,
    fn: async (config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: '/users/api-keys',
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'webhook/eventTypes': {
    required_login: true,
    required_workspace: true,
    fn: async (config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: '/webhooks/event-types',
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'webhook/update': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, formData, config = {}) => {
      return axios(Object.assign({
        method: 'PUT',
        url: `/accounts/${accountId}/webhooks/subscriptions`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint(),
        data: JSON.stringify(formData)
      }, config))
    }
  },
  'webhook/index': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, config = {}) => {
      return axios(Object.assign({
        method: 'GET',
        url: `/accounts/${accountId}/webhooks/subscriptions`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'webhook/inactivate': {
    required_login: true,
    required_workspace: true,
    fn: async (accountId, config = {}) => {
      return axios(Object.assign({
        method: 'PUT',
        url: `/accounts/${accountId}/webhooks/inactivate`,
        headers: defaultHeaders(),
        baseURL: apiBaseEndpoint()
      }, config))
    }
  },
  'signer/downloadDocument': {
    required_login: false,
    required_workspace: false,
    fn: async (signerId, documentId, artifactName, signerAccessCode) => axios({
      method: 'GET',
      url: `/signers/${signerId}/documents/${documentId}/download/${artifactName}`,
      headers: {
        'Content-Type': 'application/json'
      },
      baseURL: apiBaseEndpoint(),
      responseType: 'blob',
      params: {
        'signer-access-code': signerAccessCode
      }
    })
  }
}

const Api = (name) => {
  const Endpoint = Endpoints[name]

  if (!Endpoint) {
    return
  }

  if ((getPermissions(name) || !Endpoint.required_login) ||
    (!getPermissions(name) && !Endpoint.required_workspace)
  ) {
    return Endpoint.fn
  }

  // return
  return () => new Promise((resolve, reject) => {
    reject({
      response: {
        data: {
          message: 'Você não tem permissão ou endpoint não foi encontrado'
        }
      }
    })
  })
}

const deleteAccount = (allowed) => {
  let endpoint = 'accountUser/leave'

  if (allowed) {
    endpoint = 'account/delete'
  }

  return Endpoints[endpoint].fn
}

export {
  Api,
  cancelRequest,
  getSession,
  updateSession,
  login,
  downloadFile,
  downloadFileBlob,
  urlWithAccessToken,
  urlWithSignerAccessCode,
  selectAccount,
  updateAccounts,
  deleteAccount,
  clearSession
}
