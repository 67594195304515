import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Api } from '../../api/Assinafy';
import { Page } from '../../layout';
import { Icon, Spinner, Button, Alert } from '../../components';

import './styles.scss';
import { useState } from 'react';

const ProfileDelete = ({ scheduled }) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [error, setError] = useState([])

  useEffect(() => {
    if (state) {
      Api('user/deleteSelf')(state)
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            if (data.delete_status === 'done') {
              navigate('/logout')
            } else if (data.to_be_deleted_at) {
              navigate('/dashboard/profile/delete-scheduled')
            }
          }
        })
        .catch(({ response }) => {
          if (response) {
            setError([response.data.message])
          }
        })
    }
  }, [state])

  const pageContent = () => {
    if (scheduled) {
      return (<>
        <Icon id="icon-date" size="88" />
        <h4 className="h4">
          Exclusão de conta agendada!
        </h4>
        <p>
          Em 30 dias, sua conta será excluída e você não poderá mais acessar os documentos assinados.
        </p>
        <p>
          Caso mude de ideia, você pode cancelar a exclusão da conta e manter todos os seus documentos disponíveis.
        </p>
        <Button
          size="lg"
          variant="primary"
          as={Link}
          to={'/dashboard'}
        >
          Continuar acessando Assinafy
          <Icon id="icon-arrow_forward" className="ms-2" size="18" />
        </Button>
      </>)
    }

    return (<>
      <Spinner />
      Confirmando agendamento de exclusão da conta
    </>)
  }

  return (
    <Page className="page-profile-delete"
      title="Exclusão de conta"
    >
      <Alert show={error.length} variant="warning">
        {error.map((error) => error)}
      </Alert>

      <div className="page-opening">
        {pageContent()}
      </div>
    </Page>
  );
}

export default ProfileDelete;