import React, { useEffect, useState, useMemo, createContext } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { getSession } from '../api/Assinafy'
import Dashboard from '../layout/Dashboard'
import _ from 'lodash'

export const SessionContext = createContext();

const DashboardRoute = () => {
  const [session, setSession] = useState({})
  const navigate = useNavigate()
  const { user } = getSession()
  const { state } = useLocation()

  const memoSession = useMemo(() => [session, setSession], [session])

  useEffect(() => {
    if (!user) {
      navigate('/', { state: state })
    }
  })

  useEffect(() => {
    if (!_.isEmpty(session)) {
      return
    }
    
    setSession(getSession())
  }, [user])

  if (!user || _.isEmpty(session)) {
    return (<></>)
  }

  return (<SessionContext.Provider value={memoSession}>
    <Dashboard>
      <Outlet />
    </Dashboard>
  </SessionContext.Provider>)
};

export default DashboardRoute;
