import React from 'react';
import classNames from 'classnames';

import './styles.scss'

const Spinner = (props)  => {
  const { className } = props
  const newClassName = classNames('spinner', className)

  return (
    <svg className={newClassName} viewBox="25 25 50 50">
      <circle className="line" cx="50" cy="50" r="20" strokeLinecap="round"></circle>
      <circle className="dash" cx="50" cy="50" r="20" strokeLinecap="round"></circle>
    </svg>
  )
}

export default Spinner;