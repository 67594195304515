import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Api } from '../../../api/Assinafy'
import { Section } from '../../../layout';
import { Toast, Button, Icon } from '../../../components';
import ModalExpiration from './ModalExpiration';
import { timeFormat } from '../../../utils/Formats';

const SectionExpiration = ({ documentId, assignment }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [toast, setToast] = useState('')
  const [modalExpiration, setModalExpiration] = useState(false)

  const sectionOpening = () => {
    if (!assignment.expires_at) {
      return 'Deseja colocar uma data de validade para esse documento?'
    } else {
      const date = new Date(assignment.expires_at)
      return `O documento está disponível para assinatura até ${date.toLocaleString('pt-BR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })} às ${timeFormat(date)}.`
    }
  }

  const clearExpiration = () => {
    setLoading(true)

    Api('assignment/resetExpiration')(documentId, assignment.id, {
      expires_at: ''
    })
      .then(({ status }) => {
        if (status === 200) {
          setToast('Data de validade removida com sucesso, todos os signatários vão receber uma notificação com a alteração.');
        }

        setLoading(false)
        navigate(0)
      })
      .catch(({ response }) => {
        setLoading(false)
      })
  }

  return (<>
    <Section className="section-expiration">
      <Section.Header
        title="Validade do documento"
        opening={<>
          {sectionOpening()}
          <div className="btn-group">
            {assignment?.expires_at &&
              <Button variant="danger"
                onClick={clearExpiration}
                loading={loading}
                loadingText="REMOVENDO..."
              >
                <Icon id="icon-trash" size="18" />
                REMOVER DATA
              </Button>
            }
            <Button variant="info"
              onClick={() => setModalExpiration(true)}
            >
              <Icon id="icon-date" size="18" />
              {assignment?.expires_at ? 'ALTERAR' : 'DEFINIR'} DATA
            </Button>
          </div>
        </>}
      />
    </Section>
    <ModalExpiration show={!!modalExpiration}
      onClose={() => setModalExpiration(false)}
      documentId={documentId}
      assignmentId={assignment.id}
      expiration={assignment.expires_at}
    />
    <Toast show={toast} onClose={() => setToast('')}>
      {toast}
    </Toast>
  </>)
}

export default SectionExpiration;