import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Page, Section } from '../../layout';
import { Button, Icon } from '../../components';

const DocumentSent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { state } = location

  // useEffect(() => {
  //   if (!state) {
  //     navigate('/dashboard/documents')
  //   }
  // }, [state])

  return (
    <Page className="page-document-sent">
      <Section>
        <Icon id="icon-check" size="72" />
        <h1 className="h1">
          Documento enviado com sucesso!
        </h1>
        <Section.Opening>
          <b>{state?.name}</b> foi enviado para assinatura, você pode acompanhar os status desse documento a qualquer momento.
        </Section.Opening>
        <Button as={Link} to={'/dashboard'} variant="primary">
          IR PARA O INÍCIO
        </Button>
      </Section>
    </Page>
  )
}

export default DocumentSent;