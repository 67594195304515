import React, { useRef } from 'react';
import { Api, getSession } from '../../api/Assinafy'
import { useNavigate } from 'react-router-dom';
import { Page } from '../../layout';
import { Input, Form } from '../../components';

import './styles.scss';

const ChangePassword = () => {
  const navigate = useNavigate()
  const { user } = getSession()
  const confirmPasswordRef = useRef()

  const handleSubmit = (formData) => Api('authentication/changePassword')(formData)
    .then(({ status }) => {
      if (status === 200) {
        navigate('/password-reset/success')
      }
    })

  const handleBlur = (formData) => {
    if (formData.new_password !== formData.confirm_password) {
      confirmPasswordRef.current.setCustomValidity('Senha e confirmação de senha são diferentes')
    } else {
      confirmPasswordRef.current.setCustomValidity('')
    }
  }

  return (
    <Page className="page-sign"
      title="Defina sua nova senha"
      opening="Escolha uma nova senha de acesso."
      btnAccount={{
        title: 'Não possui conta?',
        btn: { url: '/signup', text: 'Criar conta' }
      }}
    >
      <Form id="form-password-change" className="form-sign"
        onSubmit={handleSubmit}
        size="lg"
        data={{
          email: user.email
        }}
        controls={[
          {
            Component: Input.Password,
            name: 'password',
            label: 'Senha atual',
            required: true,
            autoComplete: 'current-password'
          },
          {
            Component: Input.Password,
            name: 'new_password',
            label: 'Nova senha',
            onBlur: handleBlur,
            required: true,
            help: 'A senha deve conter pelo menos 8 caracteres.',
            autoComplete: 'new-password'
          },
          {
            Component: Input.Password,
            ref: confirmPasswordRef,
            name: 'confirm_password',
            label: 'Confirmar senha',
            onBlur: handleBlur,
            required: true,
            help: 'A senha deve ser igual a de cima.',
            autoComplete: 'new-password'
          }
        ]}
        buttons={[
          { variant: 'primary', submit: true, children: 'Salvar nova senha' },
          { variant: 'link', onClick: () => navigate(-1), children: 'Cancelar' }
        ]}
      />
    </Page>
  );
};

export default ChangePassword;
