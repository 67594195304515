import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Textarea = ({ variant, size, label, ...props }) => {
  const { className, placeholder } = props
  const prefix = 'form-control';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, size && `${prefix}-${size}`, className);

  return (<>
    <textarea {...props} className={newClassName} placeholder={(!label && placeholder) || (label && ` `)} />
    {label && <label className="form-label">
      {label}
    </label>}
  </>);
};

export default Textarea;
