import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Api, cancelRequest } from '../../api/Assinafy'
import { Avatar, Alert, Button, DataList, Dropdown, EmptyList, Form, Input, Icon, Modal, Spinner, Table, Tabs, Tooltip } from '../../components'
import { signerEmpty } from '../../utils/Signers'
import classNames from 'classnames'

const debounce = (fn, bufferInterval) => {
  let timeout;

  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(fn.apply.bind(fn, this, arguments), bufferInterval);
  }
}

const ApiSigner = Api('signer/index')

const getSigners = debounce((value, signers, setNewSigners) => {
  ApiSigner({
    params: {
      'search': value,
      'per-page': 5
    }
  })
    .then(({ status, data: { data } }) => {
      if (status === 200) {
        if (data.length) {
          data.map((signer) => {
            setNewSigners((prevState) => {
              const prevSigners = typeof prevState === 'undefined' ? [] : prevState
    
              if (signers.find((prevSigner) => prevSigner.id === signer.id)) {
                return prevSigners
              }
    
              return [
                ...prevSigners,
                signer
              ]
            })
          })
        } else {
          setNewSigners([])
        }
      }
    })
}, 350)

const Signer = {}

const SignerListItem = (props) => {
  const { full_name, email, onClick } = props

  return (
    <div className="datalist-item" onClick={onClick}>
      <Avatar placeholder={full_name[0] || email[0]} />
      <span className="signer-name">
        {full_name}
      </span>
      <span className="signer-email">
        {email}
      </span>
    </div>
  )
};

const SignerDropdown = forwardRef(({
  items,
  active,
  colors, /* TODO: passar para context??? */
  onChangeItem,
  onRemoveItem,
  onClickNew
}, ref) => {
  return (<Dropdown ref={ref}
    button={Object.keys(active).length &&
      <SignerDropdownItem {...active}
        as="div"
        bgColor={colors[items.findIndex(s => s.id === active.id)].color}
      />
    }
    className="dropdown-signer"
  >
    {items.map((signer, i) => {
      return <Dropdown.Item
        key={i}
        as={'div'}
        className={active && items.email === active.email ? 'active' : ''}
      >
        <SignerDropdownItem {...signer}
          onClick={() => onChangeItem(signer, i)}
          bgColor={colors[i].color}
        />
        {signer.id !== active.id &&
          <Button variant="icon"
            className="btn-remove"
            onClick={() => onRemoveItem(signer)}
          >
            <Icon id="icon-trash" size="16" />
          </Button>
        }
      </Dropdown.Item>
    })}
    <Button variant="link"
      className="btn-signer-add"
      onClick={onClickNew}
    >
      <Icon id="icon-add" className="me-1" size="18" />
      ADICIONAR CONTATO
    </Button>
  </Dropdown>)
});

const SignerDropdownItem = ({ as: Component = 'button', type, ...props }) => {
  const { full_name, email, bgColor, onClick } = props

  if (Component === 'button') {
    type = 'button';
  }

  return (
    <Component className="signer-item"
      type={type}
      onClick={onClick}
    >
      <Avatar placeholder={full_name[0] || email[0]} bgColor={bgColor} />
      <div className="signer-info">
        {full_name}
        <div className="signer-email">
          {email}
        </div>
      </div>
    </Component>
  )
};

const SignerAdd = ({ signers, onUpdate, onRemove, formValidation, setFormValidation, ...props }) => {
  const { uuid, id, full_name, email } = props
  const [tooltipShow, setTooltipShow] = useState(false)
  const [newSigner, setNewSigner] = useState({ uuid, id, full_name, email })
  const [newSigners, setNewSigners] = useState()
  const fullnameRef = useRef()
  const emailRef = useRef()
  const datalistNameRef = useRef()
  const datalistEmailRef = useRef()

  const debounceSigners = useCallback(getSigners, [])

  const updateNewSigner = (event) => {
    setNewSigner({
      ...newSigner,
      [event.target.name]: event.target.value
    });
  }

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value

    if (value.length > 3) {
      if (target.name === 'email') {
        datalistEmailRef.current.show()
      } else {
        datalistNameRef.current.show()
      }

      setNewSigners()

      debounceSigners(value, signers, setNewSigners)
    }

    updateNewSigner(event)
  }

  const handleInputFocus = (event) => {
    const target = event.target

    if (newSigners?.length) {
      if (target.name === 'email') {
        datalistEmailRef.current.show()
      } else {
        datalistNameRef.current.show()
      }
    }
  }

  const handleInputBlur = (event) => {
    const target = event.target;
    const validationFilter = formValidation.filter((error) => error.uuid !== target.dataset.uuid || (error.uuid === target.dataset.uuid && error.name !== target.name));

    if (target.checkValidity()) {
      setFormValidation(validationFilter)
    } else {
      setFormValidation([
        ...validationFilter,
        { uuid: target.dataset.uuid, name: target.name, message: target.validationMessage }
      ])
    }

    let wait = 0;
    if (!event.relatedTarget) {
      wait = 250
    }
    if (target.name === 'email') {
      datalistEmailRef.current?.hide(wait)
    } else {
      datalistNameRef.current?.hide(wait)
    }
  }

  const handleItemClick = (item) => {
    const { id, full_name, email } = item
    const fullnameField = fullnameRef.current
    const emailField = emailRef.current

    fullnameField.value = full_name
    fullnameField.setAttribute('readonly', true)
    emailField.value = email
    emailField.setAttribute('readonly', true)

    setNewSigner({
      uuid: newSigner.uuid,
      id,
      full_name,
      email
    })

    setFormValidation(formValidation.filter((error) => error.uuid !== newSigner.uuid))

    datalistEmailRef.current.hide(0)
    datalistNameRef.current.hide(0)
  }

  const tooltipMouseEnter = () => {
    setTooltipShow(true)
  }

  const tooltipMouseLeave = () => {
    setTooltipShow(false)
  }

  const showInputError = (inputName) => {
    const error = formValidation.find((error) => error.uuid === newSigner.uuid && error.name === inputName)
    if (error) {
      return (<div className="help-block">
        {error.message}
      </div>)
    }
  }

  useEffect(() => {
    onUpdate && onUpdate(newSigner, uuid || id)
  }, [newSigner])

  return (<Row className="row-signer">
    <Col xs="12" lg>
      <Form.Group>
        <Input.Email ref={emailRef} name="email"
          autoComplete="off"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={email}
          label="E-mail"
          data-uuid={newSigner.uuid}
          readOnly={newSigner.id}
          autoFocus={true}
          required
        />
        {showInputError('email')}
        <DataList
          ref={datalistEmailRef}
          className="datalist-signer"
          items={newSigners}
          renderItem={(item, i) => {
            return <Signer.ListItem key={i}
              {...item}
              onClick={() => handleItemClick(item)}
            />
          }}
        />
      </Form.Group>
    </Col>
    <Col xs="10" lg>
      <Form.Group>
        <Input ref={fullnameRef} name="full_name"
          autoComplete="off"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={full_name}
          label="Nome"
          data-uuid={newSigner.uuid}
          readOnly={newSigner.id}
          required
        />
        {showInputError('full_name')}
        <DataList
          ref={datalistNameRef}
          className="datalist-signer"
          items={newSigners}
          renderItem={(item, i) => {
            return <Signer.ListItem key={i}
              {...item}
              onClick={() => handleItemClick(item)}
            />
          }}
        />
      </Form.Group>
    </Col>
    <Col xs="auto">
      <Button variant="icon" className="btn-remove"
        onMouseEnter={tooltipMouseEnter}
        onMouseLeave={tooltipMouseLeave}
        onClick={() => onRemove(uuid || id)}
      >
        <Icon id="icon-trash" size="28" />
        <Tooltip show={tooltipShow}>
          Excluir
        </Tooltip>
      </Button>
    </Col>
  </Row>)
};

const SignerNewModal = ({
  show,
  signers,
  onCreate,
  onClose
}) => {
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formData, setFormData] = useState({
    id: null,
    full_name: '',
    email: ''
  })
  const [newSigners, setNewSigners] = useState()
  const cancelToken = cancelRequest()
  const fullnameRef = useRef()
  const emailRef = useRef()
  const datalistNameRef = useRef()
  const datalistEmailRef = useRef()

  const debounceSigners = useCallback(getSigners, [])

  const handleSubmit = (event) => {
    event.preventDefault()

    setValidated(true)

    if (event.target.checkValidity()) {
      const { id, full_name, email } = formData
      const addSignersContext = ({ id, full_name, email }) => {
        onCreate((prevState) => {
          if (prevState.find((signer) => signer.email === email)) {
            return prevState
          }

          return [
            ...prevState, {
              id,
              full_name,
              email
            }
          ]
        })
      }

      setLoading(true)

      if (!id) {
        ApiSigner({
          params: {
            'search': email
          }
        })
          .then(({ status, data: { data } }) => {
            if (status === 200) {
              if (data.length) {
                addSignersContext({ id: data[0].id, full_name, email })
                handleClose()
              } else {
                Api('signer/create')({ full_name, email })
                  .then(({ status, data: { data } }) => {
                    if (status === 200) {
                      addSignersContext({ id: data.id, full_name, email })
                      handleClose()
                    }
                  })
                  .catch(({ response }) => {
                    if (response) {
                      setFormError([response.data.message])
                    }

                    setLoading(false)
                  })
              }
            }
          })
      } else {
        addSignersContext({ id, full_name, email })
        handleClose()
      }
    }
  }

  const updateNewSigner = (event) => setFormData({
    ...formData,
    [event.target.name]: event.target.value
  })

  const handleInputChange = (event) => {
    const target = event.target
    const value = target.value

    if (value.length > 3) {
      if (target.name === 'email') {
        datalistEmailRef.current.show()
      } else {
        datalistNameRef.current.show()
      }

      setNewSigners()

      debounceSigners(value, signers, setNewSigners)
    }

    updateNewSigner(event)
  }

  const handleInputFocus = (event) => {
    const target = event.target

    if (newSigners?.length) {
      if (target.name === 'email') {
        datalistEmailRef.current.show()
      } else {
        datalistNameRef.current.show()
      }
    }
  }

  const handleInputBlur = (event) => {
    const target = event.target

    if (target.name === 'email') {
      datalistEmailRef.current?.hide(250)
    } else {
      datalistNameRef.current?.hide(250)
    }
  }

  const handleItemClick = (item) => {
    const { id, full_name, email } = item
    const fullnameField = fullnameRef.current
    const emailField = emailRef.current

    fullnameField.value = full_name
    fullnameField.setAttribute('readonly', true)
    emailField.value = email
    emailField.setAttribute('readonly', true)

    setFormData({
      id,
      full_name,
      email
    })

    datalistEmailRef.current.hide(0)
    datalistNameRef.current.hide(0)
  }

  const handleClose = () => {
    setFormData({})
    setNewSigners()
    setValidated(false)
    setLoading(false)
    setFormError([])
    onClose()
  }

  useEffect(() => {
    return () => {
      cancelToken.cancel()
    }
  }, [])

  return (<Modal show={show}
    onHide={handleClose}
    centered
  >
    <Form id="form-document-signers-new-modal"
      wasValidated={validated}
      onSubmit={handleSubmit}
    >
      <Modal.Header>
        <Button variant="icon" className="btn-close"
          onClick={handleClose}
        >
          <Icon id="icon-close" size="24" />
        </Button>
        <Modal.Title as={'div'} className="h2">
          Adicionando novo signatário
        </Modal.Title>
        Informe a baixo os dados do contato que você deseja enviar documentos para assinatura
      </Modal.Header>
      <Modal.Body>
        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>
        <Form.Group>
          <Input.Email ref={emailRef} name="email"
            autoComplete="off"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={formData.email}
            label="E-mail" size="lg"
            autoFocus={true}
            required
          />
          <DataList
            ref={datalistEmailRef}
            className="datalist-signer"
            items={newSigners}
            renderItem={(item, i) => {
              return <SignerListItem key={i}
                {...item}
                onClick={() => handleItemClick(item)}
              />
            }}
          />
        </Form.Group>
        <Form.Group>
          <Input ref={fullnameRef} name="full_name"
            autoComplete="off"
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={formData.full_name}
            label="Nome"
            size="lg"
            required
          />
          <DataList
            ref={datalistNameRef}
            className="datalist-signer"
            items={newSigners}
            renderItem={(item, i) => {
              return <SignerListItem key={i}
                {...item}
                onClick={() => handleItemClick(item)}
              />
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="lg" submit
          loading={loading}
        >
          <Icon id="icon-check" className="me-2" size="18" />
          SALVAR CONTATO
        </Button>
      </Modal.Footer>
    </Form>
  </Modal>)
};

const SignerListModal = ({
  show,
  onClose,
  newSigners,
  setNewSigners
}) => {
  const [signers, setSigners] = useState()
  const [signersSelected, setSignersSelected] = useState([])
  const [errors, setErrors] = useState([])
  const [tabKey, setTabKey] = useState('all')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [paginationPageCount, setPaginationPageCount] = useState(-1)
  const [search, setSearch] = useState('')

  const tabAllRef = useRef()
  const cancelToken = cancelRequest()

  const handleClose = () => {
    onClose()
    setSigners()
    setPage(0)
    setSearch('')
  }

  const handleCheckboxChangeAll = () => {
    if (signersSelected.length) {
      setSignersSelected([])
    } else {
      setSignersSelected(signers.filter(s => !newSigners.some(n => n.id === s.id)))
    }
  }

  const handleCheckboxChange = (signer) => {
    if (signersSelected.includes(signer)) {
      setSignersSelected(signersSelected.filter(d => d.email !== signer.email))
    } else {
      setSignersSelected([...signersSelected, signer])
    }
  }

  const handleAddSigners = () => {
    if (signersSelected.length) {
      signersSelected.map((signer) => {
        if (!newSigners.find(s => s.id === signer.id)) {
          const { id, full_name, email } = signer
          setNewSigners(prevState => {
            return [
              ...prevState,
              signerEmpty({ id, full_name, email })
            ]
          })
        }
      })

      setNewSigners((prevState) => prevState.filter((s) => s.email && s.full_name))
    }

    setSignersSelected([])
    handleClose()
  }

  const getSigners = (nextPage = page + 1) => {
    setTabKey('all')

    if (nextPage === 1) {
      setPaginationPageCount(-1)
    }

    if (
      paginationPageCount > 0 && paginationPageCount === page
    ) {
      return
    }

    setLoading(true)

    ApiSigner({
      ...cancelToken.config,
      params: {
        'page': nextPage,
        'per-page': 10,
        search
      }
    })
      .then(({ status, headers, data: { data } }) => {
        if (status === 200) {
          if (data.length) {
            setPage(nextPage)
            setPaginationPageCount(parseInt(headers['x-pagination-page-count']))

            setSigners([...signers || [], ...data])
          } else {
            setSigners([])
          }
        }

        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const debounceSigners = useCallback(debounce(getSigners, 350), [signers, newSigners, search])

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    setSigners()
    debounceSigners(1)
  }

  const handleSearchChange = ({ target }) => {
    setSearch(target.value)
  }

  const signersTotalMessage = (total) => {
    const addS = total > 1 ? 's' : ''
    return total
      ? `${total} contato${addS} selecionado${addS}`
      : 'Nenhum contato selecionado'
  }

  useImperativeHandle(tabAllRef, () => ({
    loading,
    getSigners
  }))

  useEffect(() => {
    if (!show && !search) {
      return
    }

    setSigners()
    debounceSigners(1)

    return () => {
      cancelToken.cancel()
    }
  }, [search])

  useEffect(() => {
    setSignersSelected([])

    if (!show || signers?.length) {
      return
    }

    getSigners(1)

    return () => {
      cancelToken.cancel()
    }
  }, [show])

  useEffect(() => {
    if (!show) {
      return
    }

    const tabPanes = document.querySelectorAll('.tab-pane[data-scroll]')
    const handleScroll = (e) => {
      const target = e.target
      if (!tabAllRef.current.loading && target.clientHeight + target.scrollTop > (target.scrollHeight - 5)) {
        tabAllRef.current.getSigners()
      }
    }

    tabPanes.forEach((tabPane) => {
      tabPane.addEventListener('scroll', handleScroll)
    })

    return () => {
      tabPanes.forEach((tabPane) => {
        tabPane.removeEventListener('scroll', handleScroll)
      })

      cancelToken.cancel()
    }
  }, [show])

  return (<Modal show={show}
    className="modal-contacts"
    centered
    onHide={handleClose}
  >
    <Modal.Header>
      {onClose && <Button variant="icon" className="btn-close"
        onClick={handleClose}
      >
        <Icon id="icon-close" size="24" />
      </Button>}
      <Modal.Title as={'div'} className="h2">
        Contatos
      </Modal.Title>
      <Alert show={errors.length} variant="warning" onClose={() => setErrors([])}>
        {errors.map((error) => error)}
      </Alert>
      <Form.Group>
        <Input.Group>
          <Input.Search
            name="search"
            placeholder="Busque pelo nome ou e-mail do contato"
            onChange={handleSearchChange}
            autoFocus
          />
          <Button variant="action"
            onClick={handleSearchSubmit}
          >
            <span className="d-lg-none">
              <Icon id="icon-zoom" size="20" />
            </span>
            <span className="d-none d-lg-block">
              BUSCAR
            </span>
          </Button>
        </Input.Group>
      </Form.Group>
    </Modal.Header>
    <Modal.Body>
      <Tabs
        activeKey={tabKey}
        onSelect={setTabKey}
      >
        <Tabs.Tab ref={tabAllRef} eventKey="all" title="Todos" data-scroll>
          {signers && !signers.length
            ? <EmptyList
              icon="icon-person"
              title="Você ainda não possui contatos na sua lista"
            />
            : <Table
              className="table-bordered mb-0"
              header={[
                {
                  name: <Form.Check>
                    <Input.Checkbox
                      onChange={handleCheckboxChangeAll}
                      checked={signersSelected.length}
                      className={(signersSelected.length < signers?.length) ? 'has-checked' : ''}
                      disabled={!signers?.length}
                    />
                  </Form.Check>,
                  className: 'col-action'
                },
                { name: 'Nome' },
                { name: 'E-mail' }
              ]}
              rows={signers}
              renderRow={(row, i) => {
                const isSelected = newSigners.findIndex(ss => ss.id === row.id) !== -1
                const isChecked = signersSelected.findIndex(ss => ss.id === row.id) !== -1
                const trClassName = classNames(isSelected && 'signer-selected', isChecked && 'signer-checked')

                return (<tr
                    key={row.id}
                    className={trClassName}
                    onClick={() => handleCheckboxChange(row)}
                  >
                  <td><Form.Check>
                    <Input.Checkbox
                      onChange={() => handleCheckboxChange(row)}
                      checked={isSelected || isChecked}
                    />
                  </Form.Check></td>
                  <td>
                    <div className="signer-header">
                      <Avatar placeholder={row.full_name[0] || row.email[0]} />
                      <div className="signer-title">{row.full_name}</div>
                    </div>
                  </td>
                  <td>{row.email}</td>
                </tr>)
              }}
            />
          }
          {(paginationPageCount > page) &&
            <Spinner className="pagination-spinner" />
          }
        </Tabs.Tab>
        <Tabs.Tab eventKey="selected" title="Selecionados">
          <Table
            className="table-bordered mb-0"
            header={[
              {
                name: <Form.Check>
                  <Input.Checkbox
                    onChange={handleCheckboxChangeAll}
                    checked={signersSelected.length}
                    className={(signersSelected.length < signers?.length) ? 'has-checked' : ''}
                    disabled={!signersSelected.length}
                  />
                </Form.Check>,
                className: 'col-action'
              },
              { name: 'Nome' },
              { name: 'E-mail' }
            ]}
            rows={signersSelected}
            renderRow={(row, i) => <tr key={row.id}>
              <td><Form.Check>
                <Input.Checkbox
                  onChange={() => handleCheckboxChange(row)}
                  checked={signersSelected.includes(row)}
                />
              </Form.Check></td>
              <td>
                <div className="signer-header"
                  onClick={() => handleCheckboxChange(row)}
                >
                  <Avatar placeholder={row.full_name[0] || row.email[0]} />
                  <div className="signer-title">{row.full_name}</div>
                </div>
              </td>
              <td>{row.email}</td>
            </tr>}
          />
        </Tabs.Tab>
      </Tabs>
    </Modal.Body>
    {!!signers?.length && <Modal.Footer>
      <b>
        {signersTotalMessage(signersSelected.filter(s => !newSigners.some(ss => ss.id === s.id)).length)}
      </b>
      <Button variant="primary"
        onClick={handleAddSigners}
      >
        ADICIONAR
      </Button>
    </Modal.Footer>}
  </Modal>)
}

Signer.Add = SignerAdd
Signer.ListItem = SignerListItem
Signer.Dropdown = SignerDropdown
Signer.NewModal = SignerNewModal
Signer.ListModal = SignerListModal

export default Signer;