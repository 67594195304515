import React, { useEffect } from 'react';
import classNames from 'classnames';

import './styles.scss';

const Image = ({
  placeholder,
  width,
  height,
  style = {},
  src, ...props
}) => {
  const { children, className } = props
  const newClassName = classNames('image', className);

  let newStyle = {
    backgroundImage: `url(${placeholder})`,
    width: width && width + 'px',
    height: height && height + 'px',
    ...style
  }

  return (
    <div {...props} className={newClassName} style={newStyle}>
      <img src={src} onError={(event) => {
        placeholder && event.target.remove()
      }} />
      {children}
    </div>
  );
};

export default Image;
