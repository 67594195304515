const fields = {
  date: {
    maxLength: 10,
    pattern: '[0-9]{2}/[0-9]{2}/[0-9]{4}',
    type: 'tel',
    mask: (event) => {
      const target = event.target;
      const value = target.value.replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2}\/\d{2})(\d)/, '$1/$2');
      return target.value = value
    }
  },
  postalCode: {
    maxLength: 9,
    pattern: '[0-9]{5}-[0-9]{3}',
    type: 'tel',
    mask: (event) => {
      const target = event.target;
      const value = target.value.replace(/\D/g, '')
        .replace(/^(\d{5})(\d)/, '$1-$2');
      return target.value = value
    }
  },
  cnpj: {
    maxLength: 18,
    pattern: '[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}',
    type: 'tel',
    mask: (event) => {
      const target = event.target;
      const value = target.value.replace(/\D/g, '')
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2}\.\d{3})(\d)/, '$1.$2')
        .replace(/^(\d{2}\.\d{3}\.\d{3})(\d)/, '$1/$2')
        .replace(/^(\d{2}\.\d{3}\.\d{3}\/\d{4})(\d)/, '$1-$2');
      return target.value = value
    }
  },
  cpf: {
    maxLength: 14,
    pattern: '[0-9]{3}.[0-9]{3}.[0-9]{3}-[0-9]{2}',
    type: 'tel',
    mask: (event) => {
      const target = event.target;
      const value = target.value.replace(/\D/g, '')
        .replace(/^(\d{3})(\d)/, '$1.$2')
        .replace(/^(\d{3}\.\d{3})(\d)/, '$1.$2')
        .replace(/^(\d{3}\.\d{3}\.\d{3})(\d)/, '$1-$2')
      return target.value = value
    }
  },
  number: {
    type: 'tel',
    mask: (event) => {
      const target = event.target;
      return target.value = target.value.replace(/\D/g, '');
    }
  },
  phoneNumber: {
    maxLength: 15,
    pattern: '\\([0-9]{2}\\) [0-9]{4,5}-[0-9]{4}',
    type: 'tel',
    mask: (event) => {
      const target = event.target;
      let value = target.value.replace(/\D/g, '')
        .replace(/^(\d{1})/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2')
        .replace(/(\d)(\d{4})$/, '$1-$2');

      return target.value = value
    }
  },
  email: {
    type: 'email',
    pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,5}'
  },
  text: {
    type: 'text'
  }
}

const getFieldProps = (type) => {
  if (fields[type]) {
    return fields[type]
  }

  return fields['text']
}

export {
  getFieldProps,
  fields
}