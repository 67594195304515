import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, EmptyList } from '../../components'

import noResults from '../../assets/images/search-noresults.svg';

const DocumentsNotFound = ({ search }) => {
  return (
    <EmptyList
      content={<>
        <img className="icon" src={noResults} width="80" height="80" alt="" />
        <h2 className="h1">
          Nenhum resultado encontrado
        </h2>
        <p>
          Não encontramos nenhum item que corresponda à sua pesquisa{search ? ` ${search}` : null}.
        </p>
        <p>
          Você pode procurar na sua lista de documentos.
        </p>
        <Button as={Link} to={'/dashboard/documents'} variant="primary">
          <Icon id="icon-description" className="me-1" size="18" />
          VER DOCUMENTOS
        </Button>
      </>}
    />
  )
}

export default DocumentsNotFound;