import React from 'react';
import { Section } from '../../../layout';
import { Icon } from '../../../components';
import { dateFormat, timeFormat } from '../../../utils/Formats';

const SectionActivities = (props) => {
  const { activities } = props

  const activityClassName = (event) => {
    switch (event) {
      case 'signer_signed_document':
        return 'activity-success'
      case 'signer_rejected_document':
        return 'activity-danger'
      default:
    }
  }

  const activityIcon = (event) => {
    switch (event) {
      case 'signer_signed_document':
        return 'signed'
      case 'signer_rejected_document':
        return 'denied'
      default:
        return 'schedule'
    }
  }

  const showTime = (date) => {
    const now = new Date()
    const diff = now.getTime() - new Date(date).getTime()
    const diffHours = diff / 1000 / 60 / 60

    if (diffHours > 48) {
      return dateFormat(date)
    }

    now.setDate(now.getDate() - 1)

    if (now.toDateString() === new Date(date).toDateString()) {
      return 'Ontem'
    } else {
      return timeFormat(date).replace(/^(\d{2}):(\d{2})/, '$1h$2');
    }
  }

  if (!activities.length) {
    return (<></>)
  }

  return (
    <Section>
      <Section.Header
        title="Histórico do documento"
      />
      <ul className="activities-list">
        {activities?.map((activity, i) => {
          return (<li key={i} className={activityClassName(activity.event)}>
            <Icon id={`icon-${activityIcon(activity.event)}`} className="me-2" size="14" />
            <div>
              <span className="activity-date">
                {showTime(activity.created_at)}
              </span> {activity.message}
            </div>
          </li>)
        })}
      </ul>
    </Section>
  )
}

export default SectionActivities;