const statusIds = {
  'uploading': 'processing',
  'uploaded': 'processing',
  'metadata_processing': 'processing',
  'metadata_ready': 'ready',
  'pending_signature': 'loading',
  'certificating': 'certificating',
  'expired': 'expired',
  'certificated': 'signed',
  'rejected_by_user': 'block',
  'rejected_by_signer': 'denied',
  'failed': 'failed'
};

const statusTitles = {
  'processing': 'Processando',
  'ready': 'Aguardando preparação',
  'loading': 'Aguardando assinatura',
  'certificating': 'Certificando',
  'expired': 'Expirado',
  'signed': 'Assinado',
  'denied': 'Negado',
  'block': 'Cancelado',
  'failed': 'Falhou'
};

const statusFilter = [
  { id: 'certificated', title: 'Assinados' },
  { id: 'pending_signature', title: 'Aguardando' },
  { id: 'metadata_ready', title: 'Pronto' },
  { id: 'rejected_by_user', title: 'Cancelados' },
  { id: 'rejected_by_signer', title: 'Negados' },
  { id: 'certificating' },
  { id: 'failed', title: 'Falhas' },
  { id: 'expired', title: 'Expirados' }
];

const statusIcon = (id, icon) => `icon-${icon || statusIds[id]}`

const statusId = (id) => statusIds[id]

const statusTitle = (id, title) => title || statusTitles[statusIds[id]]

export {
  statusIcon,
  statusId,
  statusTitle,
  statusFilter
}