import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Icon } from '../../components';

import './styles.scss';

const Prepare = ({ children }) => {
  return (<div className="prepare">
    {children}
  </div>)
}

export function PrepareNavbar(props) {
  const { document, step, submitDisabled, submitLoading } = props
  const navigate = useNavigate()

  if (!document) {
    return (<></>)
  }

  return (
    <nav className="navbar">
      <div>
        <Link className={`btn btn-light btn-back ${step !== 'signers' && `d-none d-xl-block`}`} size="lg" to={`/dashboard/documents/${document.id}/view`}>
          <span className="d-none d-xl-flex">
            <Icon id="icon-arrow_left" className="me-1" size="20" />
            {document.name}
          </span>
          <span className="d-xl-none">VOLTAR</span>
        </Link>
        {step !== 'signers' &&
          <Button variant="light" onClick={() => navigate(-1)} className="btn-back d-xl-none">
            VOLTAR
          </Button>
        }
      </div>

      <ul className="timeline">
        <li className={step === 'signers' ? 'active' : ''}>
          <span className="timeline-icon">
            <Icon id="icon-group" size="20" />
          </span>
          <div className="timeline-text">
            Signatários
          </div>
        </li>
        <li className={step === 'prepare' ? 'active' : ''}>
          <span className="timeline-icon">
            <Icon id="icon-description" size="20" />
          </span>
          <div className="timeline-text">
            Preparação
          </div>
        </li>
        <li className={step === 'review' ? 'active' : ''}>
          <span className="timeline-icon">
            <Icon id="icon-check_circle" size="20" />
          </span>
          <div className="timeline-text">
            Revisar e enviar
          </div>
        </li>
      </ul>

      <ul className="nav">
        {step !== 'signers' &&
          <li className="nav-item d-none d-xl-block">
            <Button variant="light" onClick={() => navigate(-1)}>
              VOLTAR
            </Button>
          </li>
        }
        <li className="nav-item">
          <Button variant="primary" submit disabled={submitDisabled} loading={submitLoading}>
            CONTINUAR
          </Button>
        </li>
      </ul>
    </nav>
  )
}

export default Prepare;