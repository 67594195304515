import React, { useEffect, useState } from 'react';
import { Link, useParams, useOutletContext, useLocation } from 'react-router-dom';
import { Api, cancelRequest } from '../../api/Assinafy';
import { Button, Icon, Viewport } from '../../components';
import CreateFreeAccount from './CreateFreeAccount';
import DocumentsSign from './DocumentsSign';
import { Container } from '../../layout';
import { SignNavbar } from '../../layout/Sign';

const DocumentSuccess = () => {
  const [, setLayoutClassName] = useOutletContext()
  const [signer, setSigner] = useState({})
  const { signerAccessCode } = useParams()
  const [documents, setDocuments] = useState([])
  const cancelToken = cancelRequest()
  const location = useLocation()
  const { state } = location

  const getSingularOrPlural = () => {
    const documentId = state?.documentId
    if (documentId) {
      if (Array.isArray(documentId)) {
        if (documentId.length > 1) {
          return 'Estes documentos estão'
        }

        return 'Este documento está'
      }
    }

    return 'Este documento está'
  }

  const signNavbarContent = () => {
    return (<>
      <span className="text-secondary d-none d-lg-block">{signer.email}</span>
    </>)
  }

  useEffect(() => {
    setLayoutClassName('sign-response')

    Api('signer/self')(signerAccessCode)
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setSigner(data)
        }
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  useEffect(() => {
    if (!Object.keys(signer).length) {
      return
    }

    Api('signer/documents')(signer.id, {
      params: {
        'per-page': 999,
        'signer-access-code': signerAccessCode,
        status: 'pending_signature'
      },
      ...cancelToken.config
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          data.map((document) => {
            setDocuments((prevState) => {
              if (
                document.assignment.method === 'virtual' &&
                !document.assignment.summary.signers.find(s => s.id === signer.id).completed
              ) {
                return [...prevState, document]
              }
              return prevState
            })
          })
        }
      })

    return () => {
      setDocuments([])
    }
  }, [signer])

  return (<>
    <SignNavbar toggler={false}>
      {signNavbarContent()}
    </SignNavbar>
    <Viewport>
      <Container>
        <Icon id="icon-failed" className="response-icon" size="48" />
        <h2 className="h1">
          {getSingularOrPlural()} fechado agora
        </h2>
        <div className="response-opening">
          Em breve, você receberá um e-mail de confirmação.
        </div>

        {documents?.length ?
          <DocumentsSign
            total={documents.length}
            signer={signer}
            signerAccessCode={signerAccessCode}
          />
          :
          <CreateFreeAccount />
        }
      </Container>
    </Viewport>
  </>)
};

export default DocumentSuccess;
