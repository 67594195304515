import { useState } from 'react'
import { Api, clearSession } from '../../api/Assinafy'
import { Modal, Button } from '../../components'

const ModalPasswordSet = ({
  show,
  setModalPassword,
  user,
  setFormError
}) => {
  const [loading, setLoading] = useState(false)

  const handleSendPasswordReset = () => {
    setLoading(true)

    Api('authentication/requestPasswordReset')({ email: user.email })
      .then(({ status, data }) => {
        if (status === 200) {
          setFormError([<>
            Enviamos um e-mail para <b key="email" className="text-body">{user.email}</b> com um link e as instruções para você criar uma senha.
          </>])
          setModalPassword(false)
          setLoading(false)
          clearSession()
        }
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Modal.Alert show={show}
      centered
      onClose={() => setModalPassword(false)}
      title="Para continuar você precisa definir uma senha"
      description="Você usa o login social, por esse motivo não tem uma senha definida na nossa plataforma e para continuar você precisa definir uma senha"
    >
      <Modal.Footer>
        <Button variant="info" onClick={() => setModalPassword(false)}>
          CANCELAR
        </Button>
        <Button variant="primary"
          onClick={handleSendPasswordReset}
          loading={loading}
        >
          DEFINIR SENHA
        </Button>
      </Modal.Footer>
    </Modal.Alert>
  )
}

export default ModalPasswordSet;