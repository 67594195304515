import React, { forwardRef, useEffect } from 'react';
import classNames from 'classnames';

import './styles.scss';
import Spinner from '../Spinner';

const Button = forwardRef(({
  variant,
  size,
  as: Component = 'button',
  loading,
  loadingText,
  submit,
  ...props
}, ref) => {
  const { className, disabled } = props
  const prefix = 'btn';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, size && `${prefix}-${size}`, disabled && 'disabled', className);
  let type;

  if (Component === 'button') {
    type = submit ? 'submit' : 'button';
  }

  if (!loadingText) {
    loadingText = <>
      <Spinner className="me-1" />
      CARREGANDO
    </>
  }

  return (
    <Component {...props}
      type={type}
      className={newClassName}
      disabled={Component === 'button' && (loading || !!disabled)}
      ref={ref}
    >
      {loading ? loadingText : props.children}
    </Component>
  );
});

export default Button;
