import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Api, urlWithSignerAccessCode, downloadFile, cancelRequest } from '../../api/Assinafy';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { Container } from '../../layout';
import { SignNavbar } from '../../layout/Sign';
import PageCanvas from '../../components/PageCanvas';
import { Alert, Form, Input, Button, Icon, Viewport, Modal } from '../../components';
import ModalSignature from './Sign/ModalSignature';
import ModalDeny from './Sign/ModalDeny';
import { dateFormat } from '../../utils/Formats';
import { getFieldProps } from '../../utils/Fields';
import _ from 'lodash';

const DocumentSign = () => {
  const [document, setDocument] = useState({})
  const [items, setItems] = useState([])
  const [pages, setPages] = useState([])
  const [signedItems, setSignedItems] = useState([])
  const [signer, setSigner] = useState({})
  const [acceptedTerms, setAcceptedTerms] = useState()
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [modalCancel, setModalCancel] = useState()
  const [modalAlert, setModalAlert] = useState(false)
  const signatureChooserRef = useRef()
  const [, setSignatureImgUrl] = useState('none')
  const [signatureCaptured] = useState(false)
  const [, setNextItemToSign] = useState({})
  const [beginningToSign, setBeginningToSign] = useState(true)
  const [loading, setLoading] = useState(false)
  const [validated, setValidated] = useState(false)
  const [formError, setFormError] = useState([])
  const [isFilled, setIsFilled] = useState()
  const [errors, setErrors] = useState([])
  const { signerAccessCode } = useParams()
  const cancelToken = cancelRequest()
  const navigate = useNavigate()
  const location = useLocation()
  const { state } = location

  const isVirtualMethod = () => document.assignment?.method === 'virtual'

  const pendingFields = () => items.filter(item => !item.completed && item.field.is_visible)

  const hasPendingFields = () => pendingFields().length

  const navbarItems = useCallback(() => {
    const items = []

    if (!isVirtualMethod()) {
      items.push({
        children: <>
          <Icon id="icon-initial" className="me-1" size="20" />
          Minha assinatura
        </>,
        onClick: () => signatureChooserRef.current.show(true)
      })
    }

    items.push({
      children: <>
        <Icon id="icon-arrow_downward" className="me-1" size="20" />
        Baixar documento
      </>,
      onClick: () => downloadFile(urlWithSignerAccessCode(document.artifacts?.original, signerAccessCode))
    })

    if (hasPendingFields()) {
      items.push({
        children: <span className="text-danger">
          <Icon id="icon-close" className="me-1" size="20" />
          Negar assinatura
        </span>,
        onClick: () => {
          setModalCancel({
            description: `Ao recursar este documento todos os participantes serão informados e ninguém mais poderá assinar.`
          })
        }
      })

      return [...items]
    }

    return items
  }, [document])

  const createPages = useCallback((document) => {
    return document.pages
      .sort((a, b) => a.number - b.number)
      .map(page => {
        const fields = pendingFields().filter(field => {
          return field.page && field.page.id === page.id && field.field.is_visible
        })

        page.pageUrl = urlWithSignerAccessCode(page.download_url, signerAccessCode)

        return (<PageCanvas
          key={page.id}
          {...page}
          totalPages={document.pages.length}
          fields={fields}
          renderField={renderField}
          renderFieldMarker={(field, i) => {
            const { id, display_settings: ds } = field
            return (<label key={i} className="fieldmarker"
              htmlFor={`field-${id}`}
              style={{ top: ds.top + 5 }} // 5 = half of padding
            />)
          }}
        />)
      });
  }, [document])

  const handleChangeCheckboxTerms = useCallback((event) => {
    setSubmitDisabled(!event.target.checked)
  }, [acceptedTerms])

  const handleClickButtonTerms = useCallback(() => {
    Api('signer/acceptTerms')(signerAccessCode)
      .then(({ status }) => {
        if (status === 200) {
          setAcceptedTerms(true)
        }
      })
  }, [acceptedTerms])

  const isButtonField = (type) => {
    return type === 'signature' || type === 'initial' || type === 'signatureDate'
  }

  const renderField = useCallback((item, i) => {
    const { id, field, display_settings: ds } = item

    if (field.type === 'virtual') {
      return
    }

    const { type: fieldType, mask: fieldMask, maxLength, pattern } = getFieldProps(item.field.type)
    let label = ''
    let dataType = fieldType

    switch (field.type) {
      case 'signature':
        label = 'Clique para assinar';
        break;
      case 'initial':
        label = 'Clique para rubricar';
        break;
      case 'date':
        label = 'Data';
        break;
      default: label = `Insira o ${field.name}`
    }

    if (isButtonField(field.type)) {
      dataType = 'button'
    }

    return (<input key={i}
      id={`field-${id}`}
      className={`canvas-control canvas-control-${field.type}`}
      placeholder={label}
      readOnly={item.completed}
      maxLength={maxLength}
      defaultValue={item.completed ? item.value : undefined}
      data-type={dataType}
      type={fieldType === 'email' ? 'text' : fieldType}
      pattern={fieldType === 'email' ? '.*' : pattern}
      autocomplete={fieldType === 'email' ? 'email' : ''}
      required={field.is_required}
      onChange={(event) => {
        const value = fieldMask ? fieldMask(event) : event.target.value

        if (field.type === 'date') {
          item.value = value.split('/').reverse().join('-')
        } else {
          item.value = value
        }

        setFieldCompleted(event, item)
      }}
      onClick={(event) => {
        setFieldValue(event, item)
            
        if (beginningToSign) {
          setBeginningToSign(false)
        }
      }}
      onBlur={(event) => {
        if (!isButtonField(field.type)) {
          setFieldCompleted(event, item)
        }
      }}
      style={{
        borderColor: ds.color,
        backgroundColor: ds.backgroundColor,
        fontFamily: ds.fontFamily,
        fontSize: ds.fontSize,
        left: ds.left + 10,
        top: ds.top + 10,
        width: ds.width - 10
      }} />)
  }, [document])

  const setFieldValue = useCallback((event, item) => {
    const target = event.target
    const fieldType = item.field.type

    if (item.completed) {
      return
    }

    if (fieldType === 'signatureDate') {
      const now = new Date()
      target.value = dateFormat(now)
      item.value = now.toISOString()

      setFieldCompleted(event, item)
    } else if (fieldType === 'signature' || fieldType === 'initial') {
      digitallySignedField(event, item, fieldType)
    }
  }, [document, items])

  const setFieldCompleted = useCallback((event, item) => {
    if (event.target.checkValidity()) {
      if (event.target.dataset.type === 'button') {
        event.target.blur()
      }

      event.target.classList.add('completed')
      item.completed = true
    } else {
      item.completed = false
    }

    setSignedItems(prev => {
      const prevFilter = prev.filter(i => i.id !== item.id)

      if (item.completed) {
        return [...prevFilter, item]
      }

      return prevFilter
    })
  }, [document, items])

  const digitallySignedField = useCallback((event, item, signatureType = 'signature') => {
    const { display_settings: ds } = item

    Api('signer/signature')(signerAccessCode, signatureType)
      .then(({ data }) => {
        var reader = new window.FileReader();

        reader.readAsDataURL(data);
        reader.onload = () => {
          const canvasWrapper = event.target.closest('.canvas-wrapper')
          const img = new Image()

          img.src = reader.result
          img.classList.add('canvas-img')
          img.onload = function () {
            this.style.left = `${ds.left + (ds.width / 2) - (this.width / 2)}px`
            this.style.top = `${ds.top + ds.height - this.height + 15}px`
          };
          canvasWrapper.appendChild(img)

          event.target.value = item.signer.full_name
          item.value = item.signer.full_name

          setFieldCompleted(event, item)
        }
      })
      .catch(() => {
        setModalAlert({
          variant: 'warning',
          icon: 'icon-warning',
          title: 'Você ainda não criou sua assinatura',
          description: 'Crie no menu: Outras Opções > Minha Assinatura'
        })
      })
  }, [items])

  const goToNextField = useCallback(() => {
    if (beginningToSign) {
      setBeginningToSign(false)
    }

    if (isVirtualMethod()) {
      setLoading(true)

      Api('signer/signItems')(document.id, document.assignment.id, [], signerAccessCode)
        .then(() => {
          navigate(`/sign/${signerAccessCode}/success`, {
            state: { documentName: document.name }
          })
        })
    } else {
      const item = items.find(item => !item.completed && item.field.is_visible)
      const vp = window.document.querySelector('.viewport')

      setNextItemToSign(item)

      if (item) {
        const scale = vp.style.getPropertyValue('--canvas-scale')
        const fieldmarker = window.document.querySelector(`.fieldmarker[for="field-${item.id}"]`)
        const canvasWrapper = fieldmarker.closest('.canvas-wrapper')

        fieldmarker.click()

        vp.scrollTo(0, (canvasWrapper.offsetTop + fieldmarker.offsetTop - 200) * parseFloat(scale))
      } else if (signedItems.length) {
        setValidated(true)
        setLoading(true)
        setFormError([])

        const fieldsData = []
        const signedData = signedItems.map(item => {
          fieldsData.push({ field_id: item.field.id, value: item.value })

          return {
            itemId: item.id,
            fieldId: item.field.id,
            pageId: item.page.id,
            value: item.value
          }
        })

        Api('field/validateMultiple')(document.account_id, fieldsData, signerAccessCode)
          .then(({ status, data: { data } }) => {
            if (status === 200) {
              if (typeof data.find(v => !v.success) === 'undefined') {
                Api('signer/signItems')(document.id, document.assignment.id, signedData, signerAccessCode)
                  .then(() => {
                    navigate(`/sign/${signerAccessCode}/success`, {
                      state: { documentName: document.name }
                    })
                  })
              } else {
                setLoading(false)

                data.map(v => {
                  if (!v.success) {
                    setFormError((prevState) => {
                      return [
                        ...prevState,
                        `${signedItems.find(i => i.field.id === v.field_id).field.name} - ${v.error_message}`
                      ]
                    })
                  }
                })

                vp.scrollTo(0, 0)
              }
            } else {
              setLoading(false)
            }
          })
          .catch(({ response }) => {
            if (response) {
              setFormError([response.data.message])
            }

            setLoading(false)
          })
      }
    }
  }, [document, signedItems])

  const nextButtonLabel = useCallback(() => {
    if (isVirtualMethod()) {
      return 'ASSINAR DOCUMENTO'
    } else {
      if (beginningToSign) {
        return 'INICIAR'
      } else if (hasPendingFields()) {
        return 'SEGUINTE'
      }

      return 'CONCLUIR'
    }
  }, [document, beginningToSign])

  useEffect(() => {
    if (!state?.verified) {
      navigate(`/verify/${signerAccessCode}`)
      return;
    }

    if (typeof acceptedTerms === 'undefined') {
      Api('signer/self')(signerAccessCode)
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            setAcceptedTerms(data.has_accepted_terms)
          }
        })
        .catch(({ response }) => {
          if (response?.status === 403) {
            setAcceptedTerms(false)
          }
        })
    }

    if (acceptedTerms === true && _.isEmpty(document)) {
      Api('signer/viewDocument')(signerAccessCode, {
        ...cancelToken.config,
      })
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            window.pageCanvas = []

            setSigner({ ...data.current_signer, accessCode: signerAccessCode })

            // if (data.assignment.method === 'collect') {
            setItems(data.assignment.items)
            setNextItemToSign(data.assignment.items[0])
            // }

            if (data.is_closed || data.status !== 'pending_signature') {
              setErrors(['O documento não está mais disponível para assinatura.'])
            }

            setDocument(data)
          }
        })
        .catch(({ response }) => {
          if (response?.status === 403) {
            setAcceptedTerms(false)
          } else {
            setErrors(['O documento não está mais disponível para assinatura.'])
          }
        })
    }

    return () => {
      cancelToken.cancel()
    }
  }, [state, acceptedTerms])

  useEffect(() => {
    setIsFilled(!hasPendingFields())

    if (!pages.length && document.pages) {
      setPages(createPages(document))
    }
  }, [document])

  // useEffect(() => {
  //   if (items.length) {
  //     Array.from(items).forEach(displayFieldForSigner)
  //   }
  // }, [pages])

  useEffect(() => {
    if (signatureCaptured) {
      setSignatureImgUrl(Api('signer/signature')(signerAccessCode))
    }
  }, [signatureCaptured])

  if (errors.length) {
    return (<>
      <SignNavbar />
      <Viewport>
        <Alert show={errors.length} variant="warning">
          <b>Documento bloqueado</b>
          {errors.map((error) => error)}
        </Alert>
      </Viewport>
    </>)
  }

  if (acceptedTerms === false) {
    return (<div className="alert-terms">
      <Container>
        <div>
          <h3 className="h3">
            Termos de uso
          </h3>
          <p>
            Para continuar com a assinatura do documento, aceite os <Link to={'/'}>
              termos de uso
              <Icon id="icon-external" className="ms-1" size="10" />
            </Link>
          </p>
          <Form.Check>
            <Input.Checkbox name="terms" value="1" onChange={handleChangeCheckboxTerms} required />
            Eu aceito os <Link to={'/terms'}>
              termos de uso
              <Icon id="icon-external" className="ms-1" size="10" />
            </Link>
          </Form.Check>
        </div>
        <Button variant="primary" onClick={handleClickButtonTerms} disabled={submitDisabled}>
          VER DOCUMENTO
        </Button>
      </Container>
    </div>)
  }

  return (<>
    <SignNavbar
      setModalCancel={setModalCancel}
      items={navbarItems()}
      buttonNext={!isFilled && {
        onClick: goToNextField,
        children: nextButtonLabel(),
        loading
      }}
    >
      <div className="navbar-pending-actions">
        <PendingActions items={items} hasPendingFields={hasPendingFields} />
      </div>
    </SignNavbar>
    <Viewport className={formError.length ? 'has-errors' : ''}>
      <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
        {formError.map((error, i) => <div key={i}>{error}</div>)}
      </Alert>

      <form className={
        `viewport-content ${validated ? 'was-validated' : ''}`}
      >
        {pages}
        <div className="viewport-footer">
          <Icon id="icon-lock" size="14" />
          Ambiente seguro Assinafy
        </div>
      </form>
    </Viewport>
    {!isFilled && <div className="sign-footer">
      {!isVirtualMethod() &&
        <PendingActions items={items} hasPendingFields={hasPendingFields} />
      }
      <Button
        variant="primary"
        size="sm"
        onClick={goToNextField}
      >
        {nextButtonLabel()}
      </Button>
    </div>}
    {!isVirtualMethod() &&
      <ModalSignature ref={signatureChooserRef}
        signer={signer}
        signerAccessCode={signerAccessCode}
      />
    }
    <ModalDeny show={!!modalCancel}
      description={modalCancel?.description}
      params={{
        signerAccessCode,
        documentId: document.id,
        assignmentId: document.assignment?.id
      }}
      onClose={() => setModalCancel()}
    />
    <Modal.Alert show={modalAlert}
      align="center"
      variant={modalAlert.variant}
      icon={modalAlert.icon}
      title={modalAlert.title}
      description={modalAlert.description}
      onClose={() => setModalAlert(false)}
    >
      <Modal.Footer>
        <Button variant="primary" submit
          onClick={() => {
            setModalAlert(false)
            signatureChooserRef.current.show(true)
          }}
        >
          CRIAR ASSINATURA
        </Button>
      </Modal.Footer>
    </Modal.Alert>
  </>);
};

const PendingActions = ({ items, hasPendingFields }) => {
  return (<div>
    {!!items.length && <>
      {((itemsTotal) => {
        return (<>
          {itemsTotal
            ? 'Ações restantes'
            : 'Documento completo'
          }
          <span className={`actions-total${!itemsTotal ? ' checked' : ''}`}>
            {itemsTotal
              ? itemsTotal
              : <Icon id="icon-check" size="16" />
            }
          </span>
        </>)
      })(hasPendingFields())}
    </>
    }
  </div>)
}

export default DocumentSign;
