import React, { useEffect, useState } from 'react';
import { downloadFileBlob } from '../../api/Assinafy';
import { Spinner } from '../../components';

import './styles.scss';

/**
 * TODO:
 * - carregar as imagens em background de forma síncrona
 */

const PageCanvas = ({ fields, renderField, renderFieldMarker, totalPages, pageUrl, ...props }) => {
  const { id, number, width } = props
  const [background, setBackground] = useState('')
  const [loading, setLoading] = useState(true)

  const CANVAS_WIDTH = 732
  let CANVAS_SCALE = CANVAS_WIDTH / width

  const vp = document.querySelector('.viewport')
  const vpStyle = getComputedStyle(vp)
  const vpPadding = parseInt(vpStyle.paddingLeft) + parseInt(vpStyle.paddingRight)
  const vpc = vp.querySelector('.viewport-content')

  const media = (feature) => {
    return window.matchMedia === undefined || window.matchMedia(feature).matches
  }

  const setScale = () => {
    if (media('(max-width: 779px)')) {
      CANVAS_SCALE = (vp.offsetWidth - vpPadding) / width
    } else {
      CANVAS_SCALE = CANVAS_WIDTH / width
    }

    vp.style.setProperty('--canvas-width', `${CANVAS_WIDTH}px`);
    vp.style.setProperty('--canvas-scale', CANVAS_SCALE);

    vpc.style.height = '';
    vpc.style.height = (vp.clientHeight * CANVAS_SCALE) + 'px'
  }

  useEffect(() => {
    if (fields) {
      downloadFileBlob(pageUrl)
        .then(({ data }) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            setBackground(reader.result)
            setLoading(false)
          }
        })
    } else {
      const fabric = window['fabric'];
      const canvas = new fabric.Canvas(id);

      canvas.on('mousedown', e => {
        window.currentPage = e.target
      })

      canvas.allowTouchScrolling = true
      canvas.selection = false
      canvas.key = `canvas-${props.id}`
      canvas.page = {
        id: props.id,
        number: props.number
      }

      downloadFileBlob(pageUrl)
        .then(({ data }) => {
          var reader = new window.FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => {
            fabric.Image.fromURL(reader.result, (img) => {
              canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas))
              setLoading(false)
            })
          }
        })

      window.pageCanvas = [...window.pageCanvas, canvas]
    }

    window.addEventListener('resize', () => {
      setScale()
    })
    setScale()
  }, [pageUrl])

  return (<div className="canvas-wrapper">
    {fields
      ? <div className="canvas-container"
        style={{
          backgroundImage: `url(${background})`,
          height: props.height,
          width: props.width
        }}
      />
      : <canvas {...props} />
    }
    {fields && fields.map(renderFieldMarker)}
    {fields && fields.map(renderField)}
    <div className="canvas-page-number">
      {`Página ${number} de ${totalPages}`}
    </div>
    {loading && <Spinner/>}
  </div>);
}

export default PageCanvas;