import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Page } from '../../layout';
import { Icon } from '../../components';

import './styles.scss';

const ForgotPasswordSuccess = () => {
  const location = useLocation()
  const { state } = location

  return (
    <Page className="page-sign"
      title="Pronto!"
      opening={[
        'Enviamos um e-mail para ',
        <b key="email" className="text-body">{state?.email}</b>,
        ' com um link e as instruções para você criar uma nova senha.'
      ]}
      btnAccount={{
        title: 'Não possui conta?',
        btn: { url: '/signup', text: 'Criar conta' }
      }}
    >
      <Link to={'/'} className="btn btn-lg btn-block btn-primary">
        Voltar para o Login
        <Icon id="icon-arrow_forward" className="ms-2" size="24" />
      </Link>
    </Page>
  );
};

export default ForgotPasswordSuccess;
