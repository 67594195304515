import React from 'react';
import { Icon } from '../';

import './styles.scss';

const SignersEmpty = ({
  icon,
  title,
  content,
  btn
}) => {
  return (
    <div className="empty-list">
      <Icon id={icon} size="72" />
      {title && <h2 className="h1">
        {title}
      </h2>}
      {content}
    </div>
  )
}

export default SignersEmpty;