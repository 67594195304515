import React, { useEffect, useState } from 'react';
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { Api, clearSession } from '../../api/Assinafy'
import { useNavigate, useParams } from "react-router-dom";
import { Page } from '../../layout';
import { PageLoader, Alert, Button, Icon, Input } from '../../components';
import Form, { createInputChange, createInputBlur, createInputError } from '../../components/Form';
import Cookies from 'js-cookie';

import './styles.scss';

const Signup = () => {
  const navigate = useNavigate()
  const { invitation_id } = useParams()
  const [loading, setLoading] = useState(false)
  const [formError, setFormError] = useState([])
  const [formValidation, setFormValidation] = useState([])
  const [formData, setFormData] = useState({})
  const [invitationData, setInvitationData] = useState({})
  const [firstStep, setFirstStep] = useState(false)
  const [pageLoader, setPageLoader] = useState(false)
  const {mtc_id, mtc_sid} = Cookies.get()

  const handleSubmit = (event) => {
    event.preventDefault()

    setFormValidation([])

    if (event.target.checkValidity()) {
      // if (firstStep) {
        setLoading(true)

        let createUser;

        if (invitation_id) {
          createUser = Api('userInvitation/fulfill')(invitation_id, formData)
        } else {
          createUser = Api('user/create')(formData)
        }

        createUser
          .then(({ status }) => {
            if (status === 200) {
              navigate('/email-verification')
            }
          })
          .catch(({ response }) => {
            setFormError([response.data.message])
            setLoading(false)
          })
      // }

      // setFirstStep(true)
    } else {
      Array.from(event.target.elements).map((element) => {
        if (element.name) {
          setFormValidation((prevState) => [
            ...prevState,
            { name: element.name, message: element.validationMessage }
          ])
        }
      })
    }
  }

  const handleChange = createInputChange(formData, setFormData)

  const handleBlur = createInputBlur(formValidation, setFormValidation)

  const showInputError = createInputError(formValidation)

  const googleLoginSuccess = (token) => {
    setPageLoader(true)

    Api('socialLogin/login')({
      token,
      provider: 'google',
      has_accepted_terms: true
    })
      .then(({ status, data: { user: { is_password_set } } }) => {
        if (status === 200) {
          setPageLoader(false)

          navigate('/dashboard')
        }
      })
      .catch(({ response }) => {
        setFormError([response.data.message])
        setPageLoader(false)
      })
  }

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => googleLoginSuccess(access_token)
  })

  useGoogleOneTapLogin({
    onSuccess: ({ credential }) => googleLoginSuccess(credential)
  })

  const searchParams = () => window.location.search?.slice(1)
    .split('&').map(p => p.split('='))
    .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  useEffect(() => {
    clearSession();

    setFormData({
      ...formData,
      'utm_params': Object.assign({}, searchParams(), {mtc_id, mtc_sid})
    });
    if (invitation_id) {
      Api('userInvitation/view')(invitation_id)
        .then(({ status, data: { data } }) => {
          if (status === 200) {
            setInvitationData(data)

            if (data.invited_user_id) {
              Api('userInvitation/fulfill')(invitation_id)
                .then(({ status }) => {
                  if (status === 200) {
                    navigate('/')
                  }
                })
                .catch(({ response }) => {
                  setFormError([response.data.message])
                  setLoading(false)
                })
            }

            setFormData({
              ...formData,
              ['email']: data.email
            })
          }
        })
        .catch(({ response }) => {
          setFormError([response.data.message])
        })
    }
  }, [invitation_id])

  return (<>
    {pageLoader && <PageLoader>
      Criando conta...
    </PageLoader>}
    <Page className="page-sign"
      title={!firstStep
        ? 'Criar conta na Assinafy'
        : 'Configurando sua conta na Assinafy'
      }
      opening={!firstStep
        ? 'Informe os dados abaixo para criar uma conta na Assinafy.'
        : 'Complete seu perfil na Assinafy para continuar'
      }
      btnAccount={{
        title: 'Já possui uma conta?',
        btn: { url: '/', text: 'Acessar' }
      }}
    >
      <Form id="form-signup" className="form-sign"
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Alert show={formError.length} variant="warning" onClose={() => setFormError([])}>
          {formError.map((error) => error)}
        </Alert>

        <Form.Group className="form-group-social">
          <Button
            size="lg" className="btn-block" variant="google"
            onClick={() => googleLogin()}
          >
            <Icon id="icon-google" className="me-2" size="24" />
            Criar conta com o Google
          </Button>
        </Form.Group>

        <Form.Group>
          <Input size="lg" name="name" label="Nome" required
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {showInputError('name')}
        </Form.Group>

        <Form.Group>
          <Input.Email size="lg" name="email" label="E-mail" required
            defaultValue={invitationData?.email}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={invitationData?.email}
            autoComplete="username"
          />
          {showInputError('email')}
        </Form.Group>

        {/*
        {firstStep && <>
          <Form.Group>
            <Input.Cpf size="lg" name="government_id" label="CPF"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {showInputError('government_id')}
          </Form.Group>

          <Form.Group>
            <Input.Phone size="lg" name="telephone" label="Telefone"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {showInputError('telephone')}
          </Form.Group>
        </>}
        */}

        {!firstStep &&
          <Form.Group>
            <Input.Password size="lg" name="password" label="Senha" required
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="new-password"
            />
            <Form.Text>
              A senha deve conter pelo menos 8 caracteres.
            </Form.Text>
            {showInputError('password')}
          </Form.Group>
        }

        <Form.Group>
          <Form.Check>
            <Input.Checkbox name="terms" value="1" required
              onChange={handleChange}
              onBlur={handleBlur}
            />
            Li e aceito os <a href="https://www.assinafy.com.br/termos-de-uso" target="_blank" rel="noreferrer">Termos de Uso</a> e a <a href="https://www.assinafy.com.br/politica-de-privacidade" target="_blank" rel="noreferrer">Política de Privacidade</a> da Assinafy
          </Form.Check>
          {showInputError('terms')}
        </Form.Group>

        <Form.Footer>
          <Button variant="primary" size="lg" submit loading={loading}>
            Criar conta
            <Icon id="icon-arrow_forward" className="ms-2" size="24" />
          </Button>
        </Form.Footer>
      </Form>
    </Page>
  </>);
};

export default Signup;
