import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { Api, urlWithSignerAccessCode, cancelRequest } from '../../api/Assinafy'
import { Table, Image, Form, Input, Button, Icon, Dropdown, Viewport, Progress } from '../../components'
import { Container } from '../../layout'
import { SignNavbar } from '../../layout/Sign'
import ModalViewer from '../../modules/Document/View/ModalViewer';
import ModalDeny from '../Document/Sign/ModalDeny';
import { dateFormat } from '../../utils/Formats'
import _ from 'lodash'

import thumbDocument from '../../assets/images/thumb-document.svg'

import './styles.scss'

const DocumentsSign = () => {
  const [, setLayoutClassName] = useOutletContext()
  const [signer, setSigner] = useState({})
  const [documents, setDocuments] = useState()
  const [documentsSelected, setDocumentsSelected] = useState([])
  const [documentsSent, setDocumentsSent] = useState(0)
  const [progress, setProgress] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const [modalViewer, setModalViewer] = useState(false)
  const [modalDeny, setModalDeny] = useState()
  const { signerAccessCode } = useParams()
  const cancelToken = cancelRequest()
  const navigate = useNavigate()

  const handleCheckboxChangeAll = () => {
    if (documentsSelected.length) {
      setDocumentsSelected([])
    } else {
      setDocumentsSelected(documents)
    }
  }

  const handleCheckboxChange = (document) => {
    if (documentsSelected.includes(document)) {
      setDocumentsSelected(documentsSelected.filter(d => d.id !== document.id))
    } else {
      setDocumentsSelected([...documentsSelected, document])
    }
  }

  const getDocuments = (signer, then) => {
    Api('signer/documents')(signer.id, {
      params: {
        'per-page': 999,
        'signer-access-code': signerAccessCode,
        status: 'pending_signature'
      },
      ...cancelToken.config
    })
      .then(({ status, data: { data } }) => {
        setDocuments([])

        if (status === 200) {
          data.map((document) => {
            setDocuments((prevState) => {
              if (
                document.assignment.method === 'virtual' &&
                !document.assignment.summary.signers.find(s => s.id === signer.id).completed
              ) {
                return [...prevState, document]
              }
              return prevState
            })
          })

          if (then) {
            then(data)
          }
        }
      })
  }

  const handleModalDenyComplete = () => {
    navigate(`/sign/${signerAccessCode}/closed`, {
      state: { documentId: modalDeny.documentId }
    })
  }

  const assignDocument = (documentsToSend = documentsSelected) => {
    const delayWait = 1000
    const delayWaitSend = delayWait * 2

    const progressDefault = () => {
      setProgressValue((prevState) => {
        if (prevState < 100) {
          _.delay(progressDefault, delayWait)

          return prevState + 5
        }

        return prevState
      })
    }

    const progressSend = () => {
      getDocuments(signer, (data) => {
        setProgressValue((prevState) => {
          if (prevState < 100) {
            _.delay(progressSend, delayWaitSend)

            const documentsSignCompleted = data.filter((document) => {
              return documentsToSend.find(d => d.id === document.id) &&
                document.assignment.summary.signers.find(s => s.id === signer.id).completed
            }).length
            setDocumentsSent(documentsSignCompleted)
            const documentsProgress = Math.ceil(documentsSignCompleted / documentsToSend.length * 100)

            if (documentsProgress > prevState) {
              return documentsProgress
            }

            return prevState
          }

          return prevState
        })
      })
    }

    setProgress(true)

    _.delay(progressDefault, delayWait)
    _.delay(progressSend, delayWaitSend)

    const documentIds = documentsToSend.map(d => d.id)

    Api('signer/signMultiple')(documentIds, signerAccessCode)
      .then(({ status }) => {
        if (status === 200) {
          const documentNames = documentsToSend.map(d => d.name)

          _.delay(() => {
            navigate(`/sign/${signerAccessCode}/success`, {
              state: { documentName: documentNames }
            })
          }, 5000)
        }
      })
  }

  const assignDocumentSingle = (document) => {
    setDocumentsSelected(() => {
      const documents = [document]
      assignDocument(documents)
      return documents
    })
  }

  const signNavbarContent = () => {
    return (<>
      <span className="text-secondary d-none d-lg-block">{signer.email}</span>
    </>)
  }

  useEffect(() => {
    setLayoutClassName('sign-documents')

    Api('signer/self')(signerAccessCode)
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setSigner(data)
        }
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  useEffect(() => {
    if (!Object.keys(signer).length) {
      return
    }

    getDocuments(signer)
  }, [signer])

  if (progress) {
    return (<>
      <SignNavbar toggler={false}>
        {signNavbarContent()}
      </SignNavbar>
      <Viewport>
        <div className="sign-loading">
          <Progress size="100" strokeWidth="4" value={progressValue} />
          Registrando assinatura do documento {documentsSent} de {documentsSelected.length}. Aguarde.
        </div>
      </Viewport>
    </>)
  }

  return (<>
    <SignNavbar toggler={false}>
      {signNavbarContent()}
    </SignNavbar>
    <Viewport>
      <Container>
        <h1 className="h1">
          Aguardando assinatura
        </h1>
        <Table
          rows={documents}
          className="table-bordered table-hover"
          header={[
            {
              name: <Form.Check>
                <Input.Checkbox
                  onChange={handleCheckboxChangeAll}
                  checked={documentsSelected.length}
                  className={(documentsSelected.length < documents?.length) ? 'has-checked' : ''}
                />
              </Form.Check>
            },
            { name: 'Nome' },
            { name: 'Enviado por' },
            { name: 'Validade' },
            { name: '' }
          ]}
          renderRow={(row, i) => <tr key={i}>
            <td>
              <Form.Check>
                <Input.Checkbox
                  onChange={() => handleCheckboxChange(row)}
                  checked={documentsSelected.includes(row)}
                />
              </Form.Check>
            </td>
            <td>
              <div
                className="document-header"
                onClick={() => handleCheckboxChange(row)}
              >
                <Image
                  width="40"
                  height="56"
                  src={row.artifacts?.thumbnail && urlWithSignerAccessCode(row.artifacts.thumbnail, signerAccessCode)}
                  placeholder={thumbDocument}
                />
                <div className="document-title">{row.name}</div>
                <span>
                  Assinar como testemunha <span className="d-lg-none">
                    {row.assignment?.expires_at && `até ${dateFormat(row.assignment.expires_at)}`}
                  </span>
                </span>
                <span className="d-lg-none">
                  Enviado por: {row.assignment?.sender_email}
                </span>
              </div>
            </td>
            <td className="d-none d-sm-table-cell">
              {row.assignment?.sender_email}
            </td>
            <td className="d-none d-lg-table-cell">
              {row.assignment?.expires_at ?
                dateFormat(row.assignment.expires_at)
                :
                '-'
              }
            </td>
            <td className="col-action">
              <TableActions
                signer={signer}
                document={row}
                signerAccessCode={signerAccessCode}
                assignDocumentSingle={assignDocumentSingle}
                setModalViewer={setModalViewer}
                setModalDeny={setModalDeny}
              />
            </td>
          </tr>}
        />
      </Container>
      <div className={`documents-selected${documentsSelected?.length ? ' show' : ''}`}>
        <h2 className="h3">
          {documentsSelected.length} documentos selecionados
        </h2>
        <div>
          <Dropdown
            align="right"
            variant="dark"
            button={<>
              OUTRAS AÇÕES
              <Icon id="icon-arrow_down" size="16" />
            </>}
          >
            <Dropdown.Item
              as={Button}
              className="text-danger"
              onClick={() => setModalDeny({
                description: 'Ao recursar os documentos selecionados todos os participantes serão informados e ninguém mais poderá assinar.',
                documentId: documentsSelected.map(d => d.id),
                assignmentId: document.assignment?.id
              })}
            >
              <Icon id="icon-block" size="16" />
              NEGAR ASSINATURA(S)
            </Dropdown.Item>
          </Dropdown>
          <Button variant="primary"
            onClick={() => assignDocument()}
          >
            ASSINAR
          </Button>
        </div>
      </div>
    </Viewport>
    <ModalViewer show={!!modalViewer}
      document={modalViewer}
      onClose={() => setModalViewer(false)}
    />
    <ModalDeny show={!!modalDeny}
      description={modalDeny?.description}
      params={{
        signerAccessCode,
        documentId: modalDeny?.documentId,
        assignmentId: modalDeny?.assignmentId
      }}
      onComplete={handleModalDenyComplete}
      onClose={() => setModalDeny()}
    />
  </>)
}

const TableActions = ({
  signer,
  document,
  signerAccessCode,
  assignDocumentSingle,
  setModalViewer,
  setModalDeny
}) => {
  const [loading, setLoading] = useState(false)
  const dropdownRef = useRef()

  const downloadDocument = (i, type) => {
    setLoading(i)

    Api('signer/downloadDocument')(signer.id, document.id, type, signerAccessCode)
      .then(({ status, data }) => {
        if (status === 200) {
          const d = window.document
          const a = d.createElement('a')
          const blob = window.URL.createObjectURL(new Blob([data], {
            type: 'application/pdf'
          }))
          a.href = blob
          a.setAttribute('download', '')
          d.body.append(a)
          a.click()
          a.remove()
          window.URL.revokeObjectURL(blob)
        }
      })
  }

  const actionsButtons = [
    {
      id: 'download',
      icon: 'icon-arrow_downward',
      text: 'BAIXAR DOCUMENTO',
      onClick: (i) => downloadDocument(i, 'original')
    },
    {
      id: 'denied',
      icon: 'icon-block',
      text: 'NEGAR ASSINATURA',
      className: 'text-danger',
      onClick: (i) => {
        setModalDeny({
          description: 'Ao recursar este documento todos os participantes serão informados e ninguém mais poderá assinar.',
          documentId: [document.id],
          assignmentId: document.assignment?.id
        })
      }
    }
  ]

  return (<>
    <div className="table-actions">
      <div className="btn-group d-none d-xl-flex">
        <Button variant="light"
          as={Button}
          onClick={() => setModalViewer(document)}
        >
          <Icon id="icon-visibility" size="18" className="me-1" />
          VISUALIZAR
        </Button>
        <Button variant="info"
          as={Button}
          onClick={() => assignDocumentSingle(document)}
        >
          <Icon id="icon-signature" size="18" className="me-1" />
          ASSINAR
        </Button>
      </div>
      <Dropdown
        ref={dropdownRef}
        align="right"
        variant="action"
        button={<Icon id="icon-more_vert" />}
      >
        {actionsButtons.map(({ id, icon, text, className, onClick }, i) =>
          <Dropdown.Item key={i}
            as={Button}
            onClick={() => onClick(id)}
            loading={loading === id}
            className={className}
          >
            <Icon id={icon} className="me-1" size="20" />
            {text}
          </Dropdown.Item>
        )}
      </Dropdown>
    </div>
  </>)
}

export default DocumentsSign