import React from 'react';
import { useNavigate } from 'react-router-dom';
import { selectAccount } from '../../api/Assinafy';
import { Dropdown, Avatar, Icon } from '../';

const DropdownUser = ({
  session,
  onClick
}) => {
  const navigate = useNavigate()
  const { user, accounts } = session
  const currentAccount = accounts[0]

  const selectAccountError = (message) => {
    alert(message)
  }

  return (<Dropdown
    align="right"
    className="dropdown-user"
    button={<>
      <div className="dropdown-user-name">
        {user.name}
        {currentAccount &&
          <div className="dropdown-user-account">
            {currentAccount?.name}
          </div>
        }
      </div>
      <Avatar placeholder={user.name[0] || user.email[0]} />
    </>}
    onClick={onClick}
    variant="icon"
  >
    <Dropdown.Item to={'/dashboard/account'}>
      <Icon id="icon-tune" className="me-1" size="20" />
      Configurações
    </Dropdown.Item>
    {/* <Dropdown.Item to={'#'}>
      <Icon id="icon-person" className="me-1" size="20" />
      Pagamentos
    </Dropdown.Item> */}
    <Dropdown.Item as="div" className="p-0"
    >
      <div className="dropdown-link"
        onClick={() => window.location.href = '/dashboard/account/workspaces'}
      >
        <Icon id="icon-workspace" className="me-1" size="20" />
        Workspaces
      </div>
      <Dropdown.Menu>
        {!!accounts?.length && accounts.map(({ id, name }) =>
          <Dropdown.Item key={id} as="button" onClick={() => selectAccount(id, () => navigate(0), selectAccountError)}>
            <Icon id="icon-workspace" className="me-1" size="20" />
            {name}
          </Dropdown.Item>
        )}
        <Dropdown.Item to={'/dashboard/account/workspaces'}>
          <Icon id="icon-add" className="me-1" size="20" />
          Criar novo workspace
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown.Item>
    <Dropdown.Item to={'/logout'}>
      <Icon id="icon-exit" className="me-1" size="20" />
      Sair
    </Dropdown.Item>
  </Dropdown>)
}

export default DropdownUser;