import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Container = ({ fluid, ...props }) => {
  const prefix = 'container';
  const suffix = typeof fluid === 'string' ? `-${fluid}` : '-fluid';
  const newClassName = classNames(fluid ? `${prefix}${suffix}` : prefix);

  return (
    <div {...props} className={newClassName} />
  );
};

export default Container;
