import React from 'react';
import { Api } from '../../api/Assinafy'
import { Link, useNavigate } from 'react-router-dom';
import { Page } from '../../layout';
import { Icon, Input, Form } from '../../components';

import './styles.scss';

const ForgotPassword = () => {
  const navigate = useNavigate()

  return (
    <Page className="page-sign"
      title="Esqueceu sua senha?"
      opening="Informe o e-mail cadastrado e receba um link para redefinir sua senha."
      btnAccount={{
        title: 'Não possui conta?',
        btn: { url: '/signup', text: 'Criar conta' }
      }}
    >
      <Form id="form-password-forgot" className="form-sign"
        onSubmit={(formData) => Api('authentication/requestPasswordReset')(formData)
          .then(({ status }) => {
            if (status === 200) {
              navigate('/forgot-password/success', {
                state: formData
              })
            }
          })
        }
        size="lg"
        controls={[
          { Component: Input.Email, name: 'email', label: 'E-mail', required: true },
        ]}
        buttons={[
          { variant: 'primary', submit: true, children: <>
            Enviar
            <Icon id="icon-arrow_forward" className="ms-2" size="24" />
          </> },
          { variant: 'link', as: Link, to: '/', children: 'Voltar para o Login' }
        ]}
      />
    </Page>
  );
};

export default ForgotPassword;
