import React from 'react';
import { Spinner, Card } from '../';

import './styles.scss';

const PageLoader = ({ children, ...props }) => {
  return (
    <div {...props} className="page-loader">
      <Card>
        <Spinner />
        {children}
      </Card>
    </div>
  )
}

export default PageLoader;