import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Section = (props) => {
  const { className } = props
  const prefix = 'section';
  const newClassName = classNames(prefix, className);

  return (
    <div {...props} className={newClassName} />
  );
};

const SectionTitle = ({ as: Component = 'h2', ...props }) => {
  const { className } = props
  const newClassName = classNames('section-title', className);

  return (
    <Component {...props} className={newClassName} />
  );
};

const SectionOpening = (props) => {
  return (
    <div {...props} className="section-opening" />
  );
};

const SectionHeader = ({ title, titleAs, opening, ...props }) => {
  const { children } = props

  return (
    <div {...props} className="section-header">
      <div>
        {title &&
          <SectionTitle children={title} as={titleAs} />
        }
        {opening &&
          <SectionOpening children={opening} />
        }
      </div>
      {children}
    </div>
  );
};

const SectionBody = (props) => {
  return (
    <div {...props} className="section-body" />
  );
};

Section.Header = SectionHeader;
Section.Title = SectionTitle;
Section.Opening = SectionOpening;
Section.Body = SectionBody;

export default Section;
