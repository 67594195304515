import React, { forwardRef, useState, useImperativeHandle } from 'react';
import Spinner from '../Spinner';
import classNames from 'classnames';

import './styles.scss';
import { delay } from 'lodash';

const DataList = forwardRef(({ items, renderItem, msgNotFound, ...props }, ref) => {
  const [show, setShow] = useState(false)
  const { className } = props
  const prefix = 'datalist';
  const newClassName = classNames(prefix, show && 'show', className)

  if (!msgNotFound) {
    msgNotFound = 'No items found';
  }

  useImperativeHandle(ref, () => ({
    show: () => setShow(true),
    hide: (wait = 0) => delay(() => setShow(false), wait)
  }))

  if (!renderItem) {
    renderItem = (item, i) => i
  }

  return (<div className={newClassName}>
    {items ?
      items.length ?
        items.map(renderItem)
        :
        msgNotFound
      :
      <>
        <Spinner className="me-1" />
        Carregando...
      </>
    }
  </div>);
});

export default DataList;