import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Api, getSession, cancelRequest } from '../../../api/Assinafy';
import { Dashboard, Section } from '../../../layout';
import { Button, Icon, Dropdown, Table, Alert, Toast } from '../../../components';
import ModalRemove from '../../Fields/ModalRemove';
import ModalError from '../../Fields/ModalError';

const FieldsTab = () => {
  const [fields, setFields] = useState()
  const [fieldTypes, setFieldTypes] = useState([])
  const [errors, setErrors] = useState([])
  const [toast, setToast] = useState('')
  const [modalRemove, setModalRemove] = useState(false)
  const [modalError, setModalError] = useState(false)
  const cancelToken = cancelRequest()
  const currentAccount = getSession().accounts[0]
  const { state } = useLocation()

  const handleFetchFields = () => {
    Api('field/index')(currentAccount.id, {
      ...cancelToken.config
    })
      .then(({ status, data: { data } }) => {
        if (status === 200) {
          setFields(data)
        }
      })
      .catch(({ response }) => {
        if (response) {
          setErrors([response.data.message])
        }
      })
  }

  const handleRemove = (fieldId) => {
    Api('field/delete')(currentAccount.id, fieldId)
      .then(({ status }) => {
        if (status === 200) {
          handleFetchFields()
          setToast('Campo removido com sucesso')
          setModalRemove(false)
        }
      })
      .catch(({ response: { data } }) => {
        setModalRemove(false)
        setModalError(data.message)
      })
  }

  useEffect(() => {
    if (!currentAccount) {
      setErrors(['Você precisa estar em um workspace'])
      return
    }

    handleFetchFields()

    Api('field/fieldTypesIndex')({
      ...cancelToken.config,
    })
      .then(({ data: { data } }) => {
        data.map(({ type, name }) => {
          setFieldTypes((prevState) => [
            ...prevState,
            { label: name, value: type }
          ])
        })
      })

    return () => {
      cancelToken.cancel()
    }
  }, [])

  useEffect(() => {
    if (state?.toast) {
      setToast(state.toast)
      window.history.replaceState({}, document.title)
    }
  }, [state])

  return (<Section>
    <Alert show={errors.length} variant="danger">
      {errors.map((error) => error)}
    </Alert>

    {!errors.length && <>
      <Section.Header
        title="Campos cadastrados"
        titleAs="h3"
        opening="Abaixo, você encontra a lista com todos os campos cadastrados que estão disponíveis para uso."
      >
        <Dashboard.FixedBottom>
          {Api('field/create') && <>
            <Button variant="primary" as={Link} to={'/dashboard/fields/new'}>
              <Icon id="icon-add" className="me-1" size="16" />
              NOVO CAMPO
            </Button>
            <Button variant="primary" as={Link} to={'/dashboard/fields/new'}
              className="btn-scroll-top"
            >
              <Icon id="icon-add" />
            </Button>
          </>}
        </Dashboard.FixedBottom>
      </Section.Header>

      <Table className="table-bordered table-hover"
        header={[
          { name: 'Nome' },
          { name: 'Tipo do campo' },
          { name: 'Obrigatório' },
          { name: '' }
        ]}
        rows={fields}
        renderRow={(field) => {
          return (<tr key={field.id}>
            <td>
              <Link to={`/dashboard/fields/${field.id}/view`} className="field-header">
                <Icon id={`icon-${field.type}`} />
                <div className="field-title">{field.name}</div>
              </Link>
            </td>
            <td className="d-none d-lg-table-cell">
              {fieldTypes.find((type) => type.value === field.type).label}
            </td>
            <td>
              {field.is_required ? 'Sim' : 'Não'}
            </td>
            <td className="col-action">
              <TableActions
                field={field}
                setModalRemove={setModalRemove}
              />
            </td>
          </tr>)
        }}
      />

      <ModalRemove
        modalRemove={modalRemove}
        setModalRemove={setModalRemove}
        handleRemove={() => handleRemove(modalRemove)}
      />
      <ModalError
        modalError={modalError}
        setModalError={setModalError}
      />
    </>}

    <Toast show={toast} variant="success" onClose={() => setToast('')}>
      {toast}
    </Toast>
  </Section>);
}

const TableActions = ({ field, setModalRemove }) => {
  const navigate = useNavigate()
  const dropdownRef = useRef()

  const actionsButtons = [
    {
      id: 'view',
      icon: 'icon-visibility',
      text: 'VISUALIZAR',
      onClick: () => navigate(`/dashboard/fields/${field.id}/view`)
    },
    Api('field/update') && {
      id: 'edit',
      icon: 'icon-edit',
      text: 'EDITAR',
      onClick: () => navigate(`/dashboard/fields/${field.id}/edit`)
    },
    Api('field/delete') && {
      icon: 'icon-trash',
      text: 'EXCLUIR',
      onClick: () => setModalRemove(field.id)
    }
  ]

  return (<div className="table-actions">
    <Dropdown
      ref={dropdownRef}
      align="right"
      variant="action"
      button={<Icon id="icon-more_vert" />}
    >
      {actionsButtons.map(({ icon, text, onClick }, i) => {
        if (!text) {
          return;
        }

        return (<Dropdown.Item key={i}
          as={Button}
          onClick={onClick}
        >
          <Icon id={icon} className="me-1" size="20" />
          {text}
        </Dropdown.Item>)
      })}
    </Dropdown>
  </div>)
}

export default FieldsTab;