import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSession, urlWithAccessToken, urlWithSignerAccessCode } from '../../../api/Assinafy'
import { Image, Button, Icon, Modal, Spinner } from '../../../components';

const ModalViewer = ({ document, onClose, ...props }) => {
  const [pages, setPages] = useState([])
  const [pagesLoaded, setPagesLoaded] = useState(0)
  const { signerAccessCode } = useParams()

  useEffect(() => {
    if (document.pages) {
      setPages(document.pages.sort((a, b) => a.number - b.number))
    }
  }, [document])

  useEffect(() => {
    if (!props.show) {
      return
    }
  }, [pagesLoaded])

  return (
    <Modal {...props} onHide={onClose} className="modal-viewer" centered>
      <Modal.Header>
        <Button variant="icon" className="btn-close"
          onClick={onClose}
        >
          <Icon id="icon-close" size="24" />
        </Button>
        <Modal.Title as={'div'} className="h2">
          {document.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(pagesLoaded < pages.length) && <Spinner />}
        {!!pages.length && pages.map(page => {
          return (<Image
            key={page.id}
            className="document-page"
            src={signerAccessCode ? urlWithSignerAccessCode(page.download_url, signerAccessCode) : urlWithAccessToken(page.download_url)}
            onLoad={() => setPagesLoaded(pagesLoaded + 1)}
            style={{ display: pagesLoaded < pages.length ? 'none' : 'block' }}
          />)
        })}
      </Modal.Body>
    </Modal>
  )
};

export default ModalViewer;