import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../../components';
import './styles.scss';

const DocumentsSign = ({
  total,
  signer,
  signerAccessCode
}) => {
  return (
    <div className="documents-sign">
      <h3 className="h2">
        {signer.full_name}, você tem <span>{String(total).padStart(2, '0')} documentos</span> aguardando para serem assinados.
      </h3>
      <Button variant="primary"
        as={Link}
        to={`/sign/${signerAccessCode}/documents`}
      >
        VER DOCUMENTOS
      </Button>
    </div>
  )
}

export default DocumentsSign;