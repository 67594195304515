import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const Badge = ({ variant, as: Component = 'span', ...props }) => {
  const { className, children } = props
  const prefix = 'badge';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, className);

  return (
    <Component {...props} className={newClassName}>
      {children}
    </Component>
  );
};

const List = (props) => {
  const { items } = props

  return (
    <div className="badge-list">
      {items.map((item, i) =>
        <Badge key={i} {...item} />
      )}
    </div>
  )
}

Badge.List = List;

export default Badge;
