import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { Button, Icon } from '../';

import './styles.scss';

const ModalAlert = ({
  show,
  variant,
  align,
  icon,
  title,
  description,
  onClose,
  ...props
}) => {
  const { children, className } = props
  const prefix = 'modal';
  const newClassName = classNames(`${prefix}-alert`, variant && `${prefix}-${variant}`, align && `text-${align}`, className);

  return (<Modal show={show}
    className={newClassName}
    centered
    onHide={onClose}
  >
    <Modal.Header>
      {onClose && <Button variant="icon" className="btn-close"
        onClick={onClose}
      >
        <Icon id="icon-close" size="24" />
      </Button>}
      {icon &&
        <Icon id={icon} className="modal-icon" size="72" />
      }
      <Modal.Title as={'div'} className="h2">
        {title}
      </Modal.Title>
      <div>
        {description}
      </div>
    </Modal.Header>
    {children}
  </Modal>)
}

Modal.Alert = ModalAlert;

export default Modal;