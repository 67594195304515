import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, EmptyList } from '../../components'

const DocumentsEmpty = () => {
  return (
    <EmptyList
      title="Esse contato não possui documentos"
      content={<>
        <p>
          Adicione o primeiro documento e envie para assinatura.
        </p>
        <Button as={Link} to={'/dashboard/documents'} variant="primary">
          <Icon id="icon-upload_file" className="me-1" size="18" />
          NOVO DOCUMENTO
        </Button>
      </>}
    />
  )
}

export default DocumentsEmpty;