import { useEffect } from 'react'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory()

const Prompt = ({when, message}) => {
    useEffect(() => {
        if (!when) {
            return;
        }

        let unblock = browserHistory.block((tx) => {
            if (window.confirm(message)) {
                unblock()
                tx.retry()
            }
        })

        return () => {
            unblock()
        }
    }, [when])

    return (<></>)
}

export {
    browserHistory, Prompt
}