import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';

import './styles.scss';

const Alert = ({ show, variant, dismissible, ...props }) => {
  if (typeof variant === 'undefined') {
    variant = 'info'
  }

  const { className, children, onClose } = props
  const prefix = 'alert';
  const newClassName = classNames(prefix, variant && `${prefix}-${variant}`, className);

  if (typeof show === 'undefined') {
    show = true
  }

  if (!show) {
    return (<></>)
  }

  return (
    <div {...props} className={newClassName}>
      {variant && <Icon id={`icon-${variant}`} size="28" />}
      <div className={`${prefix}-body`}>
        {children}
      </div>
      {onClose && <button type="button" className="btn" onClick={(e) => onClose(false, e)}>
        Fechar
      </button>}
    </div>
  );
};

export default Alert;