import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { GoogleOAuthProvider } from '@react-oauth/google'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import './App.scss'

const env = process.env.REACT_APP_ENVIRONMENT

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

if (!isLocalhost) {
  Sentry.init({
    dsn: 'https://8082a56dca71496e80e3daeed9401ae1@logger.febacapital.com/108',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0
  })
}

let GoogleClientId = '405902786938-sm6q3s2a8ohbbqppncbg9n7ckfspnrs0.apps.googleusercontent.com'
if (env === 'production') {
  GoogleClientId = '451389403494-ara3pgaeim5evbqqjks1hp341cavaggh.apps.googleusercontent.com'
}

ReactDOM.render((
  <GoogleOAuthProvider clientId={GoogleClientId}>
    <App />
  </GoogleOAuthProvider>
), document.getElementById('root'))