import React from 'react';
import { Button, Icon, Modal } from '../../components';

const ModalRemove = ({ modalRemove, setModalRemove, handleRemove }) => {
  return (
    <Modal show={modalRemove}
      centered
    >
      <Modal.Header>
        <Button variant="icon" className="btn-close"
          onClick={() => setModalRemove(false)}
        >
          <Icon id="icon-close" size="24" />
        </Button>
        <Modal.Title as={'div'} className="h2">
          Removendo o campo {modalRemove.name}
        </Modal.Title>
        Deseja realmente remover esse campo? Você pode editá-lo.
      </Modal.Header>
      <Modal.Footer>
        <Button variant="info"
          onClick={() => setModalRemove(false)}
        >
          CANCELAR
        </Button>
        <Button variant="danger" submit
          onClick={handleRemove}
        >
          <Icon id="icon-trash" className="me-1" size="16" />
          REMOVER
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalRemove;