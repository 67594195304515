import React from 'react'
import { Outlet } from 'react-router-dom'

const PageRoute = () => {
  return (
    <Outlet />
  )
};

export default PageRoute;
