import React, { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Dashboard } from '../../layout'
import { Tabs } from '../../components';
import BrandTab from './Tabs/BrandTab';
import FieldsTab from './Tabs/FieldsTab';
import TeamTab from './Tabs/TeamTab';
import PlanTab from './Tabs/PlanTab';
import WebhooksTab from './Tabs/WebhooksTab';

import './styles.scss';

const Workspace = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const defaultTab = 'brand'

  const handleSelect = (k) => {
    navigate(`/dashboard/workspace/${k}`)
  }

  useEffect(() => {
    if (!params.tab) {
      window.history.replaceState({}, '', `${location.pathname}/${defaultTab}`)
    }
  }, [location, params])

  return (<Dashboard.Content>
    <Dashboard.Header
      title="Personalizar workspace"
    />
    <Tabs
      activeKey={params.tab}
      onSelect={handleSelect}
    >
      <Tabs.Tab eventKey="brand" title="Sua marca">
        {(!params.tab || params.tab === defaultTab) && <BrandTab />}
      </Tabs.Tab>
      <Tabs.Tab eventKey="fields" title="Campos">
        {(params.tab === 'fields') && <FieldsTab />}
      </Tabs.Tab>
      <Tabs.Tab eventKey="team" title="Equipe">
        {params.tab === 'team' && <TeamTab />}
      </Tabs.Tab>
      {/* <Tabs.Tab eventKey="plan" title="Plano">
        {params.tab === 'plan' && <PlanTab />}
      </Tabs.Tab> */}
      <Tabs.Tab eventKey="webhooks" title="Webhooks">
        {(params.tab === 'webhooks') && <WebhooksTab />}
      </Tabs.Tab>
    </Tabs>
  </Dashboard.Content>)
}

export default Workspace;